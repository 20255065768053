import { ForwardedRef, forwardRef } from "react";

const InputExtLabel = forwardRef(({additionalWrapperClasses, additionalInputClasses, labelText, ...props}, ref) => {
    return (
        <label className={`input-wrapper d-block ${additionalWrapperClasses || ''}`}>
            <span className="mt-5 fs-12">{labelText}</span>
            <input className={`input mt-5 ${additionalInputClasses || ''}`} type="text" {...props} ref={ref} />
        </label>
    );
});

export default InputExtLabel;