import { useState, useEffect } from "react";
import useAuth from "./useAuth";
import useAlert from "./useAlert";
import { fetchUserReviews, fetchUserOwnReviews } from "../api/api";

const useReviews = () => {
    const [userReviews, setUserReviews] = useState([]);
    const {authToken} = useAuth();
    const {showAlert} = useAlert();
    
    useEffect(() => {
        fetchUserReviews(authToken)
        .then(reviews => setUserReviews(reviews))
        .catch(error => {
            console.log(error);
            showAlert(error.message);
        });

    }, []);

    return {
        userReviews,
    }
};

export default useReviews;