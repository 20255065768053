import './Popup.scss';
//Опции
{/* <div className="popup__close">&times;</div>
<div className="popup__header">
    <div className="popup__title">
        Новый заказ
    </div>
</div>
<div className="popup__content">

</div>
<div className="popup__footer"></div> */}

const Popup = ({isActive, children}) => {
    return (
        <div className={`popup ${isActive ? 'popup--active' : ''}`}>
            <div className="popup__dialog">
                {children}
            </div>
        </div>
    )
};

export default Popup;

