import { Link } from "react-router-dom";
import { useState } from "react";
import ButtonBurger from '../../../../components/buttonBurger/ButtonBurger';
import MenuMain from '../../../../components/menu/menuMain/MenuMain';
import Alert from "../../../../components/alert/Alert";

import logo from '../../../../assets/images/logo/logo_color.svg';

import './HomeHeader.scss';

const HomeHeader = () => {
    
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(isMenuOpen => !isMenuOpen);
    };

    return (
        <>
            <header className="home-header">
                <div className="container">
                    <div className="home-header__wrapper">
                        <Link to='/'>
                            {/* <p className="home-header__logo">Real Worker</p> */}
                            <img className='home-header__logo' src={logo} alt="logo" />
                        </Link>
                        <MenuMain isOpen={isMenuOpen} toggleMenu={toggleMenu}/>
                        <ButtonBurger handleClick={toggleMenu}/>
                    </div>
                </div>
            </header>
            <Alert/>
        </>
    )
};

export default HomeHeader;



