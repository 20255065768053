import { NavLink } from 'react-router-dom';
import './Footer.scss';

const Footer = () => {
    return (
        <div className="footer">
            <div className="container">
                <div className="footer__wrapper">
                    <nav>
                        <ul className="footer__menu">
                            <li>
                                <NavLink className="footer__menu__item">Услуги</NavLink>
                            </li>
                            <li>
                                <NavLink className="footer__menu__item">О платформе</NavLink>
                            </li>
                            <li>
                                <NavLink className="footer__menu__item">Служба поддержки</NavLink>
                            </li>
                        </ul>
                    </nav>

                    <div className="footer__trademark">© 2023  Real Worker</div>
                </div>
            </div>
        </div>
    )
};

export default Footer;