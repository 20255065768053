import ReviewCard from "../../../components/card/ReviewCard";
import Spinner from "../../../components/spinner/Spinner";
import CardEmpty from "../../../components/card/CardEmpty";

const ReviewsList = ({reviews}) => {
    if (!reviews) {
        return <Spinner additionalClasses={'h-full w-full'}/>
    };
    if (reviews.length < 1) {
        return <CardEmpty
                    title="Список отзывов пуст"
                    description="У Вас еще нет отзывов. Выполняйте заказы и просите Заказчиков оставить отзыв."/>
    }

    return (
        <div className="reviews-list">
            {reviews.map((review, i) => {
                const {rating, text, timeCreate, creatorName, creatorSurname, orderNumber} = review;
                return (
                    <ReviewCard
                            key={i}
                            rating={rating}
                            text={text}
                            timeCreate={timeCreate}
                            creatorName={creatorName}
                            creatorSurname={creatorSurname}
                            orderNumber={orderNumber}/>
                );
            })}
        </div>
    );
};

export default ReviewsList;