import './Business.scss';
import image from '../../../../assets/images/business/construction.png';

const Business = ({navigate}) => {
    return (
        <div className="business">
            <div className="container">
                <div className="title">
                    Повышение эффективности вашего бизнеса
                </div>
                <div className="business__wrapper">
                    <div className="business__results">
                        <p>Снижение времени и расходов на поиск рабочих позволит вам взять больше строительных заказов</p>
                        <p>С нашим сервисом вы сможете решать больше задач в единицу времени</p>
                    </div>
                    <div className="business__functionality">
                        <div className="subtitle color--second">Как это работает</div>
                        <p>
                            Функционал приложения <span className='color--second'>RealWorker</span> позволит решить строительную задачу быстро и просто:
                        </p>
                        <div className="business__steps">
                            <div className="business__steps__item">
                                <div className='business__steps__item__number'>1</div>
                                <div>Зарегистрируйтесь в приложении</div>
                            </div>
                            <div className="business__steps__item">
                                <div className='business__steps__item__number'>2</div>
                                <div>Разместите заявку на нужного специалиста</div>
                            </div>
                            <div className="business__steps__item">
                                <div className='business__steps__item__number'>3</div>
                                <div>Встретьте специалиста и поставьте задачу</div>
                            </div>
                            <div className="business__steps__item">
                                <div className='business__steps__item__number'>4</div>
                                <div>Примите и оплатите выполненную работу</div>
                            </div>
                        </div>
                                        
                    </div>
                    <img src={image} alt="construction" className="business__img" />
                    <div className="business__risks">
                        <div className="subtitle color--main">Минимизируем риски</div>
                        <div className="business__steps">
                            <div className="business__steps__item">
                                <div className='business__steps__item__number color--main'>1</div>
                                <div>Заказчик получает только качественных работникоа по заранее известной стоимости</div>
                            </div>
                            <div className="business__steps__item">
                                <div className='business__steps__item__number color--main'>2</div>
                                <div>Работник работает только с надежными заказчиками</div>
                            </div>
                            <div className="business__steps__item">
                                <div className='business__steps__item__number color--main'>3</div>
                                <div>Споры сведены к минимуму, так как все этапы зафиксированы в приложении</div>
                            </div>
                        </div>
                    </div>
                    <button 
                        // className="btn btn--primary business__btn"
                        className="btn business__btn"
                        onClick={() => navigate('/register')}>Регистрация</button>
                </div>
            </div>
        </div>
    )
};

export default Business;