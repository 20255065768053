import { useNavigate } from "react-router-dom";
import HomeHeader from "./components/homeHeader/HomeHeader";
import Promo from "./components/promo/Promo";
import ClientProblems from "./components/clientProblems/ClientProblems";
import Solutions from "./components/solutions/Solutions";
import Business from "./components/business/Business";
import Reviews from "./components/reviews/Reviews";
import Advantages from "./components/advantages/Advantages";
import Price from "./components/price/Price";
import Footer from "./components/footer/Footer";
import ButtonScrollToTop from "../../components/chat/components/buttonScrollToTop/ButtonScrollToTop";
import './HomePage.scss';

const HomePage = () => {
    const navigate = useNavigate();

    return (
        <div className="home-page">
            <HomeHeader/>
            <Promo navigate={navigate}/>
            <ClientProblems/>
            <Solutions/>
            <Business navigate={navigate}/>
            <Reviews/>
            <Advantages/>
            <Price/>
            <Footer/>
            <ButtonScrollToTop/>
        </div>
    )
};

export default HomePage;