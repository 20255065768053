import { useRef } from 'react';
import ChatHeader from './components/chatHeader/ChatHeader';
import InputPanel from './components/inputPanel/InputPanel';
import MessagesList from './components/messagesList/MessagesList';

import './ChatRoom.scss';

const ChatRoom = ({chatSelected, setChatSelected, interlocutor, setInterlocutor,messages, setMessages, messagesListRef, onSendMessage, textareaRef}) => {
    if (!chatSelected) {
        return (
            <div className='chat-room'>
                <div className="main__content">
                    <div className="container container--small">
                        <h1>Выберите чат</h1>
                    </div>
                </div>
            </div>
            
        )
    }

    return (
        <div className={`chat-room ${chatSelected ? 'chat-room--visible' : ''}`}>
            <ChatHeader setChatSelected={setChatSelected} interlocutor={interlocutor} setInterlocutor={setInterlocutor} setMessages={setMessages}/>
            <MessagesList chatSelected={chatSelected} messages={messages} messagesListRef={messagesListRef}/>
            <InputPanel onSendMessage={onSendMessage} textareaRef={textareaRef}/>
        </div>
    )
};

export default ChatRoom;