import ImageZoomer from "../../../../components/imageZoomer/ImageZoomer";
import ratingMenuTrigger from '../../../../assets/images/manual/worker-rating/rating-menu-trigger.png';
import ratingListEmpty from '../../../../assets/images/manual/worker-rating/rating-list-empty.png';
import ratingListFull from '../../../../assets/images/manual/worker-rating/rating-list-full.png';


const WorkerRating = () => {
    return (
        <>
            <h1>Ваш рейтинг</h1>
            <p className="mt-20">
                После выполнения заказа, Заказчик может оценить Вашу работу по шкале от 1 до 5. Исполнители с высоким рейтингом чаще получают хорошие заказы и получают дополнительные привелегии от нашего приложения.
            </p>
            <p className="mt-20">
                Хорошо выполняйте свою работу и Ваш рейтинг будет расти "как на дрожжах". Чтобы посмотреть свой рейтинг, зайдите в соответствующий раздел главного меню.
                <div className="images-wrapper mt-20">
                    <ImageZoomer>
                        <img src={ratingMenuTrigger} alt="rating menu trigger" />
                    </ImageZoomer>
                    <ImageZoomer>
                        <img src={ratingListEmpty} alt="rating list empty" />
                    </ImageZoomer>
                    <ImageZoomer>
                        <img src={ratingListFull} alt="rating list full" />
                    </ImageZoomer>
                </div>
            </p>
        </>
    );
};

export default WorkerRating;