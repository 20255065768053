import { useSelector, useDispatch } from "react-redux";
import { openAlert, closeAlert, closeAlertWithReject} from "../store/slices/alertSlice";

const useAlert = () => {
    const {isOpen, type, message, resolve, reject} = useSelector(state => state.alert);
    const dispatch = useDispatch();

    const checkAlert = () => {
        return {
            isOpen,
            type,
            message, 
            resolve,
            reject
        };
    };

    const showAlert = (message) => {
        dispatch(openAlert({message}));
    };

    const showAlertRequire = (message, type) => {
        return new Promise((resolve, reject) => {
            dispatch(openAlert({message, type, resolve, reject}));
        });
    };

    const hideAlert = (mode='resolve') => {
        if (mode === 'reject') {
            dispatch(closeAlertWithReject());
            return;
        };
        dispatch(closeAlert());
    };


    
    
    return {
        checkAlert,
        showAlertRequire,
        showAlert,
        hideAlert
    };
};

export default useAlert;