import { IoCheckmark } from "react-icons/io5";
import ImageZoomer from "../../../../components/imageZoomer/ImageZoomer";
import notificationUserArrivedTrigger from '../../../../assets/images/manual/order-execution/notification-user-arrived-trigger.png';
import notificationUserStartedTrigger from '../../../../assets/images/manual/order-execution/notification-user-arrived-result.png';
import notificationUserFinishedTrigger from '../../../../assets/images/manual/order-execution/notification-user-finished-trigger.png';
import askedConfirmationUserStarted from '../../../../assets/images/manual/order-execution/asked-confirmation-user-started.png';
import askedConfirmationUserFinished from '../../../../assets/images/manual/order-execution/asked-confirmation-user-finished.png';
import orderIsDone from '../../../../assets/images/manual/order-execution/status-changed-user-finished.png';

export default function OrderExecution() {

    return (
        <>
            <h1>Выполнение заказа</h1>
            <p className="mt-20">Для удобства взаимодействия Заказчика и Исполнителя, в нашем приложении есть несложные правила, которые нужно выполнять при исполнении заказов. В данном разделе мы расскажем что и как нужно делать, чтобы выполнение заказа прошло "без сучка и задоринки". </p>

            <p className="subtitle mt-30">Приезжайте на заказ вовремя</p>
            <p className="mt-20">Чтобы не подвести Заказчика, всегда приезжайте на объект к указанному в заказе времени. Лучше приехать немного заранее, скажем за 15 минут, чтобы Заказчик успел Вас встретить и проводить к месту выполнения работ.</p>

            <p className="subtitle mt-30">Вы приехали на объект</p>
            <p className="mt-20">Когда Вы приехали на объект, обязательно уведомьте Заказчика о своем прибытии. Это сделать довольно просто, достаточно надать соответствующую кнопку в карточке заказа.</p>
            <div className="images-wrapper">
                <ImageZoomer>
                    <img src={notificationUserArrivedTrigger} alt="notification userArrived trigger" />
                </ImageZoomer>
                <ImageZoomer>
                    {/* <img src="" alt="" /> */}
                </ImageZoomer>
                <ImageZoomer>
                    {/* <img src="" alt="" /> */}
                </ImageZoomer>
            </div>
            <p className="mt-20">После того как Вы отправили уведомление, Заказчик его увидит и выйдет Вас встретить. Если Заказчик не вышел и не связался с Вами в течение 15 минут после отправки уведомления, позвоните ему и сообщите о своем прибытии.</p>

            <p className="subtitle mt-30">Вы приступили к работе</p>
            <p className="mt-20">После того как Заказчик Вас встретил и проводил к месту выполнения работ, направьте Заказчику уведомление "о начале работ". Заказчик должен подтвердить данное уведомление, так что лучше это сделать прямо при Заказчике и попросить его подтвердить факт начала работ.</p>
            <div className="images-wrapper">
                <ImageZoomer>
                    <img src={notificationUserStartedTrigger} alt="notification userArrived popup" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={askedConfirmationUserStarted} alt="confirmation asked - user started" />
                </ImageZoomer>
                <ImageZoomer>
                    {/* <img src="" alt="" /> */}
                </ImageZoomer>
            </div>
            <p className="mt-20">Запомните! Отправить уведомление и добиться от Заказчика подтверждения факта начала работ очень важно, т.к. это позволит избежать ненужных споров о времени выполнении работ, которые могут возникнуть при завершении работ.</p>

            <p className="subtitle mt-30">Вы выполнили заказ</p>
            <p className="mt-20">После того как Вы выполнили заказ (рабочий день подошел к завершению), перед тем как Вы покинете место выполнения работ, обязательно уведомьте Заказчика "о выполнении заказа", дождитесь от Заказчика подтверждения и получите оплату. Уведомление отправляется также просто как и предыдущие, достаточно нажать соответствующую кнопку в карточке заказа.</p>
            <div className="images-wrapper">
                <ImageZoomer>
                    <img src={notificationUserFinishedTrigger} alt="notification userArrived result" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={askedConfirmationUserFinished} alt="confirmation asked - user finished" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={orderIsDone} alt="order is done" />
                </ImageZoomer>
            </div>
            
            <div className="images-wrapper">
                <p className="mt-20">Напоминание! Отправить уведомление и добиться от Заказчика подтверждения факта выполнения работ очень важно, т.к. это позволит избежать ненужных споров при оплате работ.</p>
            </div>
            <p className="mt-20">Поздравляем! Теперь работа выполнена и оплачена, все формальности соблюдены, можно отправиться домой отдыхать или взять и выполнить еще один заказ :)</p>

            {/* <p className="subtitle mt-30">Всегда уведомляйте Заказчиков о прибытии, начале работ и выполнении заказа</p>
            <p className="mt-20">Чтобы эффективно фиксировать и отслеживать этапы выполнения заказа, мы просим Вас отправлять Заказчикам следующие уведомления:</p>
            <ul className="mt-10">
                <li><IoCheckmark color="#00B288"/> Исполнитель приехал</li>
                <li className="mt-10"><IoCheckmark color="#00B288"/> Исполнитель начал работать</li>
                <li className="mt-10"><IoCheckmark color="#00B288"/> Исполнитель выполнил заказ</li>
            </ul>
            <p className="mt-20">Отправка уведомлений осуществляется автоматически, достаточно только нажать на соответствующую кнопку, расположенную в карточке заказа</p>
            <div className="images-wrapper">
                <ImageZoomer>
                    <img src={notificationUserArrivedTrigger} alt="notification userArrived trigger" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={notificationUserStartedTrigger} alt="notification userArrived popup" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={notificationUserFinishedTrigger} alt="notification userArrived result" />
                </ImageZoomer>
            </div>

            <p className="subtitle mt-30">Контролируйте подтверждения со стороны Заказчика</p>
            <p className="mt-20">Часть уведомлений, отправленных Вами, требуют подтверждения со стороны Заказчика. К таким уведомлениям относятся:</p>
            <ul className="mt-20">
                <li>- Начало работ</li>
                <li className="mt-20">- Выполнение работ</li>
            </ul>
            <div className="images-wrapper">
            <p className="mt-20">Если Вы видите, что в карточке заказа указано "Запрошено подтверждение" и Заказчик долго не подтверждает действие, напишите или позвоните Заказчику и попросите подтвердить соответствующее уведомление.</p>
            <ImageZoomer>
                <img src={askedConfirmationUserStarted} alt="confirmation asked - user started" />
            </ImageZoomer>
            <ImageZoomer>
                <img src={askedConfirmationUserFinished} alt="confirmation asked - user finished" />
            </ImageZoomer>
            </div> */}
        </>
    );
};