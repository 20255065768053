import { useState, useEffect } from "react";
import useAuth from "./useAuth";
import useAlert from "./useAlert";
import { fetchUserOrders } from "../api/api";

const useOrders = (filter) => {
    const [orders, setOrders] = useState(null);
    const [sortedOrders, setSortedOrders] = useState({
        searchWorkerOrders: null, 
        foundedWorkerOrders: null, 
        progressOrders: null,
        doneOrders: null,
        activeOrders: null
    });
    const [filteredOrders, setFilteredOrders] = useState(null);

    const [updateOrdersList, setUpdateOrdersList] = useState(false); // добавляем переменную для слежения за обновлением списка заказов
    const {authToken} = useAuth();
    const {showAlert} = useAlert();
   
    const sortOrders = (orders) => {
        const activeOrders = orders.filter(order => order.orderStatus === 'Поиск исполнителя' || order.orderStatus === 'Назначен исполнитель' || order.orderStatus === 'Исполнитель приехал' || order.orderStatus === 'Исполнитель работает');
        const searchWorkerOrders = orders.filter(order => order.orderStatus === 'Поиск исполнителя');
        const foundedWorkerOrders = orders.filter(order => order.orderStatus === 'Назначен исполнитель');
        const progressOrders = orders.filter(order => (
            order.orderStatus === 'Исполнитель приехал' || order.orderStatus === 'Исполнитель работает'
        ));
        const doneOrders = orders.filter(order => order.orderStatus === 'Заказ выполнен');
        // const allOrders = [...orders];

        return {
            searchWorkerOrders, 
            foundedWorkerOrders, 
            progressOrders,
            doneOrders,
            activeOrders
        };
    };


    useEffect(() => {
        fetchUserOrders(authToken)
            .then(orders => {
                // if (orders.length > 0) {
                //     const sortedOrders = [...orders].sort((a, b) => b.number - a.number);
                //     setOrders(sortedOrders);
                // } else {
                //     setOrders(orders);
                // };
                setOrders(orders);
                setSortedOrders(sortOrders(orders));
            })
            .catch(error => {
                console.log(error);
                showAlert(error.message);
            });
    }, [updateOrdersList]);

    useEffect(() => {
        if (orders) {
            let filteredOrders = null;
            const {searchWorkerOrders, foundedWorkerOrders, progressOrders, doneOrders, activeOrders} = sortedOrders;
            switch (filter) {
                case 'active':
                    filteredOrders = [...activeOrders];
                    break;
                case 'done':
                    filteredOrders = [...doneOrders];
                    break;
                case 'searchWorker':
                    filteredOrders = [...searchWorkerOrders];
                    break;
                case 'foundedWorker':
                    filteredOrders = [...foundedWorkerOrders];
                    break;
                case 'progress':
                    filteredOrders = [...progressOrders];
                    break;
                default:
                    filteredOrders = [...orders];
            };
            setFilteredOrders(filteredOrders);
        };

    }, [orders, filter]);

    
    return {
        orders,
        sortedOrders,
        filteredOrders,
        setUpdateOrdersList
    };
};

export default useOrders;