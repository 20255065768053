import HeaderCustomer from "../../components/header/HeaderCustomer";
import ButtonBurger from "../../components/buttonBurger/ButtonBurger";
import MenuSideCustomer from "../../components/menu/menuSide/MenuSideCustomer";
import MenuQuickCustomer from "../../components/menu/menuQuick/MenuQuickCustomer";
import PopupNewSupportRequest from '../../components/popup/PopupNewSupportRequest';
import SupportRequestsList from '../../components/list/SupportRequestsList';
import UserInfo from "../../components/header/components/UserInfo";
import usePopup from '../../hooks/usePopup';
import useSupportRequests from '../../hooks/useSupportRequests';

const CustomerSupportPage = () => {
    const {isPopupActive, showPopup, hidePopup} = usePopup();
    const {supportTopics, userSupportRequests, sendSupportRequest} = useSupportRequests();

    return (
        <>
            <HeaderCustomer>
                <div className="header__content">
                    <ButtonBurger />
                    <h1 className="header__title">Служба поддержки</h1>
                    <UserInfo/>
                </div>
            </HeaderCustomer>

            <div className="main__wrapper">
                <MenuSideCustomer />
                <main className="main__content">
                    <div className="container container--small">
                        <button 
                            className='btn btn--primary btn--orange m-center'
                            onClick={showPopup}>
                            Новое обращение
                        </button>
                        <SupportRequestsList
                            supportRequests={userSupportRequests}/>
                    </div>
                </main>
            </div>

            <MenuQuickCustomer/>
            <PopupNewSupportRequest
                isActive={isPopupActive}
                hidePopup={hidePopup}
                supportTopics={supportTopics}
                sendSupportRequest={sendSupportRequest}/>
        </>
    ); 
};

export default CustomerSupportPage;