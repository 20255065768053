//Напишем функцию, которая выполнит валидацию инпута с номером телефона
export const setPhoneMask = (str) => {
    const phoneNumbers = str.replace(/\D/g, '');

    if (phoneNumbers.length > 0 && phoneNumbers.length <= 4) {
        return '+7(' + phoneNumbers.replace('7', '');
    }
    if (phoneNumbers.length > 4 && phoneNumbers.length <= 7 ) {
        return '+7(' + phoneNumbers.replace('7', '').substring(0,3) + ')' + phoneNumbers.substring(4,7);
    }
    if (phoneNumbers.length > 7 && phoneNumbers.length <= 9 ) {
        return '+7(' + phoneNumbers.replace('7', '').substring(0,3) + ')' + phoneNumbers.substring(4,7) + '-' + phoneNumbers.substring(7,9);
    }
    if (phoneNumbers.length > 9 && phoneNumbers.length <= 11 ) {
        return '+7(' + phoneNumbers.replace('7', '').substring(0,3) + ')' + phoneNumbers.substring(4,7) + '-' + phoneNumbers.substring(7,9) + '-' + phoneNumbers.substring(9,11);
    }

    if (/^\+7/.test(str)) {
        return str.substring(0,16);
    } else {
        return '';
    }
};