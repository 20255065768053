//Рекурсивная функция - сравнит данные и эталонный образец. Если структура совпадает - вернет true, если нет -false
const compareData = (data, etalonData) => {
    //Если типы данных не равны, завершаем функцию и возвращаем false
    if (typeof data !== typeof etalonData) {
        return false;
    };
    //Если типы данных - простые (не object), возвращаем true
    if (typeof data !== 'object') {
        return true;
    };

    //Отдельно проверим на null, т.к. у null тип данных object
    if ((data === null && etalonData !== null) || (data !== null && etalonData === null)) {
        return false;
    };

    //Если один - объект, второй - массив
    if (Array.isArray(data) !== Array.isArray(etalonData)) {
        return false;
    };


    //Дальнейшие действия относятся только к случаю, если данные - это объект
    if (typeof data === 'object' && !Array.isArray(data) && data !== null) {
        //Если data - это пустой объект, а etalonData - не пустой
        if (Object.keys(data).length < 1 && Object.keys(etalonData).length > 0) {
            return false;
        };
        //Переберем объект etalonData
        for (let key in etalonData) {
            //Если свойство объекта etalonData не найдено в data, вернем false
            if (!(key in data)) {
                return false;
            }
            //Рекурсивный вызов функции, для проверки каждого свойства
            if (!compareData(data[key], etalonData[key])) {
                return false;
            }
        };
        return true;
    };

    //Дальнейшие действия относятся только к случаю, если данные - это массив
    if (Array.isArray(data) && Array.isArray(etalonData)) {
        //Если массив пустой, возвращаем true
        if (data.length < 1) {
            return true;
        };
        if (etalonData.length > 0) {
            //Рекурсивный вызов функции для проверки первого элемента массива
            if (!compareData(data[0], etalonData[0])) {
                return false;
            };
        }
    };
    return true;
};

export {compareData};