import { Link } from 'react-router-dom';
import './Header.scss';
import logo from '../../assets/images/logo/logo_color.svg';

const Header = ({children}) => {
    return (
        <header className="header">
            <div className="header__wrapper">
                        <div className="header__logo">
                            <Link to='/'>
                                <img src={logo} alt="logo" />
                            </Link>
                        </div>
                    <div className="header__content__wrapper">
                        <div className="container container--small">
                            {children}
                        </div>
                    </div>
                </div>
            
        </header>
    );
};

export default Header;