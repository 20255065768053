import { Outlet, useNavigate } from 'react-router-dom';
import HeaderCustomer from "../../components/header/HeaderCustomer";
import ButtonBurger from '../../components/buttonBurger/ButtonBurger';
import MenuSideCustomer from "../../components/menu/menuSide/MenuSideCustomer";
import MenuQuickCustomer from "../../components/menu/menuQuick/MenuQuickCustomer";
import UserInfo from '../../components/header/components/UserInfo';
import useUrl from '../../hooks/useUrl';
import {BackArrow, ForwardArrow} from '../../components/arrow/Arrow';

import './AboutPage.scss';


const CustomerAboutPage = () => {
    const {checkUrlNotContains} = useUrl();
    const navigate = useNavigate();
    
    return (
        <>
            <HeaderCustomer >
                <div className="header__content">
                    <ButtonBurger />
                    <h1 className="header__title">О платформе</h1>
                    <UserInfo/>
                    
                </div>
            </HeaderCustomer>

            <div className="main__wrapper">
                <MenuSideCustomer />
                <main className="main__content">
                    <div className="container container--small">
                        {checkUrlNotContains('about/')
                        ?   <div className='about-menu'>
                                <div 
                                    className='about-menu__item'
                                    onClick={() => navigate('/customer-about/contract')}>
                                    Договор
                                    <ForwardArrow 
                                        className='about-menu__item__arrow'/>
                                </div>
                                <div 
                                    className='about-menu__item'
                                    onClick={() => navigate('/customer-about/user-agreement')}>
                                    Пользовательское соглашение
                                    <ForwardArrow className='about-menu__item__arrow'/>
                                </div>
                                <div 
                                    className='about-menu__item'
                                    onClick={() => navigate('/customer-about/personal-data-policy')}>
                                    Политика обработки персональных данных
                                    <ForwardArrow className='about-menu__item__arrow'/>
                                </div>
                                <div 
                                    className='about-menu__item'
                                    onClick={() => navigate('/customer-about/personal-data-consent')}>
                                    Согласие на обработку персональных данных
                                    <ForwardArrow className='about-menu__item__arrow'/>
                                </div>
                            </div>

                        :   <div 
                                className="about-menu__back-arrow"
                                onClick={() => navigate(-1)}>
                                <BackArrow/>
                                <div>Назад</div>
                            </div> }
                        
                        <Outlet/>
                    </div>
                </main>
            </div>

            <MenuQuickCustomer/>
        </>
    );
    
};

export default CustomerAboutPage;
