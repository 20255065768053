import useAuth from "../../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import { ErrorInput } from "../../../components/error/Error";
import InputPhoneWithConfirm from "./InputPhoneWithConfirm";



const RegisterStep2 = ({userData, setRegisterStep}) => {
    const {register, handleSubmit, formState:{errors, isValid}, reset, watch, setValue, setError, clearErrors} = useForm({mode: "onBlur"});
    const navigate = useNavigate();
    
    const {signUp} = useAuth();
    const phoneInputValue = watch('phone');

    // const validatePhone = (value) => {
    //     const number = value.replace(/\D/g,'');
    //     return (number.length === 11) || 'Некорректный телефон';
    // }

    const onSubmitForm2 = async (data) => {
        if (!data.isPhoneApproved) {
            setError('isPhoneApproved', {
                type: 'required',
                message: 'Подтвердите телефон'
            });
        } else {
            const user = {...userData, ...data};
            delete user.isPhoneApproved;
            await signUp(user);
            reset();
            setRegisterStep('step1');
            navigate(userData.role === 'customer' ? '/customer-orders' : 'worker' ? '/worker-orders' : '/login');
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmitForm2)}>
            {/* <div className='input-group'>
                <label className="input__label">
                    Телефон
                    <ReactInputMask
                        mask="+7(999)999-99-99"  
                        type="tel" 
                        {...register('phone', {validate: validatePhone})}
                        className={ `input input--grey mt-5 ${errors?.phone ? 'input--error' : ''}`}
                        placeholder="+7(XXX)XXX-XX-XX"/>
                </label>
                {errors?.phone && <ErrorInput message={errors.phone?.message || 'Ошибка'}/>}
            </div> */}
            <InputPhoneWithConfirm 
                additionalWrapperClasses='mt-20'
                additionalInputClasses={ `w-140`}
                registerWithName={{register: register, name: 'phone'}}
                errorPhone={errors?.phone}
                errorPhoneConfirmation={errors?.isPhoneApproved}
                phoneInputValue={phoneInputValue}
                setValue={setValue}
                clearErrors={clearErrors}/>
            {/* <div className="sekret-key mt-20">
                <div >
                    <input 
                        type="text" 
                        // {...register('sekretKey', {required: 'Введите код'})}
                        {...register('sekretKey', {required: false})}
                        className={ `input input--grey mt-0 ${errors?.sekretKey ? 'input--error' : ''}`}
                        placeholder="Введите код"/>
                    {errors?.sekretKey && <ErrorInput message={errors.sekretKey?.message || 'Ошибка'}/>}
                </div>
                <button className="btn btn--primary btn--outline p-5 h-40 w-130 fs-11 ls-2">Запросить код</button>
            </div> */}
            {/* <div className="phone-confirmation mt-20 input--error">
                <span>Подтвердить</span>
                <div className="phone-confirmation__links">
                    <div className="btn btn--secondary" confirmation-type="call">Звонок</div>
                    <div className="btn btn--secondary"confirmation-type="telegram">Telegram</div>
                </div>
            </div>
            {<ErrorInput message={'Подтвердите телефон'}/>} */}
            {/* <div className='input-group'>
                <label className="input__label">
                    Email (для восстановления доступа)
                    <input 
                        type="email" 
                        {...register('email', {required: 'Укажите Ваш email'})}
                        className={ `input input--grey mt-5 ${errors?.email ? 'input--error' : ''}`}/>
                </label>
                {errors?.email && <ErrorInput message={errors.email?.message || 'Ошибка'}/>}
            </div> */}
            <div className="mt-20">
                {/* <div className="form__subtitle">Задайте пароль для приложения</div> */}
                <div className='input-group'>
                    <label className="input__label">
                        Пароль
                        <input 
                            type="password" 
                            {...register('password', {required: 'Задайте пароль'})}
                            autoComplete="new-password"
                            className={ `input input--grey mt-5 ${errors?.password ? 'input--error' : ''}`}/>
                    </label>
                    {errors?.password && <ErrorInput message={errors.password?.message || 'Ошибка'}/>}
                </div>
            </div>
            <div className="form__notification">
                Регистрируясь, Вы подтверждаете согласие с Политикой Конфиденциальности и Пользовательским соглашением.
            </div>
            <div className="buttons-group mt-40">
                <button 
                    type="button"
                    className="btn btn--primary btn--outline p-5 m-center h-40 w-100 ls-2"
                    onClick={() => setRegisterStep('step1')}>Назад</button>
                <button
                    className="btn btn--primary btn--orange p-5 m-center h-40 w-210 ls-2"
                    // onClick={handleClickSubmitTrigger}
                    >Создать аккаунт</button>
            </div>
        </form>
    )
};

export default RegisterStep2;