import './Error.css';

const ErrorInput = ({message}) => {
    return (
        <div className="input-error-message">{message}</div>
    )
};

export {
    ErrorInput
};