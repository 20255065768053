import './ClientProblems.scss';
import icon1 from '../../../../assets/images/problems/icon01.svg';
import icon2 from '../../../../assets/images/problems/icon02.svg';
import icon3 from '../../../../assets/images/problems/icon03.svg';
import icon4 from '../../../../assets/images/problems/icon04.svg';
import icon5 from '../../../../assets/images/problems/icon05.svg';
import icon6 from '../../../../assets/images/problems/icon06.svg';
import answerImg from '../../../../assets/images/problems/answer-img.png';


const Problems = () => {
    return (
        <div className="client-problems">
            <div className="container">
                <div className="client-problems__wrapper">
                    <div className="client-problems__item">
                        <div className="client-problems__item__icon-wrapper">
                            <img src={icon1} alt="icon1" className="client-problems__item__icon" />
                        </div>
                        <div className="client-problems__item__descr">
                            На строительном объекте <span>не хватает рабочих</span>, а сроки сдачи уже близко?
                        </div>
                    </div>
                    <div className="client-problems__item">
                        <div className="client-problems__item__icon-wrapper">
                            <img src={icon2} alt="icon2" className="client-problems__item__icon" />
                        </div>
                        <div className="client-problems__item__descr">
                            Вам нужен <span>узкопрофильный специалист</span> на один или несколько дней?
                        </div>
                    </div>
                    <div className="client-problems__item">
                        <div className="client-problems__item__icon-wrapper">
                            <img src={icon3} alt="icon3" className="client-problems__item__icon" />
                        </div>
                        <div className="client-problems__item__descr">
                            Вас <span>подводят непроверенные мастера</span>?
                        </div>
                    </div>
                    <div className="client-problems__answer">
                        <div className="client-problems__answer__circle1">
                            <div className="client-problems__answer__circle2">
                                <div className="client-problems__answer__title">
                                    Наш сервис решит эти задачи и не только...
                                </div>
                                <img className="client-problems__answer__img" src={answerImg} alt="building" />
                            </div>
                        </div>
                    </div>
                    <div className="client-problems__item">
                        <div className="client-problems__item__icon-wrapper">
                            <img src={icon4} alt="icon4" className="client-problems__item__icon" />
                        </div>
                        <div className="client-problems__item__descr">
                            Вам нужно <span>подменить работника</span>, который в отпуске?
                        </div>
                    </div>
                    <div className="client-problems__item">
                        <div className="client-problems__item__icon-wrapper">
                            <img src={icon5} alt="icon5" className="client-problems__item__icon" />
                        </div>
                        <div className="client-problems__item__descr">
                            Вам срочно нужны <span>разнорабочие или грузчики</span>?
                        </div>
                    </div>
                    <div className="client-problems__item">
                        <div className="client-problems__item__icon-wrapper">
                            <img src={icon6} alt="icon6" className="client-problems__item__icon" />
                        </div>
                        <div className="client-problems__item__descr">
                            Вы мастер и <span>Вам нужно найти подсобника</span>?
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Problems;