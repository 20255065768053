import ImageZoomer from "../../../../components/imageZoomer/ImageZoomer";
import chatTrigger from '../../../../assets/images/manual/customer-chat/customer-chat-trigger.png';
import messagesTrigger from '../../../../assets/images/manual/customer-chat/menu-chats-trigger.png';
import chatsList from '../../../../assets/images/manual/customer-chat/chats-list.png';



const CustomerChat = () => {
    return (
        <>
            <h1>Чат с Исполнителем</h1>
            <p className="mt-30">Приложение RealWorker позволяет пользователям общаться через встроенный чат. Это не только удобно, но и полезно, так как позволит быстро разобраться в ситуации, если возникнет спорный вопрос с Исполнителем.</p>
            <p className="mt-20">Для создания нового чата с Исполнителем, нажмите на иконку "Сообщение", расположенную на карточке заказа. Список активных чатов и все сообщения хранятся в разделе "Сообщения".</p>
            <div className="images-wrapper mt-30">
                <ImageZoomer>
                    <img src={chatTrigger} alt="chat trigger" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={messagesTrigger} alt="messages trigger" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={chatsList} alt="chats list" />
                </ImageZoomer>
            </div>
        </>
    );
};

export default CustomerChat;