import { IoIosContact } from 'react-icons/io';
import './ChatCard.scss';

// import userPhoto from '../../../../../../assets/images/customer-img.jpg';

export default function ChatCard({name, surname, photo, message, date, unreadMessagesNumber, orderNumber, isActive, handleClick, handleClickOrderNumber}) {
    
    const formateMessage = (message) => {
        if (message.includes('\n')) {
            const messageParts = message.split('\n');
            return messageParts[0].length < 30 ? messageParts[0] + '...' : messageParts[0].slice(0,30) + '...'
        };
        return message.length < 30 ? message : message.slice(0,30) + '...';
    };

    return (
        <div 
            className={`chat-card ${isActive ? 'chat-card--active' : ''}`}
            onClick={handleClick}>
            <div className="chat-card__photo">
                {photo
                ? <img
                className='chat-card__photo__img' 
                src={photo} alt="interlocutorPhoto" />
                : <IoIosContact className='chat-card__photo__img'/>
                }
            </div>
            <div className="chat-card__text">
                <div className="d-flex justify-between items-center">
                    <div className="chat-card__user-name">
                        {`${name} ${surname || ''}`}
                    </div>
                    <div 
                        className="chat-card__order-number"
                        onClick={(event) => handleClickOrderNumber(event, orderNumber)}>
                        {orderNumber || ''}
                    </div>
                </div>
                <div className="chat-card__details">
                    <div className="chat-card__message">
                        {formateMessage(message)}
                    </div>
                    <div className="chat-card__time-indicator">
                        {date}
                    </div>
                </div>
            </div>
            <div className={`chat-card__counter ${unreadMessagesNumber < 1 ? 'd-none' : ''}`}>
                {unreadMessagesNumber}
            </div>
        </div>
    );
};