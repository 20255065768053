import { useState, useEffect } from "react";
import useAuth from "./useAuth";
import useAlert from "./useAlert";
import useUserAction from "./useUserAction";
import { fetchUserNotifications } from "../api/api";

const useNotifications = (filter) => {
    const [notifications, setNotifications] = useState(null);
    const [filteredNotifications, setFilteredNotifications] = useState(null);
    const [updateNotificationsList, setUpdateNotificationsList] = useState(false);

    const {customerStartedWorkingConfirmed, customerStartedWorkingRejected, customerFinishedWorkingConfirmed, customerFinishedWorkingRejected} = useUserAction();
    const {authToken} = useAuth();
    const {showAlert} = useAlert();
    

    const filterNotifications = (notifications, filter) => {
        if (filter === 'requireConfirmation') {
            return notifications?.filter(notification => notification.notificationStatus === 'Запрошено подтверждение')
        };

        return notifications;
    };

    const confirmNotification = async (notificationType, orderNumber) => {
        if (notificationType === 'Исполнитель работает') {
            const notificationUpdated = await customerStartedWorkingConfirmed(orderNumber, authToken);
            updateNotifications();

            return notificationUpdated;
        };

        if (notificationType === 'Заказ выполнен') {
            const notificationUpdated = await customerFinishedWorkingConfirmed(orderNumber, authToken);
            updateNotifications();

            return notificationUpdated;
        };
    };

    const rejectNotification = async (notificationType, orderNumber) => {
        if (notificationType === 'Исполнитель работает') {
            const notificationUpdated = await customerStartedWorkingRejected(orderNumber, authToken);
            updateNotifications();

            return notificationUpdated;
        };

        if (notificationType === 'Заказ выполнен') {
            const notificationUpdated = await customerFinishedWorkingRejected(orderNumber, authToken);
            updateNotifications();

            return notificationUpdated;
        };
    };

    const updateNotifications = () => {
        setUpdateNotificationsList(state => !state);
    };

    useEffect(() => {
        fetchUserNotifications(authToken)
            .then(notifications => {
                // if (notifications.length > 0) {
                    
                // };
                
                const sortedNotifications = [...notifications].sort((a, b) => new Date(b.notificationCreatedDate).getTime() - new Date(a.notificationCreatedDate).getTime());

                setNotifications(sortedNotifications);
            })
            .catch(error => {
                console.log(error);
                showAlert(error.message);
            });
    },[updateNotificationsList]);

    useEffect(() => {
        const filteredNotifications = filterNotifications(notifications, filter);
        setFilteredNotifications(filteredNotifications);
    }, [notifications, filter]);

    return {
        notifications,
        filteredNotifications,
        confirmNotification,
        rejectNotification
    }
};

export default useNotifications;