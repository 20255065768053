import { useEffect, useState, useRef, forwardRef } from "react";
import ReactInputMask from "react-input-mask";
import { GiCheckMark } from "react-icons/gi";
import { ErrorInput } from "../../components/error/Error";
import Spinner from "../../components/spinner/Spinner";
import Timer from "../../components/timer/Timer";
import useAlert from "../../hooks/useAlert";
import { createPhoneVerification, fetchPhoneValidation, createPhoneRegistration } from "../../api/api";


const InputPhoneWithConfirm = forwardRef(({additionalWrapperClasses, additionalInputClasses, errorPhone, errorPhoneConfirmation, phoneInputValue, setValue, clearErrors, confirmOptions, ...props}, ref) => {
    const callConfirm = typeof(confirmOptions?.callConfirm) === 'boolean' ? confirmOptions.callConfirm : true;
    const telegramConfirm = typeof(confirmOptions?.telegramConfirm) === 'boolean' ? confirmOptions.telegramConfirm : true;
    
    const [showSecondBlock, setShowSecondBlock] = useState(null);
    const [showTimerBlock, setShowTimerBlock] = useState(false);
    const [isPhoneCorrect, setIsPhoneCorrect] = useState(false);
    const [code, setCode] = useState('');
    const [isPhoneApproved, setIsPhoneApproved] = useState(null);
    const [phoneValidationTimer, setPhoneValidationTimer] = useState(false);
    
    const {showAlert} = useAlert();
    const codeInputRef = useRef(null);

    
    
    useEffect(() => {
        if (!phoneInputValue || validatePhone(phoneInputValue) !== true) {
            if (isPhoneCorrect) setIsPhoneCorrect(false);
            if (showSecondBlock) setShowSecondBlock(null);
            if (code) setCode('');
            if (isPhoneApproved) setIsPhoneApproved(null);
            if (phoneValidationTimer) setPhoneValidationTimer(false); 
        } else {
            if (!isPhoneCorrect) setIsPhoneCorrect(true);
        };

    }, [phoneInputValue]);

    useEffect(() => {
        if (code.length === 4) {
            checkPhoneValidation('call', phoneInputValue, code);
        };
    }, [code]);

    useEffect(() => {
        setValue('isPhoneApproved', isPhoneApproved);
        if (isPhoneApproved) setPhoneValidationTimer(false);
    }, [isPhoneApproved]);

    useEffect(() => {
        if (phoneValidationTimer) {
            const timer = setInterval(async () => {
                const isValid = await checkPhoneValidation('telegram', phoneInputValue);
                
                if (isValid) {
                    //Убираем таймер
                    clearInterval(timer);
                    setPhoneValidationTimer(false);
                };
            }, 2000);

            return () => clearInterval(timer);
        };
    }, [phoneValidationTimer]);


    const validatePhone = (value) => {
        const number = value.replace(/\D/g,'');
        
        return (number.length === 11) || 'Некорректный телефон';
    };

    const onClickConfirm = async () => {
        
        if (!phoneInputValue || validatePhone(phoneInputValue) !== true) {
            return;
        };
        setShowSecondBlock((state) => {
            if (state === 'optionsBlock') {
                return null;
            };
            if (state === null && callConfirm && telegramConfirm) {
                return 'optionsBlock';
            };
            if (state === null && callConfirm && !telegramConfirm) {
                return 'codeBlock';
            };
            if (state === null && !callConfirm && telegramConfirm) {
                return 'telegramBlock';
            };
        });

        if (callConfirm && !telegramConfirm) {
            setShowTimerBlock(true);
            try {
                await createPhoneVerification('call', phoneInputValue);
            } catch (error) {
                console.log(error);
                showAlert(error.message);
            };
        };
    };

    const onClickCall = async (event) => {
        const confirmationType = event.target.getAttribute('confirmation-type');
        setShowTimerBlock(true);

        if (confirmationType === 'call') {
            setShowSecondBlock('codeBlock');
            try {
                await createPhoneVerification('call', phoneInputValue);
            } catch (error) {
                console.log(error);
                showAlert(error.message);
            };
        };

        if (confirmationType === 'telegram') {
            //Проверим - открыто ли приложение как PWA
            const isPwa = window.matchMedia('(display-mode: standalone)').matches;

            try {
                await createPhoneRegistration(phoneInputValue);
                isPwa ? window.location.assign('https://t.me/realworker_bot') : window.open('https://t.me/realworker_bot', '_blank');
                setShowSecondBlock('telegramBlock');
                setPhoneValidationTimer(true);               
            } catch (error) {
                console.log(error);
                showAlert(error.message);
            };

        };
    };
    
    const onCodeType = (e) => {
        if (e.target.value.length < 5) {
            setCode(e.target.value);
        }
    };

    const checkPhoneValidation = async (type, phone, code) => {
        try {
            const phoneValidation = await fetchPhoneValidation(type, phone, code);

            if (phoneValidation.verified) {
                if (type === 'call') {
                    codeInputRef.current.blur();
                    setCode('');
                }
                setShowSecondBlock(null);
                setIsPhoneApproved(true);
                clearErrors('isPhoneApproved');
            } else {
                if (type === 'call') codeInputRef.current.blur();
                setIsPhoneApproved(false);
            };

            return phoneValidation.verified;

        } catch (error) {
            console.log(error);
            showAlert(error.message);
        }
    };

    const onTimerFinished = () => {
        if (callConfirm && telegramConfirm) setShowSecondBlock('optionsBlock');
        if (callConfirm && !telegramConfirm) setShowSecondBlock(null);
        setShowTimerBlock(false);
        if (phoneValidationTimer) setPhoneValidationTimer(false);
    };

    return (
        <div className={additionalWrapperClasses}>
            <label className="input__label" htmlFor="inputPhone">Телефон</label>
            <div className={`input input--grey input-phone-wrapper  mt-5 h-auto ${errorPhone ? 'input--error' : ''}`}>
                    <div className="d-flex justify-between">
                        <ReactInputMask
                                    id="inputPhone"
                                    mask="+7(999)999-99-99"  
                                    type="tel" 
                                    className={additionalInputClasses}
                                    placeholder="+7(XXX)XXX-XX-XX"
                                    {...props}
                                    ref={ref}/>
                        {!showSecondBlock && !isPhoneApproved &&
                        <div 
                        className={`btn btn--secondary btn--green-dark ${!isPhoneCorrect ? 'btn--disabled' : ''}`}
                        onClick={onClickConfirm}>Подтвердить</div>}
                        {isPhoneApproved &&
                        <GiCheckMark className="check-mark mr-10"/>}
                        {showTimerBlock && !isPhoneApproved &&
                        <Timer startValue={60} onFinished={onTimerFinished}/>}
                    </div>
                    {isPhoneCorrect && showSecondBlock === 'optionsBlock' &&
                    <div className="mt-20">
                        <div className="form__subtitle text-center">Выберите способ подтверждения</div>
                        <div className="phone-confirmation__links mt-10 justify-center gap-x-30">
                            <div 
                                className="btn btn--secondary" 
                                confirmation-type="call"
                                onClick={onClickCall}>Звонок</div>
                            <div 
                                className="btn btn--secondary" 
                                confirmation-type="telegram"
                                onClick={onClickCall}>Telegram</div>
                        </div>
                    </div>}
                    {isPhoneCorrect && showSecondBlock === 'codeBlock' &&
                    <div className="phone-confirmation__code-block">
                        <div>
                            <span className="form__subtitle">Введите код:</span>
                            <div>
                                <input 
                                    type="text" 
                                    className={`input ${isPhoneApproved === false && 'input--error'}`}
                                    value={code}
                                    onChange={onCodeType}
                                    ref={codeInputRef}/>
                                {isPhoneApproved === false && <ErrorInput message={'Код не правильный'}/>}
                            </div>
                        </div>
                        <p>Подсказка: последние 4 цифры номера, с которого Вы получили вызов</p>
                    </div>
                    }
                    {isPhoneCorrect && showSecondBlock === 'telegramBlock' &&
                    <div className="phone-confirmation__code-block">
                        <div>
                            <span className="form__subtitle">Ожидаем подтверждение...</span>
                            <Spinner additionalClasses={'h-40 w-40'}/>
                        </div>
                        <p>Подсказка: перейдите в Telegram и подтвердите номер</p>
                    </div>
                    }
            </div>
            {errorPhone && <ErrorInput message={errorPhone.message || 'Ошибка'}/>}
            {errorPhoneConfirmation && <ErrorInput message={errorPhoneConfirmation.message || 'Ошибка'}/>}
        </div>
    );
});

export default InputPhoneWithConfirm;