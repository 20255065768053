const validateEmail = (mailAddress) => {
    if (mailAddress) {
        let pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (pattern.test(mailAddress)) {
            return true;
        } else {
            return 'Некорректный email'
        };
    };
};

const validatePhone = (value) => {
    if (value) {
        const number = value.replace(/\D/g,'');
        return (number.length === 11) || 'Некорректный телефон';
    }
    
};

export {validateEmail, validatePhone};