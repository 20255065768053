import { useState } from "react";
import { FaRegCalendarAlt } from 'react-icons/fa';
import {BackArrow} from "../../../../components/arrow/Arrow";
import DatePicker from "react-datepicker";
import ru from 'date-fns/locale/ru';
import CustomInput from "../../../../components/inputs/CustomInput";

import './SearchOptions.scss';

export default function SearchOptions({startDate, setStartDate, endDate, setEndDate, identialSpecialization, setIdentialSpecialization, identialQualification, setIdentialQualification, resetFilters, toggleOptions}) {
    // const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null);


    // const onClickBackArrow = () => {

    // };

    const onSelectDatePicker = (range) => {
        const [startDate, endDate] = range;
        setStartDate(startDate);
        setEndDate(endDate);
    };

    return (
        <div className='search-options'>
            {/* <div className="header">
                <div className="container container--small">
                    <div className="header__content">
                        <BackArrow handleClick={onClickBackArrow}/>
                        <div>Настройки поиска</div>
                    </div>
                </div>
            </div> */}
            <div className="search-options__header">

            </div>
            <div className="search-options__content">
                <div className="container container--small">
                    <div className="search-options__content__wrapper">
                        
                        <div className="date-picker input">
                            <label htmlFor="date-picker">Выберите даты:</label>
                            <DatePicker
                            id="date-picker"
                            // className="input"
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(range) => onSelectDatePicker(range)}
                            minDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            locale={ru}
                            // placeholderText="Выберите даты"
                            />
                            <label htmlFor="date-picker">
                                <FaRegCalendarAlt className={`date-picker__icon ${startDate && endDate ? 'date-picker__icon--active' : ''}`}/>
                            </label>
                        </div>
                        
                        <CustomInput
                            withCheckbox={true}
                            labelText={'Только в Вашем городе'}/>

                        <CustomInput
                            withCheckbox={true}
                            labelText={'Только по Вашей специальности'}
                            checked={identialSpecialization}
                            onChange={() => setIdentialSpecialization(state => !state)}/>

                        <CustomInput
                            withCheckbox={true}
                            labelText={'Только по Вашей квалификации'}
                            checked={identialQualification}
                            onChange={() => setIdentialQualification(state => !state)}/>


                        {/* <div className="input ">
                            <label className="custom-checkbox">
                                <input type="checkbox" className="real-checkbox"/>
                                <span>Только в Вашем городе</span>
                            </label>
                        </div>

                        <div className="input ">
                            <label className="custom-checkbox">
                                <input type="checkbox" className="real-checkbox"/>
                                <span>Только по Вашей специальности</span>
                            </label>
                        </div>

                        <div className="input ">
                            <label className="custom-checkbox">
                                <input type="checkbox" className="real-checkbox"/>
                                <span>Только по Вашей квалификации</span>
                            </label>
                        </div> */}

                    </div>
                </div>
            </div>
            <div className="search-options__footer">
                <div className="container container--small">
                    <div className="search-options__footer__wrapper">
                        <button 
                            className="btn btn--primary btn--orange"
                            onClick={toggleOptions}>Сохранить</button>
                        <button 
                            className="btn btn--primary btn--white"
                            onClick={resetFilters}>Сбросить настройки</button>
                    </div>
                </div>
            </div>
            
        </div>
    )
};