import ChatCard from "./components/chatCard/ChatCard";
import PopupOrderInfo from "../../../popup/PopupOrderInfo";
import usePopup from "../../../../hooks/usePopup";

import './ChatsList.scss';

export default function ChatsList({chats, chatSelected, setChatSelected, order, setOrder, orderNumberSelected, setOrderNumberSelected}) {
    const {isPopupActive, hidePopup, showPopup} = usePopup();

    const formateDate = (date) => {
        if (!date) {
            return '';
        };
        const now = new Date();
        const tempDate = new Date(date);
        const year = tempDate.getFullYear().toString();
        const month = tempDate.getMonth().toString();
        const monthMod = (month.length > 1) ? month : `0${month}`;
        const day = tempDate.getDate().toString();
        const dayMod = (day.length > 1) ? day : `0${day}`;
        const hours = tempDate.getHours().toString();
        const hoursMod = (hours.length > 1) ? hours : `0${hours}`;
        const minutes = tempDate.getMinutes().toString();
        const minutesMod = (minutes.length > 1) ? minutes : `0${minutes}`;

        if ((now.getDate() - day > 0) || now.getTime() - tempDate.getTime() > 86400000) {
            return `${dayMod}.${monthMod}.${year}`
        };

        return `${hoursMod}:${minutesMod}`;
    };

    const handleClickOrderNumber = (event, orderNumber) => {
        event.stopPropagation();
        setOrderNumberSelected(orderNumber);
        showPopup();
    };

    const handleClosePopup = () => {
        hidePopup();
        setOrderNumberSelected(null);
        setOrder(null);
    };


    const renderChatsList = (chats) => {

        return (
            chats.map((chat, i) => {
                
                return (
                    <div 
                        key={i}
                        className={i === 0 ? null : "mt-16"}>
                        <ChatCard
                            number={chat.chatNumber}
                            name={chat.interlocutorName}
                            surname={chat?.interlocutorSurname || ''}
                            photo={chat?.interlocutorPhoto}
                            message={chat?.lastMessageText || ''}
                            date={formateDate(chat?.lastMessageDate)}
                            unreadMessagesNumber={chat.unreadMessagesNumber}
                            orderNumber={chat?.orderNumber}
                            isActive={chat.chatNumber === chatSelected}
                            handleClick={() => setChatSelected(chat.chatNumber)}
                            handleClickOrderNumber={handleClickOrderNumber}/>
                    </div>
                );
            })
        );
    };

    return (
        <section className={`chats-list ${(!chatSelected) ? 'chats-list--visible' : ''}`}>
            <div className="main__content">
                <div className="container container--small">
                    {renderChatsList(chats)}
                </div>
            </div>
            {isPopupActive && <PopupOrderInfo isActive={true} onClose={handleClosePopup} order={{...order, orderNumberSelected}}/>}
        </section>
    )
};