import ImageZoomer from "../../../../components/imageZoomer/ImageZoomer";
import registerPic1 from '../../../../assets/images/manual/quick-start-worker/register-pic1.png';
import registerPic2 from '../../../../assets/images/manual/quick-start-worker/register-pic2.png';
import registerPic3 from '../../../../assets/images/manual/quick-start-worker/register-pic3.png';
import freeOrdersList from '../../../../assets/images/manual/quick-start-worker/orders-list.png';
import freeOrdersMap from '../../../../assets/images/manual/quick-start-worker/orders-map.png';
import orderDetailsTrigger from '../../../../assets/images/manual/quick-start-worker/order-details-trigger.png';
import orderDetails from '../../../../assets/images/manual/quick-start-worker/order-details.png';
import notificationWorkerArrivedTrigger from '../../../../assets/images/manual/quick-start-worker/notification-user-arrived-trigger.png';
import notificationWorkerArrivedPopup from '../../../../assets/images/manual/quick-start-worker/notification-user-arrived-popup.png';
import notificationWorkerArrivedResult from '../../../../assets/images/manual/quick-start-worker/notification-user-arrived-result.png';
import notificationWorkerStartedTrigger from '../../../../assets/images/manual/quick-start-worker/notification-user-started-trigger.png';
import confirmationAskedStarted from '../../../../assets/images/manual/quick-start-worker/asked-confirmation-user-started.png';
import statusChangedStarted from '../../../../assets/images/manual/quick-start-worker/status-changed-user-started.png';
import notificationWorkerFinishedTrigger from '../../../../assets/images/manual/quick-start-worker/notification-user-finished-trigger.png';
import confirmationAskedFinished from '../../../../assets/images/manual/quick-start-worker/asked-confirmation-user-finished.png';
import statusChangedFinished from '../../../../assets/images/manual/quick-start-worker/status-changed-user-finished.png';



const QuickStartWorker = () => {
    return (
        <div className="quick-start">
            <h1 className='title'>Быстрый старт. Поиск и выполнение заказа.</h1>
            <p className='mt-30'>Наше приложение создано чтобы экономить Ваше время на поиск заказов. Поиск Вашего первого заказа займет не более 5 минут.</p>
            <p className="subtitle mt-30">Шаг №1. Зарегистрируйтесь в нашем приложении</p>
            <div className="images-wrapper">
                <ImageZoomer>
                    <img src={registerPic1} alt="registerPic1" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={registerPic2} alt="registerPic2" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={registerPic3} alt="registerPic3" />
                </ImageZoomer>
            </div>

            <p className="subtitle mt-30">Шаг №2. Найдите заказ</p>
            <p className="mt-20">Для поиска заказа, Вам необходимо нажать кнопку "Поиск заказа" на панели быстрого доступа. Вы можете искать заказы в общем списке заказов или найти подходящий заказ на карте.</p>
            <div className="images-wrapper">
                <ImageZoomer>
                    <img src={freeOrdersList} alt="free orders list" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={freeOrdersMap} alt="free orders map" />
                </ImageZoomer>
            </div>
            <p className="subtitle mt-30">Шаг №3. Закрепите за собой подходящий Вам заказ</p>
            <p className="mt-20">Когда Вы нашли подходящий Вам заказ, изучите подробную информацию и закрепите заказ за собой</p>
            <div className="images-wrapper">
                <ImageZoomer>
                    <img src={orderDetailsTrigger} alt="order details trigger" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={orderDetails} alt="order details" />
                </ImageZoomer>
            </div>
            <p className="subtitle mt-30">Шаг №4. Уведомьте Заказчика о прибытии на объект</p>
            <p className="mt-20">Важно! Когда Вы приехали на объект, обязательно отправьте Заказчику соответствующее уведомление. Если Вы не отправите уведомление, Заказчик не узнает о Вашем прибытии и может подумать что Вы не приехали или опоздали, и наши алгоритмы защиты Исполнителя не смогут Вам помочь, если Заказчик решит Вас оштрафовать за опоздание.</p>
            <div className="images-wrapper">
                <ImageZoomer>
                    <img src={notificationWorkerArrivedTrigger} alt="notification userArrived trigger" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={notificationWorkerArrivedPopup} alt="notification userArrived popup" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={notificationWorkerArrivedResult} alt="notification userArrived result" />
                </ImageZoomer>
            </div>
            <p className="subtitle mt-30">Шаг №4. Уведомьте Заказчика о начале работ</p>
            <div className="images-wrapper">
                <ImageZoomer>
                    <img src={notificationWorkerStartedTrigger} alt="notification worker started trigger" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={confirmationAskedStarted} alt="confirmation asked - started" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={statusChangedStarted} alt="status changed - started" />
                </ImageZoomer>
            </div>
            <p className="subtitle mt-30">Шаг №5. Уведомьте Заказчика о завершении работ</p>
            <div className="images-wrapper">
                <ImageZoomer>
                    <img src={notificationWorkerFinishedTrigger} alt="notification worker finished trigger" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={confirmationAskedFinished} alt="confirmation asked - finished" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={statusChangedFinished} alt="status changed - finished" />
                </ImageZoomer>
            </div>
            <p className='mt-30'>Вот и все, Ваш заказ выполнен, берите следующий заказ и выполняйте по аналогичной схеме.</p>
        </div>
    )
};

export default QuickStartWorker;