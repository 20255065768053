import { useCallback, useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { IconContext } from 'react-icons';
import useDate from '../../hooks/useDate';
import useUserAction from '../../hooks/useUserAction';
import useAlert from '../../hooks/useAlert';
import { DecorCircle, DecorDots, DecorIndicator, MessageLink, PhoneLink, DeleteLink, EnterLink, FooterInfo, RatingTrigger } from './CardComponents';
import { createChat} from '../../api/api';

import './Card.scss';


const OrderCard = ({role, ...props}) => {
    const {number, dateTime, address, specialization, worker, orderStatus, setUpdateOrdersList, openPopup, reviewByCustomer, authToken} = props;

    const navigate = useNavigate();
    const {customerCanceledOrder} = useUserAction();
    const {formatDate} = useDate();
    const {showAlert, showAlertRequire} = useAlert();
    const [footerLinks, setFooterLinks] = useState({
        messageLink: false,
        phoneLink: false,
        deleteLink: false
    });

    const handleClickDeleteLink = useCallback(() => {
        showAlertRequire('Желаете отменить заказ?', 'ok-cancel')
            .then(() => {
                customerCanceledOrder(number, authToken)
                    .then(() => setUpdateOrdersList(state => !state))
                    .then(() => showAlert(`Заказ №${number} отменен.`))
                    .catch(error => {
                        console.log(error);
                        showAlert(error.message);
                    });
            })
            .catch(error => {

            });
    }, [number]);

    const onMessageClick = async () => {
        try {
            const chatCreated = await createChat(number, authToken);
            navigate('/customer-messages', {state: {
                chatNumber: chatCreated.chatNumber
            }});

        } catch (error) {
            console.log(error);
            showAlert(error.message);
        }

    };

    useEffect(() => {
        const footerLinks = {
            messageLink: false,
            phoneLink: false,
            deleteLink: false
        };
        switch (orderStatus) {
            case 'Поиск исполнителя':
                footerLinks.deleteLink = true;
                break;
            case 'Назначен исполнитель':
                footerLinks.messageLink = true;
                footerLinks.phoneLink = true;
                footerLinks.deleteLink = true;
                break;
            case 'Исполнитель приехал':
                footerLinks.messageLink = true;
                footerLinks.phoneLink = true;
                break;
            case 'Исполнитель работает':
                footerLinks.messageLink = true;
                footerLinks.phoneLink = true;
                break;
            case 'Заказ выполнен':
                break;
            case 'Заказ закрыт':
                break;
            default:
                break;
        };
        setFooterLinks(footerLinks);
    }, [orderStatus]);

    return (
        <div className="card">
            <div className="card__header">
                <div className="card__order-number" onClick={() => navigate(`/customer-orders/${number}`)}>
                    Заказ №{number}
                </div>
                <div className="card__order-time">
                    {formatDate(dateTime)}
                </div>
                <DecorIndicator orderStatus={orderStatus}/>
            </div>
            <div className="card__content">
                <div className="card__content__item">
                    <div className="card__content__item__param">
                        Адрес
                    </div>
                    <div className="card__content__item__value">
                        {address}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                        <DecorDots/>
                    </div>
                </div>
                <div className="card__content__item">
                    <div className="card__content__item__param">
                        Специализация
                    </div>
                    <div className="card__content__item__value">
                        {specialization}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                        
                    {worker && 
                        <DecorDots/>
                    }
                    </div>
                </div>
            {worker &&
                <div className="card__content__item">
                    <div className="card__content__item__param">
                        Исполнитель
                    </div>
                    <div className="card__content__item__value">
                        {worker}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                    </div>
                </div>
            }
            {orderStatus === 'Заказ выполнен' && !reviewByCustomer &&
                <RatingTrigger
                    handleClick={() => openPopup(number)}/>
            }
                
            </div>
            <div className="card__footer">
                <FooterInfo orderStatus={orderStatus}>
                    {orderStatus}
                </FooterInfo>
                <div className="card__links">
                    <IconContext.Provider value={{className: 'card__links__icon'}}>
                        {footerLinks.messageLink && <MessageLink handleClick={onMessageClick}/>}
                        {footerLinks.phoneLink && <PhoneLink/>}
                        {footerLinks.deleteLink && <DeleteLink handleClick={handleClickDeleteLink}/>}
                        <Link to={`/customer-orders/${number}`}>
                            <EnterLink />
                        </Link>
                    </IconContext.Provider>
                </div>
            </div>
        </div>
    );
};

export default OrderCard;



