import OrderCardVacantMini from "../card/OrderCardVacantMini";
import Spinner from "../spinner/Spinner";

const PopupOrderInfo = ({isActive, onClose, order}) => {

    return (
        <div className={`popup ${isActive ? 'popup--active' : ''}`}>
            <div className="popup__dialog">
                <div className="popup__close" onClick={onClose}>&times;</div>

                <div className="popup__content mt-40">
                    {order?.address 
                    ? <OrderCardVacantMini
                        number={order?.orderNumberSelected}
                        dateTime={order?.dateTime}
                        address={order?.address}
                        specialization={order?.specialization}
                        price={order?.price}/>
                    : <Spinner/>}
                </div>
            </div>
        </div>
    )
};

export default PopupOrderInfo;