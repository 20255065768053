import { Children, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import { CiLogout } from 'react-icons/ci';
import { closeSideMenu } from "../../../store/slices/menuSlice";
import useAuth from '../../../hooks/useAuth';

import './MenuSide.scss';
import logo from '../../../assets/images/logo/logo_color.svg';

const MenuSideHeader = ({children}) => {
    return children;
};

const MenuSideContent = ({children}) => {
    return children;
};

const MenuSide = ({btnLogOut = true, isActive=false, additionalClasses={main:null, header:null, content:null}, children}) => {
    const dispatch = useDispatch();
    const isOpen = useSelector(state => state.menu.isMenuSideOpen);
    const navigate = useNavigate();
    const {logOut} = useAuth();

    const menuSideChildren = Children.map(children, child => child); //преобразуем дочерние элементы в массив, чтобы избежать проблем при поиске по массиву, т.к. если дочерний элемент один - то children по-умолчанию не является массивом
    const header = menuSideChildren.find(child => child.type === MenuSideHeader);
    const content = menuSideChildren.find(child => child.type === MenuSideContent);
    


    useEffect(() => {
        if (isOpen) {
            dispatch(closeSideMenu());
        }
    }, []);

    return (
        <div className={`menu-side ${isOpen || isActive ? 'menu-side--active' : ''} ${additionalClasses?.main || ''}`}>
            <div className="menu-side__wrapper">
                <IconContext.Provider value={{className: 'menu-side__links__item__icon__img'}}>
                    {header ||
                    <div className={`menu-side__header ${additionalClasses?.header || ''} `}>
                        <div 
                            className="menu-side__header__logo"
                            onClick={() => navigate('/')}>
                            <Link to='/'>
                                <img src={logo} alt="logo" />
                            </Link>
                        </div>
                        <div 
                            className="menu-side__header__btn-close"
                            onClick={() => dispatch(closeSideMenu())}>&times;</div>
                    </div>}
                
                    <div className={`menu-side__content ${additionalClasses?.content || ''}`}>
                        {content || children}
                    </div>
                
                    {
                        btnLogOut &&
                        <div className="menu-side__footer" onClick={logOut}>
                            <div className="menu-side__footer__icon">
                                <CiLogout/>
                            </div>
                            <div className="menu-side__footer__text">
                                Выход
                            </div>
                        </div>
                    }
                </IconContext.Provider>
            </div>
        </div>
    )
};

export default MenuSide;
export {MenuSideHeader, MenuSideContent};