import {IoChevronBack, IoChevronForward} from "react-icons/io5";
import './Arrow.scss';

const BackArrow = ({handleClick}) => {

    return (
        <div 
            className="arrow"
            onClick={handleClick}>
            <IoChevronBack className='arrow-icon'/>
        </div>
    );
};

const BackArrowWithText = ({text, handleClick, additionalClasses}) => {

    return (
        <div 
            className={`back-arrow--with-text ${additionalClasses ? additionalClasses : ''}`}
            onClick={handleClick}>
            <BackArrow/>
            <div>{text}</div>
        </div>
    );
};

const ForwardArrow = ({className, handleClick}) => {

    return (
        <div 
            className={`arrow ${className ? className : ''}`}
            onClick={handleClick}>
            <IoChevronForward className='arrow-icon'/>
        </div>
    );
};

export {
    BackArrow,
    BackArrowWithText,
    ForwardArrow
};