import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import useAlert from "./useAlert";
import { fetchUnreadData } from "../api/api";

export default function useUnreadData() {
    const {unreadMessages, unreadNotifications} = useSelector(state => state.unreadData);

    return {
        unreadMessages,
        unreadNotifications
    };

};