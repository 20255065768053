import UserInfo from "../../components/header/components/UserInfo";
import HeaderWorker from "../../components/header/HeaderWorker";
import ButtonBurger from "../../components/buttonBurger/ButtonBurger";
import MenuSideWorker from '../../components/menu/menuSide/MenuSideWorker';
import MenuQuickWorker from "../../components/menu/menuQuick/MenuQuickWorker";
import ReviewsList from './components/ReviewsList';
import useReviews from '../../hooks/useReviews';

export default function WorkerReviewsPage () {
    const {userReviews} = useReviews();
    
    return (
        <>
            <HeaderWorker>
                <div className="header__content">
                    <ButtonBurger />
                    <h1 className="header__title">Мои отзывы</h1>
                    <UserInfo/>
                </div>
            </HeaderWorker>

            <div className="main__wrapper">
                <MenuSideWorker />
                <main className="main__content">
                    <div className="container container--small">
                        <ReviewsList reviews={userReviews}/>
                    </div>
                </main>
            </div>

            <MenuQuickWorker/>
        </>
    );
};