import { AiFillFile } from "react-icons/ai";
import ImageZoomer from "../imageZoomer/ImageZoomer";
import downloadFile from "../../utils/downloadFile";
import './FilesList.scss';

const FilesList = ({filesList, additionalClasses, preview=true}) => {
     
    return (
        <div className={`files-list ${additionalClasses ? additionalClasses : ''}`}>
            {filesList.map((file, i) => {
                const regex = /\.(jpe?g|png|gif|bmp|webp)$/i;
                const isImage = regex.test(file.url);
                const isPdf = /\.pdf$/i.test(file.url);
                console.log(file)
                return (
                    <>
                        {isImage
                        ? <ImageZoomer key={i}>
                            {preview
                            ? <img 
                                className="file-preview" 
                                src={file.url} 
                                alt={file.alt}
                                onError={(e) => {
                                    // e.target.onError = null;
                                    // e.target.src = "https://re-home.su/uploads/509/balvan-foto.jpg";
                                    e.target.src = 'https://cdn4.iconfinder.com/data/icons/small-n-flat/24/file-empty-1024.png';

                                    // console.log(e.target);
                                }} />
                            : <AiFillFile className="file-icon" data-url={file.url} />}
                        </ImageZoomer>
                        : <div 
                            className="d-flex items-center cursor-pointer"
                            onClick={() => isPdf ? downloadFile(file.url, true) : downloadFile(file.url)}>
                                <AiFillFile 
                                    className="file-icon" 
                                    data-url={file.url}/>
                                <p className="fs-10">{file.alt}</p>
                        </div>
                        }
                    </>
                    
                );
            })}
        </div>
    );
};

export default FilesList;