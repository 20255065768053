import { useState } from 'react';
import { IoIosContact } from 'react-icons/io';
import {HiOutlinePlus} from 'react-icons/hi';
import InputIntLabel from '../../../components/inputs/InputInnerLabel';
import { useEffect } from 'react';



const CustomerSettingsProfile = ({user, setSettingsChanged, useFormMethodsProfile}) => {
    const {register, watch} = useFormMethodsProfile;
    
    const newDataProfile = watch();
    const userPhoto = watch('photo');
   
    useEffect(() => {
        const newDataProfileMod = {...newDataProfile, photo: newDataProfile.photo?.length > 0 ? newDataProfile.photo : null}
        const isProfileChanged = Object.values(newDataProfileMod).some(item => !!item);
        setSettingsChanged(isProfileChanged);
    }, [newDataProfile]);

    
    return (
        <form>
            <div className="customer-settings__foto">
                {userPhoto?.length > 0 || user?.photo
                ? <img 
                    className='customer-settings__foto__img' 
                    src={userPhoto?.length > 0 
                        ? URL.createObjectURL(userPhoto[0]) 
                        : user.photo} 
                    alt="userPhoto" />
                : <IoIosContact className='customer-settings__foto__img'/>}
                <label htmlFor='userPhotoUploader'
                    className='customer-settings__foto__plus'>
                    <HiOutlinePlus size='15px'/>
                </label>
                <input 
                    id='userPhotoUploader' 
                    type='file' 
                    {...register('photo')}/>
            </div>

            <div>
                <InputIntLabel
                    // registerWithName={{register: register, name: 'name'}}
                    useFormMethods={{
                        register: {
                            register,
                            name: 'name',
                        }
                    }}
                    labelText='Имя:'
                    type='text'
                    placeholder={user.name}/>
                <InputIntLabel
                    useFormMethods={{
                        register: {
                            register,
                            name: 'middleName',
                        }
                    }}
                    labelText='Отчество:'
                    type='text'
                    placeholder={user.middleName || '' }/>
                <InputIntLabel
                    useFormMethods={{
                        register: {
                            register,
                            name: 'surname',
                        }
                    }}
                    labelText='Фамилия:'
                    type='text'
                    placeholder={user.surname || ''}/>
                <InputIntLabel
                    useFormMethods={{
                        register: {
                            register,
                            name: 'country',
                        }
                    }}
                    labelText='Страна:'
                    type='text'
                    placeholder='Россия'/>
                <InputIntLabel
                    useFormMethods={{
                        register: {
                            register,
                            name: 'city',
                        }
                    }}
                    labelText='Город:'
                    type='text'
                    placeholder={user.city}/>
                <InputIntLabel
                    useFormMethods={{
                        register: {
                            register,
                            name: 'company',
                        }
                    }}
                    labelText='Компания:'
                    type='text'
                    placeholder={user.company}/>
                <InputIntLabel
                    useFormMethods={{
                        register: {
                            register,
                            name: 'position',
                        }
                    }}
                    labelText='Должность:'
                    type='text'
                    placeholder={user.position}/>
            </div>
        </form>
    )
};

export default CustomerSettingsProfile;