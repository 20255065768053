import { IoIosContact } from 'react-icons/io';
import {BackArrow} from '../../../../../../components/arrow/Arrow';
import useDate from '../../../../../../hooks/useDate';

import './ChatHeader.scss';

const ChatHeader = ({setChatSelected, interlocutor, setInterlocutor, setMessages}) => {

    const {formatDate} = useDate();

    const renderLastUserActiveInfo = (lastActiveDate) => {
        if (lastActiveDate === null) {
            return 'Был на сайте - давно';
        };

        const lastActiveDateMod = new Date(lastActiveDate);
        const currentDate = new Date();
        const minInterval = 60000;
        
        if (currentDate.getTime() - lastActiveDateMod.getTime() < minInterval) {
            return 'Сейчас на сайте';
        }

        return `Был на сайте - ${formatDate(lastActiveDate, 'hh:mm, DD.MM.YYYY')}`;
    };

    const onClickBackArrow = () => {
        setChatSelected(null);
        setInterlocutor(null);
        setMessages([]);
    };

    return (
        <div className="header">
            <div className="container container--small">
                <div className="header__content">
                    <BackArrow handleClick={onClickBackArrow}/>
                    {/* <img 
                        className='header__img'
                        src={userPhoto} alt="userPhoto"/> */}
                    {interlocutor?.photo
                    ? <img
                        className='header__img' 
                        src={interlocutor.photo} alt="interlocutorPhoto" />
                    : <IoIosContact className='header__img'/>
                    }
                    <div className="chat-header__text">
                        <div className="chat-header__user-name">
                            {`${interlocutor?.name || ''} ${interlocutor?.surname || ''}`}
                        </div>
                        <div className="chat-header__details">
                            { renderLastUserActiveInfo(interlocutor?.lastActive) }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ChatHeader;