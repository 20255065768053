//В компонент нужно передать состав меню в виде объекта {Ссылка: адрес ссылки}

import { Link, NavLink } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import './DropDownMenu.scss';

const DropDownMenu = ({menuObj}) => {
    const {logOut} = useAuth();

    const renderMenuLinks = (menuObj) => {
        const menuElements = [];

        for (let key in menuObj) {
            menuElements.push(
                <div className='drop-down-menu__link' key={key}>
                    {key.toLowerCase() !== 'выход'
                    ? <NavLink to={menuObj[key]}>{key}</NavLink>
                    : <span onClick={logOut}>{key}</span>}
                </div>);
        };

        return menuElements;
    };  

    return (
        <div className={`drop-down-menu`}>
            {renderMenuLinks(menuObj)}
        </div>
    );
};

export default DropDownMenu;