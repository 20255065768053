import { useState, useEffect } from 'react';
import './Timer.scss';

const Timer = ({startValue, onFinished}) => {
    const [timerValue, setTimerValue] = useState(startValue);
    
    const transformTimerValue = (timerValue) => {
        const minutes = Math.floor(timerValue/60);
        const seconds = timerValue - minutes*60;

        return `${minutes < 10 ? '0' + minutes : minutes} : ${seconds < 10 ? '0' + seconds : seconds}`;
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setTimerValue(state => {
                if (state <= 0) {
                    clearInterval(timer);
                    return state;
                }
                return state - 1;
            });
        }, 1000);
        
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (timerValue <= 0 && onFinished) {
            onFinished();
        };
    }, [timerValue]);

    return (
        <div className="timer">
            {transformTimerValue(timerValue)}
        </div>
    )
};

export default Timer;