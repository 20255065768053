import HomeHeader from "../homePage/components/homeHeader/HomeHeader";
import './FeedbackPage.scss';

export default function FeedbackPage() {

    return (
        <>
            <HomeHeader/>
            <h1>Тут мы разместим форму для обратной связи. Чем-то похожую на форму связи с техподдержкой</h1>
            <h2>В форме обратной связи будут поля: имя, фамилия, телефон, email, тема обращения, сообщение, возможность приложить файл, чекбокс согласия на обработку персональных данных, и кнопка отправки формы</h2>
        </>
    );
};