

const UserAgreement = () => {
    return (
        <div className="user-agreement">
            <p className="mt-10 fw-600">ПРИМЕР ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ</p>
            <p className="mt-10 fw-600">1. ОБЩИЕ ПОЛОЖЕНИЯ</p>
            <p>1.1. Настоящее Пользовательское соглашение (далее – Соглашение) относится к сайту «НАЗВАНИЕ
САЙТА», расположенному по адресу АДРЕС САЙТА.</p>
            <p>1.2. Сайт «НАЗВАНИЕ САЙТА» (далее – Сайт) является собственностью юридического лица
ОРГАНИЗАЦИЯ (ОГРН: НОМЕР, ИНН: НОМЕР, адрес регистрации: ЮР.АДРЕС)</p>
            <p>1.3. Настоящее Соглашение регулирует отношения между Администрацией сайта «НАЗВАНИЕ САЙТА»
(далее – Администрация сайта) и Пользователем данного Сайта.</p>
            <p>1.4. Администрация сайта оставляет за собой право в любое время изменять, добавлять или удалять
пункты настоящего Соглашения без уведомления Пользователя.</p>
            <p>1.5. Использование Сайта Пользователем означает принятие Соглашения и изменений, внесенных в
настоящее Соглашение.</p>
            <p>1.6. Пользователь несет персональную ответственность за проверку настоящего Соглашения на
наличие изменений в нем.</p>
            <p>2. ОПРЕДЕЛЕНИЯ ТЕРМИНОВ</p>
            <p>2.1. Перечисленные ниже термины имеют для целей настоящего Соглашения следующее значение:</p>
            <p>2.1.1 «НАЗВАНИЕ САЙТА» – Интернет-ресурс, расположенный на доменном имени АДРЕС САЙТА,
осуществляющий свою деятельность посредством Интернет-ресурса и сопутствующих ему сервисов
(далее - Сайт).</p>
            <p>2.1.2. «НАЗВАНИЕ САЙТА» – сайт, содержащий информацию о Товарах и/или Услугах и/или Иных
ценностях для пользователя, Продавце и/или Исполнителе услуг, позволяющий осуществить выбор,
заказ и (или) приобретение Товара, и/или получение услуги.</p>
            <p>2.1.3. Администрация сайта – уполномоченные сотрудники на управления Сайтом, действующие от
имени юридического лица ОРГАНИЗАЦИЯ.</p>
            <p>2.1.4. Пользователь сайта (далее - Пользователь) – лицо, имеющее доступ к Сайту, посредством сети
Интернет и использующее Сайт.</p>
            <p>2.1.5. Содержание сайта (далее – Содержание) - охраняемые результаты интеллектуальной
деятельности, включая тексты литературных произведений, их названия, предисловия, аннотации,
статьи, иллюстрации, обложки, музыкальные произведения с текстом или без текста, графические,
текстовые, фотографические, производные, составные и иные произведения, пользовательские
интерфейсы, визуальные интерфейсы, названия товарных знаков, логотипы, программы для ЭВМ, базы
данных, а также дизайн, структура, выбор, координация, внешний вид, общий стиль и расположение
данного Содержания, входящего в состав Сайта и другие объекты интеллектуальной собственности все
вместе и/или по отдельности, содержащиеся на сайте АДРЕС САЙТА.</p>
            <p>3. ПРЕДМЕТ СОГЛАШЕНИЯ</p>
            <p>3.1. Предметом настоящего Соглашения является предоставление Пользователю доступа к
содержащимся на Сайте Товарам и/или оказываемым услугам.</p>
            <p>3.1.1. Сайт предоставляет Пользователю следующие виды услуг (сервисов):
 предоставление Пользователю возможности размещения сообщений, комментариев,
рецензий Пользователей, выставления оценок контенту сайта;
 ознакомление с товарами/услугами, размещенными на Сайте;
 выбор и заказ товаров/услуг для осуществления последующей покупки или оформления на
данном Сайте.</p>
            <p>3.1.2. Под действие настоящего Соглашения подпадают все существующие (реально
функционирующие) на данный момент услуги (сервисы) Сайта, а также любые их последующие
модификации и появляющиеся в дальнейшем дополнительные услуги (сервисы).</p>
            <p>3.2. Доступ к сайту предоставляется на бесплатной основе.</p>
            <p>3.3. Настоящее Соглашение является публичной офертой. Получая доступ к Сайту Пользователь
считается присоединившимся к настоящему Соглашению.</p>
            <p>3.4. Использование материалов и сервисов Сайта регулируется нормами действующего
законодательства Российской Федерации</p>
            <p>4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</p>
            <p>4.1. Администрация сайта вправе:</p>
            <p>4.1.1. Изменять правила пользования Сайтом, а также изменять содержание данного Сайта.
Изменения вступают в силу с момента публикации новой редакции Соглашения на Сайте.</p>
            <p>4.2. Пользователь вправе:</p>
            <p>4.2.1. Пользоваться всеми имеющимися на Сайте услугами, а также приобретать любые Товары и/или
Услуги, предлагаемые на Сайте.</p>
            <p>4.2.2. Задавать любые вопросы, относящиеся к услугам сайта:
 по телефону: ТЕЛЕФОН
 по электронной почте: ПОЧТА
 через Форму обратной связи, расположенную по адресу: АДРЕС САЙТА</p>
            <p>4.2.3. Пользоваться Сайтом исключительно в целях и порядке, предусмотренных Соглашением и не
запрещенных законодательством Российской Федерации.</p>
            <p>4.2.4. Требовать от администрации скрытия любой информации о пользователе.</p>
            <p>4.2.5. Использовать информацию сайта в коммерческих целях без специального разрешения.</p>
            <p>4.3. Пользователь Сайта обязуется:</p>

4.3.1. Предоставлять по запросу Администрации сайта дополнительную информацию, которая имеет
непосредственное отношение к предоставляемым услугам данного Сайта.
4.3.2. Соблюдать имущественные и неимущественные права авторов и иных правообладателей при
использовании Сайта.
4.3.3. Не предпринимать действий, которые могут рассматриваться как нарушающие нормальную
работу Сайта.
4.3.4. Не распространять с использованием Сайта любую конфиденциальную и охраняемую
законодательством Российской Федерации информацию о физических либо юридических лицах.
4.3.5. Избегать любых действий, в результате которых может быть нарушена конфиденциальность
охраняемой законодательством Российской Федерации информации.
4.3.6. Не использовать Сайт для распространения информации рекламного характера, иначе как с
согласия Администрации сайта.
4.3.7. Не использовать сервисы с целью:
4.3.7.1. нарушения прав несовершеннолетних лиц и (или) причинение им вреда в любой форме.
4.3.7.2. ущемления прав меньшинств.
4.3.7.3. представления себя за другого человека или представителя организации и (или) сообщества
без достаточных на то прав, в том числе за сотрудников данного сайта.
4.3.7.4. введения в заблуждение относительно свойств и характеристик какого-либо Товара и/или
услуги, размещенных на Сайте.
4.3.7.5. некорректного сравнения Товара и/или Услуги, а также формирования негативного отношения
к лицам, (не) пользующимся определенными Товарами и/или услугами, или осуждения таких лиц.
4.3.7.6. загрузки контента, который является незаконным, нарушает любые права третьих лиц;
пропагандирует насилие, жестокость, ненависть и (или) дискриминацию по расовому, национальному,
половому, религиозному, социальному признакам; содержит недостоверные сведения и (или)
оскорбления в адрес конкретных лиц, организаций, органов власти.
4.3.7.7. побуждения к совершению противоправных действий, а также содействия лицам, действия
которых направлены на нарушение ограничений и запретов, действующих на территории Российской
Федерации.
4.3.8. Обеспечить достоверность предоставляемой информации
4.3.9. Обеспечивать сохранность личных данных от доступа третьих лиц.
4.4. Пользователю запрещается:
4.4.1. Использовать любые устройства, программы, процедуры, алгоритмы и методы, автоматические
устройства или эквивалентные ручные процессы для доступа, приобретения, копирования или
отслеживания содержания Сайта.
4.4.2. Нарушать надлежащее функционирование Сайта.
4.4.3. Любым способом обходить навигационную структуру Сайта для получения или попытки
получения любой информации, документов или материалов любыми средствами, которые специально
не представлены сервисами данного Сайта.
4.4.4. Несанкционированный доступ к функциям Сайта, любым другим системам или сетям,
относящимся к данному Сайту, а также к любым услугам, предлагаемым на Сайте.
4.4.4. Нарушать систему безопасности или аутентификации на Сайте или в любой сети, относящейся к
Сайту.
4.4.5. Выполнять обратный поиск, отслеживать или пытаться отслеживать любую информацию о
любом другом Пользователе Сайта.
4.4.6. Использовать Сайт и его Содержание в любых целях, запрещенных законодательством
Российской Федерации, а также подстрекать к любой незаконной деятельности или другой
деятельности, нарушающей права Сайта или других лиц.
5. ИСПОЛЬЗОВАНИЕ САЙТА
5.1. Сайт и Содержание, входящее в состав Сайта, принадлежит и управляется Администрацией сайта.
5.2. Содержание Сайта защищено авторским правом, законодательством о товарных знаках, а также
другими правами, связанными с интеллектуальной собственностью, и законодательством о
недобросовестной конкуренции.
5.3. Настоящее Соглашение распространяет свое действия на все дополнительные положения и
условия о покупке Товара и/или оказанию услуг, предоставляемых на Сайте.
5.4. Информация, размещаемая на Сайте не должна истолковываться как изменение настоящего
Соглашения.
5.5. Администрация сайта имеет право в любое время без уведомления Пользователя вносить
изменения в перечень Товаров и услуг, предлагаемых на Сайте, и (или) их цен.
5.6. Документ указанный в пункте 5.7. настоящего Соглашения регулирует в соответствующей части и
распространяют свое действие на использование Пользователем Сайта.
5.7. Политика конфиденциальности: ССЫЛКА НА ДОКУМЕНТ
5.8. Любой из документов, перечисленных в пункте 5.7 настоящего Соглашения может подлежать
обновлению. Изменения вступают в силу с момента их опубликования на Сайте.
6. ОТВЕТСТВЕННОСТЬ
6.1. Любые убытки, которые Пользователь может понести в случае умышленного или неосторожного
нарушения любого положения настоящего Соглашения, а также вследствие несанкционированного
доступа к коммуникациям другого Пользователя, Администрацией сайта не возмещаются.
6.2. Администрация сайта не несет ответственности за:
6.2.1. Задержки или сбои в процессе совершения операции, возникшие вследствие непреодолимой
силы, а также любого случая неполадок в телекоммуникационных, компьютерных, электрических и
иных смежных системах.
6.2.2. Действия систем переводов, банков, платежных систем и за задержки связанные с их работой.
6.2.3. Надлежащее функционирование Сайта, в случае, если Пользователь не имеет необходимых
технических средств для его использования, а также не несет никаких обязательств по обеспечению
пользователей такими средствами.
7. НАРУШЕНИЕ УСЛОВИЙ ПОЛЬЗОВАТЕЛЬСКОГО
СОГЛАШЕНИЯ
7.1. Администрация сайта имеет право раскрыть информацию о Пользователе, если действующее
законодательство Российской Федерации требует или разрешает такое раскрытие.
7.2. Администрация сайта вправе без предварительного уведомления Пользователя прекратить и (или)
заблокировать доступ к Сайту, если Пользователь нарушил настоящее Соглашение или содержащиеся
в иных документах условия пользования Сайтом, а также в случае прекращения действия Сайта либо
по причине технической неполадки или проблемы.
7.3. Администрация сайта не несет ответственности перед Пользователем или третьими лицами за
прекращение доступа к Сайту в случае нарушения Пользователем любого положения настоящего
Соглашения или иного документа, содержащего условия пользования Сайтом.
8. РАЗРЕШЕНИЕ СПОРОВ
8.1. В случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения
обязательным условием до обращения в суд является предъявление претензии (письменного
предложения о добровольном урегулировании спора).
8.2. Получатель претензии в течение 30 календарных дней со дня ее получения, письменно
уведомляет заявителя претензии о результатах рассмотрения претензии.
8.3. При невозможности разрешить спор в добровольном порядке любая из Сторон вправе обратиться
в суд за защитой своих прав, которые предоставлены им действующим законодательством Российской
Федерации.
8.4. Любой иск в отношении условий использования Сайта должен быть предъявлен в течение 5 дней
после возникновения оснований для иска, за исключением защиты авторских прав на охраняемые в
соответствии с законодательством материалы Сайта. При нарушении условий данного пункта любой
иск оставляется судом без рассмотрения.
9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ
9.1. Администрация сайта не принимает встречные предложения от Пользователя относительно
изменений настоящего Пользовательского соглашения.
9.2. Отзывы Пользователя, размещенные на Сайте, не являются конфиденциальной информацией и
могут быть использованы Администрацией сайта без ограничений.
        </div>
    )
};

export default UserAgreement;