import { useState } from 'react';
import { IoStar } from 'react-icons/io5';
import './StarRating.scss';

const StarRating = ({rating, setRating}) => {
    const [hoverRating, setHoverRating] = useState(0);

    const handleRatingClick = (number) => {
        setRating(number);
    };

    const handleRatingHover = (number) => {
        setHoverRating(number);
    };

    const handleRatingLeave = () => {
        setHoverRating(0);
    };


    return (
        <div className="star-rating">
            {[1,2,3,4,5].map(number => (
                <IoStar
                    key={number} 
                    className={`star-item ${(number <= rating) || (number <= hoverRating) ? 'color-orange-4' : ''}`}
                    onClick={() => handleRatingClick(number)}
                    onMouseEnter={() => handleRatingHover(number)}
                    onMouseLeave={() => handleRatingLeave(number)}/>
            ))}
        </div>
    )
};

export default StarRating;