import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

const initialState = {
    isOpen: false,
    type: 'ok',
    message: '',
    resolve: null,
    reject: null
};

const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        openAlert: (state, action) => {
            state.isOpen = true;
            state.message = action.payload.message;
            if (action.payload?.type) {
                state.type = action.payload.type;
            }
            if (action.payload?.resolve) {
                state.resolve = action.payload.resolve;
            };
            if (action.payload?.reject) {
                state.reject = action.payload.reject;
            };
        },
        closeAlert: (state) => {
            state.isOpen = false;
            state.message = '';
            state.type = 'ok';
            state.reject = null;
            if (state.resolve) {
                state.resolve();
                state.resolve = null;
            };
        },
        closeAlertWithReject: (state) => {
            state.isOpen = false;
            state.message = '';
            state.type = 'ok';
            state.resolve = null;
            if (state.reject) {
                state.reject();
                state.reject = null;
            };
        },
        modifyMessage: (state, action) => {
            state.message = action.payload.message;
        }
    },
});

const {actions, reducer} = alertSlice;

export const {openAlert, closeAlert, closeAlertWithReject, modifyMessage} = actions;

export default reducer;