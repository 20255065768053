import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import HeaderWorker from "../../components/header/HeaderWorker";
import ButtonBurger from "../../components/buttonBurger/ButtonBurger";
import MenuSideWorker from "../../components/menu/menuSide/MenuSideWorker";
import MenuQuickWorker from "../../components/menu/menuQuick/MenuQuickWorker";
import { BackArrowWithText } from "../../components/arrow/Arrow";
import Spinner from '../../components/spinner/Spinner';
import { MessageLink, PhoneLink } from "../../components/card/CardComponents";
import useAuth from "../../hooks/useAuth";
import useDate from '../../hooks/useDate';
import useAlert from "../../hooks/useAlert";
import useUserAction from "../../hooks/useUserAction";
import { createChat, fetchOrder } from "../../api/api";

import './OrderPage.scss';



const WorkerOrderPage = () => {
    const [order, setOrder] = useState();
    const [orderLinks, setOrderLinks] = useState({
        acceptLink: null,
        messageLink: null,
        phoneLink: null,
        deleteBtn: null
    });
    const {orderNumber} = useParams();
    const {formatDate} = useDate();
    const navigate = useNavigate();
    const {showAlert, showAlertRequire} = useAlert();
    const {authToken} = useAuth();
    const {workerAcceptedOrder, workerCanceledOrder} = useUserAction();
    
    const handleAcceptOrder = () => {
        showAlertRequire(`Желаете принять в работу заказ №${orderNumber}?`, 'ok-cancel')
            .then(() => {
                workerAcceptedOrder(orderNumber, authToken)
                    .then(() => showAlertRequire(`Вы приняли в работу заказ №${orderNumber}`))
                    .then(() => navigate('/worker-orders'))
                    .catch(error => {
                        console.log(error);
                        showAlert(error.message);
                    });
            })
            .catch(error => {
                
            });
    };

    const handleCancelOrder = () => {
        showAlertRequire('Желаете отменить заказ?', 'ok-cancel')
            .then(() => {
                workerCanceledOrder(orderNumber, authToken)
                    .then(() => {
                        showAlert(`Вы отменили заказ №${orderNumber}`);
                        navigate('/worker-orders');
                    })
                    .catch(error => {
                        console.log(error);
                        showAlert(error.message);
                    });
            })
            .catch(error => {

            });
    };

    const onMessageClick = async () => {
        try {
            const chatCreated = await createChat(orderNumber, authToken);
            navigate('/worker-messages', {state: {
            chatNumber: chatCreated.chatNumber
        }});
        } catch (error) {
            console.log(error);
            showAlert(error.message);
        };
    };

    useEffect(() => {
        fetchOrder(orderNumber, authToken)
            .then(data => setOrder(data))
            .catch(error => {
                console.log(error);
                showAlert(error.message);
            });
    }, []);

    useEffect(() => {
        const orderLinks = {
            acceptLink: null,
            messageLink: null,
            phoneLink: null,
            deleteLink: null
        };
        switch (order?.status) {
            case 'Поиск исполнителя':
                orderLinks.acceptLink = true;
                orderLinks.messageLink = true;
                break;
            case 'Назначен исполнитель':
                orderLinks.messageLink = true;
                orderLinks.phoneLink = true;
                orderLinks.deleteLink = true;
                break;
            case 'Исполнитель приехал':
                orderLinks.messageLink = true;
                orderLinks.phoneLink = true;
                orderLinks.deleteLink = true;
                break;
            case 'Исполнитель работает':
                orderLinks.messageLink = true;
                orderLinks.phoneLink = true;
                orderLinks.deleteLink = true;
                break;
            case 'Заказ выполнен':
                break;
            case 'Заказ закрыт':
                break;
            default:
                break;
        };
        setOrderLinks(orderLinks);
    }, [order]);

    return (
        < >
            <HeaderWorker>
                <div className="header__content">
                    <ButtonBurger />
                    <h1 className="header__title">{`Заказ №${orderNumber}`}</h1>
                </div>
            </HeaderWorker>

        <div className="main__wrapper">
            <MenuSideWorker />
            <main className="main__content">
                <div className="container container--small">
                    <div className="d-flex justify-between mt--14">
                        <BackArrowWithText
                            text={'Назад'}
                            additionalClasses={'color-orange4'}
                            handleClick={() => navigate(-1)}/>
                        {orderLinks.phoneLink && orderLinks.messageLink &&
                        <div className="d-flex items-center gap-x-30">
                            <MessageLink 
                                additionalClasses={'d-flex items-center color-orange4'}
                                iconAdditionalClasses={'h-24 w-24'}
                                handleClick={onMessageClick}/>
                            <PhoneLink
                               additionalClasses={'d-flex items-center color-orange4'}
                               iconAdditionalClasses={'h-24 w-24'}/>
                        </div>}
                    </div>
                    {order
                    ? <>
                        <div className="input-group">
                            <div className="input__label">
                                Адрес 
                                <div className="input mt-5">
                                    {order?.address}
                                </div>
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="input__label">
                                Дата и время 
                                <div className="input mt-5">
                                    {order?.dateTime && formatDate(order?.dateTime)}
                                </div>
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="input__label">
                                Категория 
                                <div className="input mt-5">
                                    {order?.category}
                                </div>
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="input__label">
                                Специализация 
                                <div className="input mt-5">
                                    {order?.specialization}
                                </div>
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="input__label">
                                Квалификация 
                                <div className="input mt-5">
                                    {order?.qualification}
                                </div>
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="input__label">
                                Описание 
                                <div className="input mt-5">
                                    {order?.description}
                                </div>
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="input__label">
                                Цена 
                                <div className="input mt-5">
                                    {order?.price && `${order?.price} руб.`}
                                </div>
                            </div>
                        </div>
                        <div className="input-group">
                            <div className="input__label">
                                Заказчик 
                                <div className="input mt-5">
                                    {order?.customer}
                                </div>
                            </div>
                        </div>

                        <div className="order-page__footer">
                            {orderLinks.deleteLink &&
                                <button
                                    className='btn btn--primary btn--outline btn-border--red m-center h-40 w-210 ls-2'
                                    onClick={handleCancelOrder}
                                    >Отменить заказ</button>}

                            {order.status === 'Поиск исполнителя' && 
                            <button 
                                className="btn btn--primary btn--outline m-center h-40 w-210 ls-2"
                                >Задать вопрос</button>}
                            
                            {orderLinks.acceptLink &&
                            <button
                                className="btn btn--primary btn--orange m-center h-40 w-210 ls-2"
                                onClick={handleAcceptOrder}
                                >Принять заказ</button>}
                            
                        </div>
                    </>
                    : <Spinner additionalClasses={'h-full w-full'}/>}
                </div>
                
                
            </main>
        </div>

        <MenuQuickWorker/>
        



    </>
    );
};

export default WorkerOrderPage;