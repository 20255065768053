import Message from './components/message/Message';
import Spinner from '../../../../../spinner/Spinner';
import useDate from '../../../../../../hooks/useDate';
import './MessagesList.scss';

const DateIndicator = ({date}) => {
    return (
        <div className="date-indicator">
            {date}
        </div>
    );
};

const MessagesList = ({chatSelected, messages, messagesListRef}) => {
    const {formatDate} = useDate();

    const renderMessagesList = (messages) => {
        let date = null;

        return messages.map((message, i) => {
            const dateMod = formatDate(message.timeCreate, 'DD.MM.YYYY');
            let isNewDate = false;

            if (date !== dateMod) {
                date = dateMod;
                isNewDate = true;
            };

            return (
                <>
                    {isNewDate && <DateIndicator date={formatDate(message.timeCreate, 'DD.MM.YYYY')}/>}
                    <div 
                        key={i}
                        className={i !== 0 ? 'mt-16' : null}>
                        <Message
                                text={message.text}
                                dateTime={message.timeCreate}
                                isOwnMessage={message.isOwnMessage}
                                files={message.files}
                                />
                    </div>
                </>
            )
        })
    };

    return (
        <div className="messages-list" ref={messagesListRef}>
            <div className="container container--small">
                <div className="messages-list__wrapper">
                    {chatSelected
                        ? messages
                            ? renderMessagesList(messages)
                            : <Spinner additionalClasses={'h-full w-full'}/>
                        : null
                    }
                </div>
            </div>
        </div>
    );
};

export default MessagesList;