import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineBulb, AiOutlineMail, AiOutlineQuestionCircle, AiOutlineStar } from 'react-icons/ai';
import { BiSupport } from 'react-icons/bi';
import { GiSuitcase } from 'react-icons/gi';
import { IoSettingsOutline } from 'react-icons/io5';
import { SlGraduation } from 'react-icons/sl';
import MenuSide from './MenuSide';
import UnreadBadge from '../../unreadBadge/UnreadBadge';
import useUrl from '../../../hooks/useUrl';
import useUnreadData from '../../../hooks/useUnreadData';

import './MenuSide.scss';


const MenuSideWorker = () => {
    const {checkUrlContains} = useUrl();
    const navigate = useNavigate();
    const {unreadMessages, unreadNotifications} = useUnreadData();

    const iconStyles = {
        workerOrders: checkUrlContains('worker-orders') ? 'menu-side__links__item__icon menu-side__links__item__icon--active' : 'menu-side__links__item__icon',
        workerNotifications: checkUrlContains('worker-notifications') ? 'menu-side__links__item__icon menu-side__links__item__icon--active' : 'menu-side__links__item__icon',
        workerMessages: checkUrlContains('worker-messages') ? 'menu-side__links__item__icon menu-side__links__item__icon--active' : 'menu-side__links__item__icon',
        workerSettings: checkUrlContains('worker-settings') ? 'menu-side__links__item__icon menu-side__links__item__icon--active' : 'menu-side__links__item__icon',
        workerReviews: checkUrlContains('worker-reviews') ? 'menu-side__links__item__icon menu-side__links__item__icon--active' : 'menu-side__links__item__icon',
        workerSpecialities: checkUrlContains('worker-specializations') ? 'menu-side__links__item__icon menu-side__links__item__icon--active' : 'menu-side__links__item__icon',
        workerAbout: checkUrlContains('worker-about') ? 'menu-side__links__item__icon menu-side__links__item__icon--active' : 'menu-side__links__item__icon',
        workerSupport: checkUrlContains('worker-support') ? 'menu-side__links__item__icon menu-side__links__item__icon--active' : 'menu-side__links__item__icon'
    };

    return (
        <MenuSide>
            <ul className="menu-side__links">
                <li 
                    className="menu-side__links__item"
                    onClick={() => navigate('/worker-orders')}>
                    <div className={iconStyles.workerOrders}>
                        <GiSuitcase />
                    </div>
                    <p className="menu-side__links__item__text">
                        Мои заказы
                    </p>
                </li>
                <li 
                    className="menu-side__links__item"
                    onClick={() => navigate('/worker-notifications')}>
                    <div className={iconStyles.workerNotifications}>
                        <AiOutlineBulb />
                    </div>
                    <p className="menu-side__links__item__text">
                        Уведомления
                    </p>
                    <UnreadBadge
                        type='counter' 
                        unreadNumber={unreadNotifications}/>
                </li>
                <li 
                    className="menu-side__links__item"
                    onClick={() => navigate('/worker-messages')}>
                    <div className={iconStyles.workerMessages}>
                        <AiOutlineMail />
                    </div>
                    <p className="menu-side__links__item__text">
                        Сообщения
                    </p>
                    <UnreadBadge
                        type='counter' 
                        unreadNumber={unreadMessages}/>
                </li>
                <li 
                    className="menu-side__links__item"
                    onClick={() => navigate('/worker-settings')}>
                    <div className={iconStyles.workerSettings}>
                        <IoSettingsOutline />
                    </div>
                    <p className="menu-side__links__item__text">
                        Настройки
                    </p>
                </li>
                <li 
                    className="menu-side__links__item"
                    onClick={() => navigate('/worker-reviews')}>
                    <div className={iconStyles.workerReviews}>
                        <AiOutlineStar />
                    </div>
                    <p className="menu-side__links__item__text">
                        Оценки и отзывы
                    </p>
                </li>
                <li 
                    className="menu-side__links__item"
                    onClick={() => navigate('/worker-specializations')}>
                    <div className={iconStyles.workerSpecialities}>
                        <SlGraduation />
                    </div>
                    <p className="menu-side__links__item__text">
                        Мои специальности
                    </p>
                </li>
                <li 
                    className="menu-side__links__item"
                    onClick={() => navigate('/worker-about')}>
                    <div className={iconStyles.workerAbout}>
                        <AiOutlineQuestionCircle />
                    </div>
                    <p className="menu-side__links__item__text">
                        О платформе
                    </p>
                </li>
                <li 
                    className="menu-side__links__item"
                    onClick={() => navigate('/worker-support')}>
                    <div className={iconStyles.workerSupport}>
                        <BiSupport />
                    </div>
                    <p className="menu-side__links__item__text">
                        Служба поддержки
                    </p>
                </li>
            </ul>
            <button 
                className="btn btn--primary btn--orange mt-30"
                onClick={() => navigate('/vacant-orders')}
            >
                    Поиск заказа
            </button>
        </MenuSide>
    );
};

export default MenuSideWorker;