import { useState } from "react";
import { useDispatch } from "react-redux";
import { openPopupNewOrder } from "../../store/slices/popupSlice";
import { IoIosContact } from 'react-icons/io';
import HeaderCustomer from "../../components/header/HeaderCustomer";
import ButtonBurger from '../../components/buttonBurger/ButtonBurger';
import UserInfo from "../../components/header/components/UserInfo";
import MenuSideCustomer from "../../components/menu/menuSide/MenuSideCustomer";
import OrderCardCustomer from "../../components/card/OrderCardCustomer";
import MenuQuickCustomer from "../../components/menu/menuQuick/MenuQuickCustomer";
import Filter from "../../components/filter/Filter";
import OrdersCounter from "../../components/ordersCounter/OrdersCounter";
import PopupNewReview from "../../components/popup/PopupNewReview";
import CardEmpty from "../../components/card/CardEmpty";
import Spinner from "../../components/spinner/Spinner";
import useOrders from "../../hooks/useOrders";
import useAuth from "../../hooks/useAuth";
import useAlert from "../../hooks/useAlert";
import usePopup from "../../hooks/usePopup";
import { createReview } from "../../api/api";

import '../../assets/styles/global.scss';
import './CustomerOrdersPage.scss';


// import customerPhoto from '../../assets/images/customer-img.jpg';

const CustomerOrdersPage = () => {
    const [selectedOrder, setSelectedOrder] = useState();
    const [filter, setFilter] = useState('active');
    const {orders, sortedOrders, filteredOrders, setUpdateOrdersList} = useOrders(filter);
    const {authToken} = useAuth();
    const {showAlert} = useAlert();
    const {isPopupActive, showPopup, hidePopup} = usePopup();
    const dispatch = useDispatch();
   
    const renderOrders = (orders, filter) => {

        if (!orders) {
            return <Spinner additionalClasses={'h-full w-full'}/>
        }
        if (orders.length < 1) {
            let title;
            let description;
            if (filter === 'all') {
                title = 'У Вас еще нет заказов';
                description = 'Создайте новый заказ и он появится здесь';
            }
            if (filter === 'active') {
                title = 'У Вас нет активных заказов';
                description = 'Создайте заказ и до выполнения он будет храниться здесь';
            }
            if (filter === 'done') {
                title = 'У Вас нет выполненных заказов';
                description = 'Здесь будут храниться Ваши выполненные заказы';
            }
            return <CardEmpty
                        title={title}
                        description={description}
                        buttonText={'Создать заказ'}
                        buttonClick={() => dispatch(openPopupNewOrder())}/>
        }

        const ordersRendered = orders.map((item, i) => {
            return (
                <OrderCardCustomer 
                    key={i} 
                    number={item.number} 
                    dateTime={item.dateTime} 
                    address={item.address} 
                    specialization={item.specialization} 
                    worker={item.worker} 
                    orderStatus={item.orderStatus}
                    setUpdateOrdersList={setUpdateOrdersList}
                    openPopup={openPopup}
                    setSelectedOrder={setSelectedOrder}
                    reviewByCustomer={item.reviewByCustomer}
                    authToken={authToken}/>
            );
        });
        return ordersRendered;
    };
    

    const openPopup = (orderNumber) => {
        setSelectedOrder(orderNumber);
        showPopup();
    };

    const closePopup = () => {
        setSelectedOrder(null);
        hidePopup();
    };

    const sendReview = async (rating, text) => {
        try {
            const reviewPosted = await createReview(selectedOrder, rating, text, authToken);
            closePopup();
            showAlert('Ваш отзыв получен. Спасибо!');
            setUpdateOrdersList(state => !state);
            return reviewPosted;
        }
        catch (error) {
            console.log(error);
            showAlert(error.message);
        };
    };


    return (
        <>
            <HeaderCustomer setUpdateOrdersList={setUpdateOrdersList}>
                <div className="header__content">
                    <ButtonBurger />
                    <h1 className="header__title">Мои заказы</h1>
                    {/* <div>
                        {userPhoto
                        ? <img 
                        className="header__img"
                        src={userPhoto} alt="userPhoto" />
                        : <IoIosContact className="header__img"/>
                        }
                        <DropDownMenu 
                            menuObj={{Профиль: '/customer-settings', Выход: 'link2'}}/>
                    </div> */}
                    <UserInfo/>
                </div>
            </HeaderCustomer>

            <div className="main__wrapper">
                <MenuSideCustomer />
                <main className="main__content">
                    <div className="container container--small">
                        <Filter
                            optionsList={[
                                {name: 'Активные', value: 'active'},
                                {name: 'Выполненные', value: 'done'},
                                {name: 'Все заказы', value: 'all'}
                            ]}
                            option={filter}
                            setOption={setFilter}/>

                        <OrdersCounter counters={[
                            {number: sortedOrders?.searchWorkerOrders?.length, text: 'Поиск исполнителя', handleClick: () => setFilter('searchWorker'), active: filter === 'searchWorker'},
                            {number: sortedOrders?.foundedWorkerOrders?.length, text: 'Исполнитель найден', handleClick: () => setFilter('foundedWorker'), active: filter === 'foundedWorker'},
                            {number: sortedOrders?.progressOrders?.length, text: 'Заказ исполняется', handleClick: () => setFilter('progress'), active: filter === 'progress'},
                            {number: sortedOrders?.doneOrders?.length, text: 'Всего выполнено', handleClick: () => setFilter('done'), active: filter === 'done'},
                            {number: orders?.length, text: 'Всего заказов', handleClick: () => setFilter('all'), active: filter === 'all'}
                        ]}/>

                        <section className="mt-24">
                            {renderOrders(filteredOrders, filter)}
                        </section>
                    </div>
                </main>
            </div>

            <MenuQuickCustomer/>
            <PopupNewReview
                isActive={isPopupActive}
                closePopup={closePopup}
                sendReview={sendReview}/>
        </>
    );
    
};

export default CustomerOrdersPage;
