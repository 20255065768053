import { useRef, useState, cloneElement, Children, isValidElement } from 'react';
import './Accordion.scss';

const AccordionHeader = ({children}) => {
    return children;
};

const AccordionContent = ({children}) => {
    return children;
};


const AccordionBlock = ({children}) => {
    const [isOpen, setIsOpen] = useState(false);
    //Реф нам нужен для того, чтобы получить текущее значение высоты элемента
    const contentRef = useRef();
    //Для реализации задуманной логики, нам нужно разделить полученные вложенные элементы (children) на конкретные компоненты и далее применить каждый из компонентов индивидуально. Т.к. прототип children - это Array, нам доступны все св-ва массивов.
    const accordionHeader = children.find(child => child.type === AccordionHeader);
    const accordionContent = children.find(child => child.type === AccordionContent);
    

    return (
        <div className={`accordion ${isOpen ? 'active' : ''}`}>
            <div className="accordion__header" onClick={() => setIsOpen(state => !state)}>
                {accordionHeader}
            </div>
            <div className={`accordion__content`} ref={contentRef} style={isOpen ? {height: contentRef.current.scrollHeight} : {height: ''}}>
                {accordionContent}
            </div>
        </div>
    );
};

export {AccordionBlock, AccordionHeader, AccordionContent};