import emptyDataImg from '../../assets/images/empty-data.png';

const CardEmpty = ({image=emptyDataImg, title='Заголовок', description='Описание', buttonText, buttonClick=()=>{}}) => {

    return (
        <div className="card">
            <div className="card__content">
                <div className="empty-state">
                    <img
                        className='h-100' 
                        src={image} 
                        alt="emptyImage" />
                    <div className='card__content__item__param'>{title}</div>
                    <p className='card__content__item__value'>{description}</p>
                    {buttonText &&
                    <button 
                        className='btn btn--primary btn--orange mt-20'
                        onClick={buttonClick}>
                        {buttonText}
                    </button>
                    }
                </div>
            </div>
        </div>
    );
};

export default CardEmpty;