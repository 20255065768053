const useDate = () => {
    const formatDate = (dateStr, format) => {
        const months = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];

        const date = new Date(dateStr);
        const day = date.getDate();
        const month = date.getMonth();
        const monthMod = `${(month + 1) < 10 ? '0' + (month + 1) : month + 1}`;
        const monthText = months[month];
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const minutesMod = `${minutes < 10 ? '0' + minutes : minutes}`;

        if (format === 'hh:mm, DD.MM.YYYY') {
            return `${hours}:${minutesMod}, ${day}.${monthMod}.${year}`
        };

        if (format === 'DD.MM.YYYY') {
            return `${day}.${monthMod}.${year}`
        };

        return `${day} ${monthText} ${year}, ${hours}:${minutes < 10 ? '0' + minutes : minutes}`;

    }

    const compareDate = ({date1, date2}, param='day') => {
        let date1Mod = null;
        let date2Mod = null;

        const normalizeDate = (date) => {
            if (!date?.toISOString) {
                return date;
            }
            return date.toISOString();
        };

        switch (param) {
            case 'day':
                date1Mod = normalizeDate(date1).slice(0,10);                
                date2Mod = normalizeDate(date2).slice(0,10);
                break;
            default:
                date1Mod = normalizeDate(date1).slice(0,10);                
                date2Mod = normalizeDate(date2).slice(0,10);
                break;
        }
        return (date1Mod === date2Mod);
    };

    

    return {
        formatDate,
        compareDate
    }
};

export default useDate;