import { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import {HiOutlineChat, HiChat, HiOutlineUser, HiUser, HiOutlinePlus, HiOutlineSearch, HiOutlineBriefcase, HiBriefcase} from 'react-icons/hi';
import {IoNotificationsOutline, IoNotifications} from 'react-icons/io5';
import UnreadBadge from '../../unreadBadge/UnreadBadge';
import useUrl from '../../../hooks/useUrl';
import useUnreadData from '../../../hooks/useUnreadData';

import './MenuQuick.scss';


const MenuQuick = ({role, ...props}) => {
    const {handleClickBtn1, handleClickBtn2, handleClickBtn3, handleClickBtn4, handleClickBtn5, isVisible=true} = props;
    const {checkUrlContains, checkUrlNotContains} = useUrl();
    const [currentPage, setCurrentPage] = useState({
        isOrders: false,
        isMessages: false,
        isNotifications: false,
        isSettings: false
    }); 
    const {unreadMessages, unreadNotifications} = useUnreadData();
    
    useEffect(() => {
        setCurrentPage({
            isOrders: checkUrlContains('orders') && checkUrlNotContains('vacant-orders'),
            isMessages: checkUrlContains('messages'),
            isNotifications: checkUrlContains('notifications'),
            isSettings: checkUrlContains('settings')
        });
    }, []);

    return (
        <div className={`quick-menu ${!isVisible ? 'quick-menu--unvisible' : ''}`}>
            <IconContext.Provider value={{className: 'quick-menu__link__icon'}}>
                <div className="quick-menu__left-side">
                    <div className="quick-menu__link" onClick={handleClickBtn1}>
                        {currentPage.isOrders ? <HiBriefcase className='quick-menu__link__icon--active'/> : <HiOutlineBriefcase/>}
                    </div>
                    <div className="quick-menu__link" onClick={handleClickBtn2}>
                        {currentPage.isMessages ? <HiChat className='quick-menu__link__icon--active'/> : <HiOutlineChat/>}
                        <UnreadBadge unreadNumber={unreadMessages}/>
                    </div>
                </div>
                <div className="quick-menu__center-side">
                    <div 
                        className="quick-menu__center-side__btn"
                        onClick={handleClickBtn3}
                        >
                        {role === 'customer' ? <HiOutlinePlus color='white'/>
                                            : role === 'worker' ? <HiOutlineSearch color='white'/> 
                                                                : ''}                      
                    </div>
                </div>
                <div className="quick-menu__right-side">
                    <div className="quick-menu__link" onClick={handleClickBtn4}>
                        { currentPage.isNotifications ? <IoNotifications className='quick-menu__link__icon--active'/> : <IoNotificationsOutline/>}
                        <UnreadBadge unreadNumber={unreadNotifications}/>
                    </div>
                    <div className="quick-menu__link" onClick={handleClickBtn5}>
                        { currentPage.isSettings ? <HiUser className='quick-menu__link__icon--active'/> : <HiOutlineUser/>}
                    </div>
                </div>
            </IconContext.Provider>
        </div>
    );
};

export default MenuQuick;