import { NavLink, useNavigate } from "react-router-dom";

import './MenuMain.scss';

const MenuMain = ({isOpen, toggleMenu}) => {
    const navigate = useNavigate();
    const menuActiveClass = isOpen ? 'menu-main--active' : '';

    return (
        <div className={`menu-main ${menuActiveClass}`}>
            <div 
                className="menu-main__btn-close"
                onClick={toggleMenu}>&times;</div>
            <nav className="menu-main__links">
                <NavLink className="menu-main__links-item">Специальности</NavLink>
                <NavLink className="menu-main__links-item">Условия пользования</NavLink>
                <NavLink to={'/manual'} className="menu-main__links-item">Инструкция</NavLink>
                <NavLink to={'/feedback'} className="menu-main__links-item">Написать нам</NavLink>
            </nav>
            <button 
            className="btn menu-main__btn "
            // className="btn btn--primary btn--orange menu-main__btn "
            onClick={() => navigate('/login')}>
                Личный кабинет</button>
        </div>
    );
};

export default MenuMain;