import './Solutions.scss';
import builder from '../../../../assets/images/solutions/builder.png';

const Solutions = () => {
    return (
        <div className="solutions">
            <div className="container">
                <div className="solutions__wrapper">
                    <div className='solutions__item'>
                        <div className="subtitle color--main">
                            Для заказчика
                        </div>
                        <p>Разместите заявку и ее увидят более 1000 мастеров</p>
                        <p>Фиксированная стоимость рабочей смены</p>
                        <p>Вы можете выбрать работника нужной вам специальности</p>
                        <p>Удобная система поиска и контроля выполнения заявок</p>
                    </div>
                    <div className='solutions__item'>
                        <div className="subtitle color--second">
                            Для работника
                        </div>
                        <p>Большое количество заказов</p>
                        <p>Ежедневная оплата работ</p>
                        <p>Удобная система сдачи-приемки</p>
                        <p>Только добросовестные зказчики</p>
                    </div>
                    <img className='solutions__builder' src={builder} alt="smart-builder" />
                </div>
            </div>
        </div>
    );
};

export default Solutions;