import ImageZoomer from '../../../../components/imageZoomer/ImageZoomer';
import './InstallationApp.scss';
import screen1 from '../../../../assets/images/manual/installation-ios/screen1_mod.jpg';
import screen2 from '../../../../assets/images/manual/installation-ios/screen2_mod.jpg';
import screen3 from '../../../../assets/images/manual/installation-ios/screen3_mod.jpg';

export default function InstallationIOS () {

    return (
        <div className="installation">
            <h1 className='title'>Установка приложения на Iphone, Ipad</h1>
            <div className="installation__text text">
                <p> Если Вы счастливый обладатель Iphone или Ipad, то установка нашего приложения на Ваше уствойство будет выполнена в 3 клика и займет буквально 1 минуту Вашего времени.</p>
                <p>Вам необходимо:</p>
            </div>
            <div className="installation__steps">
                <div className="installation__steps__item">
                    <div className="installation__steps__item__descr subtitle">1. Нажмите на иконку «Поделиться».</div>
                    <ImageZoomer>
                        <img className="installation__steps__item__img" src={screen1} alt="install-ios-step1" />
                    </ImageZoomer>
                </div>
                <div className="installation__steps__item">
                    <div className="installation__steps__item__descr subtitle">2. Нажмите на кнопку «На экран «Домой»».</div>
                    <ImageZoomer>
                        <img className="installation__steps__item__img" src={screen2} alt="install-ios-step2" />
                    </ImageZoomer>
                </div>
                <div className="installation__steps__item">
                    <div className="installation__steps__item__descr subtitle">3. Нажмите на кнопку «Добавить».</div>
                    <ImageZoomer>
                        <img className="installation__steps__item__img" src={screen3} alt="install-ios-step3" />
                    </ImageZoomer>
                </div>
            </div>
            <div className="installation__text text">
                Приложение установлено. Пользуйтесь с удовольствием!
            </div>
        </div>
    )
};