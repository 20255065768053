
import { IoMailOutline, IoCallOutline, IoTrashOutline, IoEnterOutline } from 'react-icons/io5';
import Star from '../star/Star';

import './Card.scss';


const DecorCircle = () => {
    return (
        <div className="decor__circle">
            <div className="decor__circle-inner"></div>
        </div>
    );
};

const DecorDots = () => {
    return (
        <div className="decor__dots">
            <div className="decor__dots__item"></div>
            <div className="decor__dots__item"></div>
            <div className="decor__dots__item"></div>
            <div className="decor__dots__item"></div>
            <div className="decor__dots__item"></div>
        </div>
    );
};

const DecorIndicator = ({orderStatus=null, notificationReadDate, requestedStatusChange=false}) => {
    let cssClasses = 'card__indicator';

    if (orderStatus) {
        switch (orderStatus) {
            case 'Поиск исполнителя':
                cssClasses += ' card__indicator--orange';
                break;
            case 'Назначен исполнитель':
                cssClasses += ' card__indicator--blue';
                break;
            case 'Исполнитель приехал':
                cssClasses += ' card__indicator--green';
                break;
            case 'Исполнитель работает':
                cssClasses += ' card__indicator--green';
                break;
            case 'Заказ выполнен':
                cssClasses += ' card__indicator--purple';
                break;
            case 'Заказ закрыт':
                cssClasses += ' card__indicator--red';
                break;
            default:
                cssClasses += '';
        }
    }

    if (notificationReadDate) {
        cssClasses += ' card__indicator--grey';
    }

    if (notificationReadDate === null) {
        cssClasses += ' card__indicator--orange';
    }

    if (requestedStatusChange) {
        cssClasses += ' card__indicator--yellow';
    }


    return (
        <div className={cssClasses}></div>
    )
};

const MessageLink = ({additionalClasses, iconAdditionalClasses, handleClick}) => {
    return (
        <div 
            className={`card__links__item ${additionalClasses}`}
            onClick={handleClick}>
            <IoMailOutline className={iconAdditionalClasses}/>
        </div>
    )
};

const PhoneLink = ({additionalClasses, iconAdditionalClasses, handleClick}) => {
    return (
    <div 
        className={`card__links__item ${additionalClasses}`}
        onClick={handleClick}>
        <IoCallOutline className={iconAdditionalClasses}/>
    </div>
    );
};

const DeleteLink = ({additionalClasses, handleClick}) => {

    return (
        <div 
            className={`card__links__item ${additionalClasses ? additionalClasses : ''}`}
            onClick={handleClick}>
            <IoTrashOutline/>
        </div>
    );
};

const EnterLink = ({handleClick}) => {
    return (
    <div className="card__links__item" onClick={handleClick}>
        <IoEnterOutline/>
    </div>
    );
};

const FooterInfo = ({orderStatus=null, notificationStatus=null, supportStatus=null, requestedStatusChange=false, handleClick, children}) => {
    let cssClasses = 'card__info';

    if (orderStatus) {
        switch (orderStatus) {
            case 'Поиск исполнителя':
                cssClasses += ' card__info--orange';
                break;
            case 'Назначен исполнитель':
                cssClasses += ' card__info--blue';
                break;
            case 'Исполнитель приехал':
                cssClasses += ' card__info--green';
                break;
            case 'Исполнитель работает':
                cssClasses += ' card__info--green';
                break;
            case 'Заказ выполнен':
                cssClasses += ' card__info--purple';
                break;
            case 'Заказ закрыт':
                cssClasses += ' card__info--red';
                break;
            default:
                cssClasses += '';
        }
    }

    if (requestedStatusChange) {
        cssClasses += ' card__info--yellow';
    }

    if (notificationStatus) {
        switch (notificationStatus) {
            case 'Подтверждение не требуется':
                cssClasses += ' card__info--blue';
                break;
            case 'Подтверждено':
                cssClasses += ' card__info--green';
                break;
            case 'Отклонено':
                cssClasses += ' card__info--red';
                break;
            default:
                cssClasses += '';
        }
    }

    if (supportStatus) {
        switch (supportStatus) {
            case 'Зарегистрировано':
                cssClasses += ' card__info--blue';
                break;
            case 'На рассмотрении':
                cssClasses += ' card__info--orange';
                break;
            case 'Рассмотрено':
                cssClasses += ' card__info--green';
                break;
            default:
                cssClasses += '';
        }
    }

    return (
        <div 
            className={cssClasses}
            onClick={handleClick ? handleClick : () => {}}>
            {children}
        </div>
    )
};

const FooterInfoBtn = ({orderStatus=null, notificationStatus=null}) => {
    let cssClasses = 'card__info__btn';

    if (orderStatus) {
        switch (orderStatus) {
            case 'Поиск исполнителя':
                cssClasses += ' card__info__btn--disabled';
                break;
            case 'Назначен исполнитель':
                cssClasses += ' card__info__btn--blue';
                break;
            case 'Исполнитель приехал':
                cssClasses += ' card__info__btn--green';
                break;
            case 'Исполнитель работает':
                cssClasses += ' card__info__btn--green';
                break;
            case 'Заказ выполнен':
                cssClasses += ' card__info__btn--disabled';
                break;
            case 'Заказ закрыт':
                cssClasses += ' card__info__btn--disabled';
                break;
            default:
                cssClasses += '';
        }
    }

    return (
        <div className={cssClasses}>
            &gt;
        </div>
    )

};

const RatingBadge = ({averageRating, doneOrders}) => {
    return (
        <div className="rating-badge">
            <Star/>
            <div className="rating-badge__rating-num">
                {averageRating}
            </div>
            <div className="rating-badge__orders-num">
                {`(${doneOrders})`}
            </div>
        </div>
    );
};

const RatingTrigger = ({handleClick}) => {
    return (
        <div 
            className="rating-trigger"
            onClick={handleClick}>
            Оставьте отзыв
        </div>
    );
};

export {
    DecorCircle,
    DecorDots,
    DecorIndicator,
    MessageLink,
    PhoneLink,
    DeleteLink,
    EnterLink,
    FooterInfo,
    FooterInfoBtn,
    RatingBadge,
    RatingTrigger
}