import SupportCard from "../card/SupportCard";
import Spinner from "../spinner/Spinner";
import CardEmpty from "../card/CardEmpty";

const SupportRequestsList = ({supportRequests}) => {
    if (!supportRequests) {
        return <Spinner additionalClasses={'h-full w-full'}/>
    };
    if (supportRequests.length < 1) {
        return <CardEmpty
                    title="Список обращений пуст"
                    description="Здесь будут храниться Ваши обращения в службу технической поддержки"/>
    };

    return (
        <section className="mt-24">
            {supportRequests.map((request, i) => (
                <SupportCard
                    key={i}
                    number={request.number}
                    timeCreate={request.timeCreate}
                    topic={request.topic}
                    text={request.text}
                    status={request.status}
                    filesList={request.files}/>
            ))}
        </section>
    );
};

export default SupportRequestsList;