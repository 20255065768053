import HeaderCustomer from "../../components/header/HeaderCustomer";
import ButtonBurger from "../../components/buttonBurger/ButtonBurger";
import MenuSideCustomer from "../../components/menu/menuSide/MenuSideCustomer";
import MenuQuickCustomer from "../../components/menu/menuQuick/MenuQuickCustomer";
import Chat from "../../components/chat/Chat";
import UserInfo from "../../components/header/components/UserInfo";
import useAuth from "../../hooks/useAuth";
import useChat from '../../hooks/useChat';

export default function CustomerMessagesPage() {
    const {authToken} = useAuth();
    const {chats, chatSelected, setChatSelected, order, setOrder, orderNumberSelected, setOrderNumberSelected, interlocutor, setInterlocutor, messages, setMessages, messagesListRef, onSendMessage, textareaRef } = useChat(authToken);  
    

    return (
        <>
            <HeaderCustomer>
                <div className="header__content">
                    <ButtonBurger />
                    <h1 className="header__title">Сообщения</h1>
                    <UserInfo/>
                </div>
            </HeaderCustomer>

            <div className="main__wrapper">
                <MenuSideCustomer />
                <main className="w-full">
                    <Chat 
                            chats={chats} 
                            chatSelected={chatSelected} 
                            setChatSelected={setChatSelected}
                            order={order}
                            setOrder={setOrder}
                            orderNumberSelected={orderNumberSelected}
                            setOrderNumberSelected={setOrderNumberSelected}
                            interlocutor={interlocutor}
                            setInterlocutor={setInterlocutor}
                            messages={messages}
                            setMessages={setMessages}
                            messagesListRef={messagesListRef}
                            onSendMessage={onSendMessage}
                            textareaRef={textareaRef}/> 
                </main>
                {/* <main className=" main__content w-full">
                    <div className="container container--small">
                        <Chat 
                            chats={chats} 
                            chatSelected={chatSelected} 
                            setChatSelected={setChatSelected}
                            messages={messages}
                            setMessages={setMessages}
                            messagesListRef={messagesListRef}
                            onSendMessage={onSendMessage}/> 
                    </div>     
                </main>  */}
            </div>

            <MenuQuickCustomer isVisible={!chatSelected}/>
        </>
    );
};