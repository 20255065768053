import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserPhoto } from "../store/slices/userSlice";
import useAuth from "../hooks/useAuth";
import useAlert from "../hooks/useAlert";
import Spinner from "../components/spinner/Spinner";
import { fetchUserRoleName } from "../api/api";



const WithAuth = ({mode = 'auth', requiredRole=null }) => {
    const [localTokenChecked, setLocalTokenChecked] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const [currentUrl, setCurrentUrl] = useState(null); //Записываем url страницы, чтобы при переходе на новую страницу, отслеживать переход, запускать загрузку данных пользователя и не допускать рендер Outlet до сброса/загрузки данных пользователя
    const location = useLocation(); //Отслеживаем страницу, чтобы запустить новую загрузку данных пользователя при переходе между страницами 

    const { isAuth, authToken, userPhoto, checkLocalToken, clearUser } = useAuth();
    const {showAlert} = useAlert();
    const dispatch = useDispatch();


    useEffect(() => {
        setLocalTokenChecked(checkLocalToken());
    },[]);

    useEffect(() => {
        setUserProfile(null); //сбросим пользователя чтобы не показывать содержимое страницы до загрузки пользователя
        setCurrentUrl(location.pathname);

        if (isAuth) {
            fetchUserRoleName(authToken)
                .then(userProfile => {
                    setUserProfile(userProfile);
                    if ((!userPhoto && userProfile?.photo) || ((userPhoto && userProfile?.photo) && (userPhoto !==userProfile?.photo))) {
                        dispatch(setUserPhoto({photo: userProfile.photo}));
                    }
                })
                .catch(error => {
                    console.log(error);
                    showAlert(error.message);
                    clearUser();
                });
        } else {
            setUserProfile(null);
        }
    },[authToken, location]);


    if (!localTokenChecked) {
        // return <h1>Проверка локального токена....</h1>
        return <Spinner/>
    };

    if (currentUrl !== location.pathname) {
        return <Spinner/>
    };

    if (mode === 'auth') {

        if (!isAuth) {
            return <Navigate to='/login'/>
        };

        if (!userProfile) {
            // return <h1>Загружаем данные пользователя....</h1>
            return <Spinner/>
        };


        if (userProfile?.role && requiredRole && userProfile?.role !== requiredRole) {
            return <Navigate to='/'/>
        };

        if (userProfile?.role && !requiredRole) {
            return (
                <Outlet/>
            ); 
        };

        return (
            <Outlet context={[userProfile.role]}/>
        );
    };

    if (mode === 'notAuth') {

        if (!isAuth) {
            return (
                <Outlet/>
            );
        };
        
        if (!userProfile) {
            // return <h1>Загружаем данные пользователя....</h1>
            return <Spinner/>
            
        };
        
        if (userProfile?.role) {

            if (userProfile.role === 'customer') {
                return <Navigate to='/customer-orders'/>
            };
    
            if (userProfile.role === 'worker') {
                return <Navigate to='/worker-orders'/>
            };
        };

    };
};

export default WithAuth;