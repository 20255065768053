//данный слайс можно в будущем удалить, решил не использовать глобальный стейт для хранения состояния открытия/закрытия меню. На каждой странице - стейт будет локальный.

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isMenuMainOpen: false,
    isMenuSideOpen: false
};

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        openMainMenu: (state) => {
            state.isMenuMainOpen = true;
        },
        closeMainMenu: (state) => {
            state.isMenuMainOpen = false;
        },
        openSideMenu: (state) => {
            state.isMenuSideOpen = true;
        },
        closeSideMenu: (state) => {
            state.isMenuSideOpen = false;
        }
    }
});

const {actions, reducer} = menuSlice;

export const {
    openMainMenu, 
    closeMainMenu, 
    openSideMenu, 
    closeSideMenu
} = actions;
export default reducer;