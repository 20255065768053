import { useState } from 'react';
import UserInfo from '../../components/header/components/UserInfo';
import HeaderWorker from "../../components/header/HeaderWorker";
import ButtonBurger from "../../components/buttonBurger/ButtonBurger";
import MenuSideWorker from '../../components/menu/menuSide/MenuSideWorker';
import SpecializationCard from '../../components/card/SpecializationCard';
import PopupNewSpecialization from '../../components/popup/PopupNewSpecialization';
import Spinner from '../../components/spinner/Spinner';
import CardEmpty from '../../components/card/CardEmpty';
import useSpecializations from '../../hooks/useSpecializations';


const WorkerSpecializationsPage = () => {
    const [isPopupActive, setIsPopupActive] = useState(false);
    const {categories, specializations, userSpecializations, createSpecialization, updateSpecializations} = useSpecializations();

    const renderSpecializations = (userSpecializations) => {
        if (!userSpecializations) {
            return <Spinner additionalClasses={'h-full w-full'}/>
        };
        if (userSpecializations.length < 1) {
            return <CardEmpty
                        title="Список Ваших специальностей пуст"
                        description="Добавьте специальность или несколько специальностей, и Вы сможете принимать заказы по данным направлениям."/>
        }

        return userSpecializations.map((specialization, i) => {
            return <SpecializationCard
                        key={i}
                        specialization={specialization.specialization}
                        qualification={specialization.qualification}
                        doneOrders={specialization.doneOrders}
                        averageRating={specialization.averageRating}/>
        });
    };

    const togglePopup = () => {
        setIsPopupActive(state => !state);
    };

    return (
        <>
            <HeaderWorker>
                <div className="header__content">
                    <ButtonBurger />
                    <h1 className="header__title">Мои специальности</h1>
                    <UserInfo/>
                </div>
            </HeaderWorker>

            <div className="main__wrapper">
                <MenuSideWorker />
                <main className="main__content">
                    <div className="container container--small">
                        <button 
                            className='btn btn--primary btn--orange m-center'
                            onClick={togglePopup}>
                            Добавить специальность
                        </button>
                        <section className="mt-24">
                            {renderSpecializations(userSpecializations)}
                        </section>
                    </div>
                </main>
            </div>
            <PopupNewSpecialization
                isActive={isPopupActive}
                categories={categories}
                specializations={specializations}
                togglePopup={togglePopup}
                createSpecialization={createSpecialization}
                updateSpecializations={updateSpecializations}/>
        </>
    )
};

export default WorkerSpecializationsPage;