

const Contract = () => {
    return (
        <div className="contract">
            <p className="fw-600">ДОГОВОР-ОФЕРТА (ПРИМЕР)</p>
            <p className="mt-10 fw-600">1. Общие положения</p>
            <p>1.1. Настоящий Договор является официальным предложением (публичной офертой) Фамилия,
имя, отчество/Название организации/Название магазина (в дальнейшем «Исполнитель») для
полностью дееспособного физического (далее – «Заказчик»), которое примет настоящее
предложение, на указанных ниже условиях.</p>
            <p>1.2. В соответствии с пунктом ...
в случае принятия изложенных ниже условий и оплаты услуг юридическое или физическое лицо,
производящее акцепт этой оферты, становится Заказчиком.</p>
            <p>1.3. Моментом полного и безоговорочного принятия Заказчиком предложения Исполнителя
заключить Договор оферты (акцептом оферты) считается факт подтверждения готовности
совершить оплату услуги Исполнителя, посредством нажатия кнопки «Оплатить» на сайте
http://www._________.ru/. Текст настоящего Договора-оферты (далее по тексту – «Договор»)
расположен по адресу: http://www._________.ru/_________.</p>
            <p>1.4. Осуществляя акцепт Договора в порядке, определенном п. 1.3 Договора, Заказчик
подтверждает, что он ознакомлен, согласен, полностью и безоговорочно принимает все условия
Договора в том виде, в каком они изложены в тексте Договора, в том числе в приложениях к
Договору, являющихся его неотъемлемой частью.</p>
            <p>1.5. Клиент согласен, что акцепт Договора в порядке, указанном в п. 1.2 Договора является
заключением Договора на условиях, изложенных в нем.</p>
            <p>1.6. Договор не может быть отозван.</p>
            <p>1.7. Договор не требует скрепления печатями и/или подписания Заказчиком и Исполнителем
(далее по тексту - Стороны) и сохраняет при этом юридическую силу.</p>

            <p className="mt-10 fw-600">2. Предмет договора</p>
            <p>2.1. Предметом настоящего Договора является возмездное оказание Исполнителем
образовательных услуг в соответствии с условиями настоящего Договора.</p>
            <p>2.2. Заказчик полностью принимает условия Договора и оплачивает услуги Исполнителя в
соответствии с условиями настоящего Договора.</p>

            <p className="mt-10 fw-600">3. Оплата Услуг</p>
            <p>3.1. Стоимость услуг по Договору определяется в соответствии с действующими ценами и
прописана на сайте http://www.____________.ru/</p>
            <p>3.2. Стоимость услуги может быть изменена Исполнителем в одностороннем порядке.</p>
            <p>3.3. Способы оплаты услуги указаны при оформлении платежа.</p>

            <p className="mt-10 fw-600">4. Интеллектуальная собственность</p>
            <p>4.1. Вся текстовая информация и графические изображения, находящиеся на сайте
http://www.____________.ru/ являются собственностью Исполнителя.</p>
            <p className="mt-10 fw-600">5. Особые условия и ответственность сторон.</p>
            <p>5.1. Исполнитель несет ответственность за своевременность предоставляемых услуг при
выполнении Заказчиком установленных требований и правил, размещенных на сайте
http://www.____________.ru/.</p>
            <p>5.2. Исполнитель освобождается от ответственности за нарушение условий Договора, если такое
нарушение вызвано действием обстоятельств непреодолимой силы (форс-мажор), включая:
действия органов государственной власти, пожар, наводнение, землетрясение, другие стихийные
действия, отсутствие электроэнергии, забастовки, гражданские волнения, беспорядки, любые иные
обстоятельства, не ограничиваясь перечисленным, которые могут повлиять на выполнение
Исполнителем Договора.</p>
            <p>5.3. Исполнитель не несет ответственности за качество каналов связи общего пользования или
служб, предоставляющих доступ Заказчика к его услугам.</p>
            <p className="mt-10 fw-600">6. Конфиденциальность и защита персональной информации</p>
            <p>6.1. Исполнитель обязуется не разглашать полученную от Заказчика информацию.</p>
            <p>6.2. Не считается нарушением обязательств разглашение информации в соответствии с
обоснованными и применимыми требованиями закона.</p>
            <p>6.3. Исполнитель получает информацию об IP-адресе посетителя Сайта
http://www.____________.ru/. Данная информация не используется для установления личности
посетителя.</p>
            <p>6.4. Исполнитель не несет ответственности за сведения, предоставленные Заказчиком на сайте
http://www.____________.ru/ в общедоступной форме.</p>
            <p className="mt-10 fw-600">7. Порядок рассмотрения претензий и споров</p>
            <p>7.1. Претензии Заказчика по предоставляемым услугам принимаются Исполнителем к
рассмотрению по электронной почте в течение 2 (рабочих) дней с момента возникновения спорной
ситуации.</p>
            <p>7.2. При рассмотрении спорных ситуаций Исполнитель вправе запросить у Заказчика всю
интересующую документацию относительно рассматриваемого мероприятия. В случае
непредоставления Заказчиком документов в течение 1 рабочего дня после дня требования,
претензия рассмотрению Исполнителем не подлежит.</p>
            <p>7.3. Исполнитель и Заказчик, принимая во внимания характер оказываемой услуги, обязуются в
случае возникновения споров и разногласий, связанных с оказанием услуг, применять досудебный
порядок урегулирования спора. В случае невозможности урегулирования спора в досудебном
порядке стороны вправе обратиться в суд.</p>
            <p className="mt-10 fw-600">8. Прочие условия</p>
            <p>8.1. Заказчик обладает всеми правами и полномочиями, необходимыми для заключения и
исполнения Договора.</p>
            <p>8.2. Заказчик вправе в любое время в одностороннем порядке отказаться от услуг Исполнителя. В
случае одностороннего отказа Заказчика от услуг Исполнителя произведенная оплата не
возвращается, но может быть перенесена на другую услугу.</p>
            <p>8.3. Исполнитель оставляет за собой право изменять или дополнять любые из условий настоящего
Договора в любое время, опубликовывая все изменения на своем сайте.</p>
            <p>8.4. По всем вопросам, не урегулированным настоящим Договором, стороны руководствуются
действующим законодательством страны.</p>
            <p>8.5. Признание судом недействительности какого-либо положения настоящего Договора и правил
не влечет за собой недействительность остальных положений.</p>
            <p className="mt-10 fw-600">9. Реквизиты Исполнителя</p>
            <p className="mt-10 fw-600">10. Контактные данные</p>
        </div>
    )
};

export default Contract;