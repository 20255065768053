import { useState} from "react";
import { Link } from "react-router-dom";
import StepIndicator from "./components/StepIndicator";
import RegisterStep1 from "./components/RegisterStep1";
import RegisterStep2 from "./components/RegisterStep2";
import Alert from "../../components/alert/Alert";
import './RegisterPage.scss';
import logo from '../../../src/assets/images/logo/logo_color.svg';

const RegisterPage = () => {
    const [registerStep, setRegisterStep] = useState('step1');
    const [userData, setUserData] = useState({});

    return (
        <>
            <div className="register-page">
                <div className="container">
                    <div className="register-page__logo">
                        <Link to='/'>
                            {/* <p className="register-page__title">Real Worker</p> */}
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>
                    
                    <div className="register-page__subtitle">
                        Платформа для эффективной работы
                    </div>
                    <div className="form">
                        <div className="form__title">РЕГИСТРАЦИЯ</div>
                        <StepIndicator registerStep={registerStep}/>
                        {registerStep === 'step1' ? <RegisterStep1
                                                        setUserData={setUserData} 
                                                        setRegisterStep={setRegisterStep}/> 
                                                : <RegisterStep2
                                                        userData={userData} 
                                                        setRegisterStep={setRegisterStep}/>}
                    </div>
                </div>   
            </div>
            <Alert/>
        </>
    );
};

export default RegisterPage;