// import { useEffect, useState } from "react";
// import {useForm} from 'react-hook-form';
import HeaderCustomer from "../../components/header/HeaderCustomer";
import ButtonBurger from "../../components/buttonBurger/ButtonBurger";
import MenuSideCustomer from "../../components/menu/menuSide/MenuSideCustomer";
import Filter from "../../components/filter/Filter";
import MenuQuickCustomer from "../../components/menu/menuQuick/MenuQuickCustomer";
import CustomerSettingsProfile from "./components/CustomerSettingsProfile";
import CustomerSettingsAccount from "./components/CustomerSettingsAccount";
import UserInfo from "../../components/header/components/UserInfo";
// import useAuth from "../../hooks/useAuth";
// import useAlert from "../../hooks/useAlert";
import useSettings from "../../hooks/useSettings";
// import { fetchUserProfile, updateUserData } from "../../api/api";

import './CustomerSettingsPage.scss';



const CustomerSettingsPage = () => {

    const {user, mode, setMode, saveData, clearInputs, settingsChanged, setSettingsChanged, useFormMethodsAccount, useFormMethodsProfile} = useSettings();
    
    return (
        <>
            <HeaderCustomer>
                <div className="header__content">
                    <ButtonBurger />
                    <h1 className="header__title">Настройки</h1>
                    {settingsChanged 
                        ? <div className="header-group">
                                <button 
                                    className="header__btn header__btn--transparent"
                                    onClick={clearInputs}
                                >Отменить</button>
                                <button 
                                    className="header__btn header__btn--transparent color-orange5"
                                    onClick={mode === 'profile' ? useFormMethodsProfile.handleSubmit(saveData) : useFormMethodsAccount.handleSubmit(saveData)}
                                >Сохранить</button>
                            </div>
                        : <UserInfo />}
                </div>
            </HeaderCustomer>

            <div className="main__wrapper">
                <MenuSideCustomer />
                <main className="main__content">
                    <div className="container container--small">
                        <Filter
                            optionsList={[
                                {name: 'Профиль', value: 'profile'},
                                {name: 'Аккаунт', value: 'account'}
                            ]}
                            option={mode}
                            setOption={setMode}/>

                        <div className="customer-settings">
                            {mode === 'profile' 
                                ? user && <CustomerSettingsProfile 
                                                user={user}
                                                setSettingsChanged={setSettingsChanged} 
                                                useFormMethodsProfile={useFormMethodsProfile}/> 
                                : mode === 'account' 
                                    ? user && <CustomerSettingsAccount 
                                                    user={user}
                                                    setSettingsChanged={setSettingsChanged} 
                                                    useFormMethodsAccount={useFormMethodsAccount}/> 
                                    : null}
                        </div>
                        

                        
                    </div>
                </main>
            </div>

            <MenuQuickCustomer/>
        </>
    )
};

export default CustomerSettingsPage;