import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAlert from "./useAlert";
import useAuth from "./useAuth";
import useUserAction from "./useUserAction";
import { fetchOrder, updateOrderData } from "../api/api";

const useOrder = () => {
    const [order, setOrder] = useState();
    const [refreshOrder, setRefreshOrder] = useState(false);
    const {orderNumber} = useParams();
    const navigate = useNavigate();
    const {showAlert, showAlertRequire} = useAlert();
    const {authToken} = useAuth();
    const {customerCanceledOrder} = useUserAction();
    

    const updateOrder = async (newOrderData) => {
        try {
            const orderUpdated = await updateOrderData(orderNumber, newOrderData, authToken);
            await showAlertRequire('Изменения сохранены');
            return orderUpdated;
        } catch (error) {
            console.log(error);
            showAlert(error.message);
        };
    };

    const deleteOrder = async () => {
        showAlertRequire('Желаете отменить заказ?', 'ok-cancel')
            .then(() => {
                customerCanceledOrder(orderNumber, authToken)
                    .then(() => {
                        setRefreshOrder(state => !state);
                        navigate('/customer-orders');
                    })
                    .catch(error => {
                        console.log(error);
                        showAlert(error.message);
                    });
            })
            .catch(error => {

            });
    };

    const refreshData = () => {
        setRefreshOrder(state => !state);
    };

    useEffect(() => {
        fetchOrder(orderNumber, authToken)
            .then(order => setOrder(order))
            .catch(error => {
                console.log(error);
                showAlert(error.message);
            });
    }, [refreshOrder]);

    return {
        orderNumber,
        order,
        updateOrder,
        deleteOrder,
        refreshData,
        authToken
    };
};

export default useOrder;