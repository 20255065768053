import { useState } from "react";
import {useForm} from 'react-hook-form';
import { Link, useNavigate } from "react-router-dom";
import InputPhoneWithConfirm from "../../../components/inputs/InputPhoneWithConfirm";
import InputExtLabel from "../../../components/inputs/InputExtLabel";
import { ErrorInput } from "../../../components/error/Error";


const Step1 = ({register, errors, setValue, setError, clearErrors, handleSubmit, phoneInputValue, validatePhone, navigate, setMode}) => {

    const onSubmitStep1 = (data) => {
        console.log(data);
        if (!data.isPhoneApproved) {
            setError('isPhoneApproved', {
                type: 'required',
                message: 'Подтвердите телефон'
            });
            return;
        };

        setMode('step2');
    };

    return (
        <>
            <InputPhoneWithConfirm
                additionalWrapperClasses='mt-20'
                additionalInputClasses={ `w-140`}
                errorPhone={errors?.phone}
                errorPhoneConfirmation={errors?.isPhoneApproved}
                phoneInputValue={phoneInputValue}
                setValue={setValue}
                clearErrors={clearErrors}
                confirmOptions={{callConfirm: true, telegramConfirm: false}}
                {...register('phone',{
                    validate: validatePhone
                })}
            />
            <div className="buttons-group mt-40">
                <button
                    type="button" 
                    className="btn btn--primary btn--outline m-center p-5 h-40 w-100 ls-2"
                    onClick={() => navigate('/login')}>Отменить</button>
                <button 
                    className="btn btn--primary btn--orange m-center p-5 h-40 w-210 ls-2"
                    onClick={handleSubmit(onSubmitStep1)} >Продолжить</button>
            </div>
        </>
    );
};

const Step2 = ({register, watch, setValue, clearErrors, navigate, handleSubmit, errors}) => {
    const password = watch('password');

    const onSubmitStep2 = (data) => {
        console.log(data);        
    };

    const validatePasswordCopy = (passwordCopy) => {
        if (passwordCopy) {
            return (passwordCopy === password) || 'Повтор пароля не корректный'
        };
    };

    const handlePasswordCopyChange = (e) => {   
        if (errors.passwordCopy) {
            clearErrors('passwordCopy');
        };
        setValue('passwordCopy', e.target.value);
    };

    return (
        <>
            <InputExtLabel
                labelText='Пароль'
                additionalWrapperClasses='mt-20'
                additionalInputClasses='input--grey'
                {...register('password', {required: 'Задайте пароль'})}
                type='password'/>
            {errors?.password && <ErrorInput message={errors.password?.message || 'Ошибка'}/>}
            <InputExtLabel
                labelText='Повторите пароль'
                additionalWrapperClasses='mt-20'
                additionalInputClasses='input--grey'
                {...register('passwordCopy', {required: 'Повторите пароль', validate: validatePasswordCopy})}
                type='password'
                onChange={handlePasswordCopyChange}
                />
            {errors?.passwordCopy && <ErrorInput message={errors.passwordCopy?.message || 'Ошибка'}/>}
            <div className="buttons-group mt-40">
                <button
                    type="button" 
                    className="btn btn--primary btn--outline m-center p-5 h-40 w-100 ls-2"
                    onClick={() => navigate('/login')}>Отменить</button>
                <button 
                    className="btn btn--primary btn--orange m-center p-5 h-40 w-210 ls-2"
                    onClick={handleSubmit(onSubmitStep2)} >Продолжить</button>
            </div>
        </>
    );
};


const RecoveryModePhone = () => {
    const [mode, setMode] = useState('step1');
    const {register, handleSubmit, formState:{errors, isValid}, reset, watch, setValue, setError, clearErrors} = useForm({mode: "onBlur"});
    const navigate = useNavigate();
    const phoneInputValue = watch('phone');

    const validatePhone = (value) => {
        const number = value.replace(/\D/g,'');
        
        return (number.length === 11) || 'Некорректный телефон';
    };

    return (
        <form >
            {mode === 'step1' && <Step1
                                    register={register}
                                    errors={errors} 
                                    handleSubmit={handleSubmit} 
                                    setValue={setValue}
                                    setError={setError}
                                    clearErrors={clearErrors}
                                    phoneInputValue={phoneInputValue} 
                                    validatePhone={validatePhone}
                                    navigate={navigate}
                                    setMode={setMode}/>}
            {mode === 'step2' && <Step2
                                    register={register}
                                    watch={watch}
                                    setValue={setValue}
                                    clearErrors={clearErrors}
                                    navigate={navigate}
                                    handleSubmit={handleSubmit}
                                    errors={errors} />}
        </form>
    )
};

export default RecoveryModePhone;