import { useNavigate } from 'react-router-dom';
import './Promo.scss';
import builder from '../../../../assets/images/promo/builder.png';
import iphone from '../../../../assets/images/promo/iphone-11-pro.png';

const Promo = ({navigate}) => {

    return (
        <div className="promo">
            <div className="container">
                <div className="promo__wrapper">
                    <div className="promo__title">
                        <span>RealWorker</span> - удобный поиск рабочих строительных специальностей
                    </div>
                    <div className="promo__descr">
                        Если вы ищете рабочих строительных специальностей для выполнения разовых или периодических задач - наш сервис станет для вас идеальным инструментом
                    </div>
                    <div className='mt-20 promo__btns-wrapper'>
                        <button 
                            // className='btn btn--primary btn--orange-dark'
                            className='btn'
                            onClick={() => navigate('/register')}>Регистрация</button>
                        <button 
                            // className='btn btn--primary btn--grey'
                            className='btn btn--grey'
                            onClick={() => navigate('/login')}>Войти</button>
                    </div>
                    <img className='promo__builder' src={builder} alt="builder" />
                    <img className='promo__iphone' src={iphone} alt="iphone-11-pro" />
                </div>
            </div>
        </div>
    );
};

export default Promo;