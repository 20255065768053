import ImageZoomer from "../../../../components/imageZoomer/ImageZoomer";
import newOrderTriggerPic1 from '../../../../assets/images/manual/order-creation/new-order-pic1_mod.jpg';
import newOrderTriggerPic2 from '../../../../assets/images/manual/order-creation/new-order-pic2.png';
import newOrderFormPic1 from '../../../../assets/images/manual/order-creation/new-order-pic3_mod.jpg';


const OrderCreation = () => {
    return (
        <>
            <h1>Создание заказа</h1>
            <div className="mt-30">
               Чтобы создать новый заказ, нажмите на иконку "+", расположенную на панели быстрого доступа или нажмите на кнопку в меню пользователя.
            </div>
            <div className="images-wrapper">
                <ImageZoomer>
                    <img src={newOrderTriggerPic1} alt="newOrderTriggerPic1" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={newOrderTriggerPic2} alt="newOrderTriggerPic1" />
                </ImageZoomer>
            </div>
            
            <div className="images-wrapper mt-30">
                <div >
                    Заполните все поля формы заказа:
                    <ul className="mt-10">
                        <li>- категория,</li>
                        <li>- специализация,</li>
                        <li>- адрес,</li>
                        <li>- дата и время,</li>
                        <li>- кол-во специалистов,</li>
                        <li>- описание задачи,</li>
                        <li>- загрузите фото (при необходимости)</li>
                    </ul>
                    <p className="mt-10">И нажмите на кнопку "Разместить заказ"</p>            
                </div>
                <ImageZoomer>
                    <img src={newOrderFormPic1} alt="newOrderFormPic1" />
                </ImageZoomer>
            </div>
            <div className="mt-30">Поздравляем! Ваш заказ создан и доступен для Исполнителей.</div>
        </>
    )
};

export default OrderCreation;