import { useEffect, useState } from 'react';
import {useForm} from 'react-hook-form';
import Popup from './Popup';
import StarRating from '../starRating/StarRating';
import {ErrorInput} from '../error/Error';

const PopupNewReview = ({isActive, closePopup, sendReview}) => {
    const [rating, setRating] = useState(0);
    const [ratingError, setRatingError] = useState(false);

    const {register, handleSubmit, setValue} = useForm();

    const handleClose = () => {
        closePopup();
        setRating(0);
    };

    const onSubmit = async (data) => {
        if (rating > 0) {
            const reviewPosted = await sendReview(data.rating, data.text);
            setRating(0);
        } else {
            setRatingError(true);
        };
    };

    useEffect(() => {
        setValue('rating', rating);
    }, [rating]);

    return (
        <Popup isActive={isActive}>
            <div className="popup__close" onClick={handleClose}>&times;</div>
            <div className="popup__header">
                <div className="popup__title">
                    Добавить отзыв
                </div>
            </div>
            <div className="popup__content">
                <form >
                    <div className='d-flex gap-x-10 mt-30'>
                        <span>Оценка: </span>
                        <StarRating
                            rating={rating}
                            setRating={setRating}/>
                    </div>
                    {ratingError && <ErrorInput message={'Оцените работу исполнителя'}/>}
                    <textarea 
                        type="text" 
                        className="input h-auto" 
                        placeholder='Оставьте отзыв' 
                        rows={8}
                        {...register('text')}/>
                </form>
            </div>
            <div className="popup__footer">
                <button 
                    className='btn btn--primary btn--orange m-center mt-30'
                    onClick={handleSubmit(onSubmit)}>
                    Отправить
                </button>
            </div>
        </Popup>
    );
};

export default PopupNewReview;