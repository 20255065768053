import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { DecorCircle, DecorDots, EnterLink } from './CardComponents';

import useDate from '../../hooks/useDate';

import './Card.scss';

const OrderCardVacantMini = ({...props}) => {
    const {number, dateTime, address, specialization, qualification, price, customer} = props;
    const {formatDate} = useDate();

    return (
        <div className="card">
            <div className="card__header pt-10">
                <div className="card__order-number">
                    Заказ №{number}
                </div>
                <div className="card__order-time">
                    {formatDate(dateTime)}
                </div>
            </div>
            <div className="card__content">
                <div className="card__content__item">
                    <div className="card__content__item__param">
                        Адрес
                    </div>
                    <div className="card__content__item__value">
                        {address}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                        <DecorDots/>
                    </div>
                </div>
                <div className="card__content__item">
                    <div className="card__content__item__param">
                        Специализация
                    </div>
                    <div className="card__content__item__value">
                        {specialization}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                        {/* <DecorDots/> */}
                    </div>
                </div>
                {/* <div className="card__content__item">
                    <div className="card__content__item__param">
                        Квалификация
                    </div>
                    <div className="card__content__item__value">
                        {qualification}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                        <DecorDots/>
                    </div>
                </div>
                <div className="card__content__item">
                    <div className="card__content__item__param">
                        Заказчик
                    </div>
                    <div className="card__content__item__value">
                        {customer}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                    </div>
                </div> */}
            </div>
            <div className="card__footer">
                <div className="card__info card__info--grey">
                    {`${price} руб.`}
                </div>
                <div className="card__links">
                    <Link to={`/worker-orders/${number}`}>
                        <IconContext.Provider value={{className: 'card__links__icon'}}>
                            <EnterLink/>
                        </IconContext.Provider>
                    </Link>
                </div>
            </div>
        </div>
    );
}; 

export default OrderCardVacantMini;