import { getRandomCoordsMoscow } from "../utils/getRandomCoords";
import { getData, postData, patchData } from "./httpRequest";
import convertFileToBase64 from "../utils/convertFileToBase64";
import {
    getUserRoleName,
    getUserProfile,
    getUserOrders,
    getUserSpecializations,
    getUserReviews,
    getUserOwnReviews,
    getUserSupportRequests,
    getVacantOrders,
    getOrder,
    getUserNotifications,
    getChatMessages,
    getUserChats,
    getUnreadData,
    getSupportTopics,
    getPhoneValidation,

    getCities,
    getCategories,
    getSpecializations,
    getQualifications,

    postAuthToken,
    postUser,
    postUserSpecialization,
    postOrder,
    postNotification,
    postChat,
    postMessage,
    postReview,
    postSupportRequest,
    postPhoneVerification,
    
    patchOrderWorker,
    patchOrderWorkerStatus,
    patchOrderStatus,
    patchOrderData,
    patchUserData,
    patchNotificationStatus,

    deleteToken
} from "./localServer";


const isLocalServerMode = false;


//-----------------------Основные запросы к серверу - ПОЛУЧЕНИЕ ДАННЫХ-----------------------------------


const fetchUserRoleName = async (authToken) => {
    try {
        const data = isLocalServerMode
        ? await getUserRoleName(authToken)
        : await getData('https://realworker.ru/api/users/user-name-role/', null, authToken, {
            name: 'Name',
            role: 'Role'
        });
        
        if (data?.role !== 'customer' && data?.role !== 'worker') {
            const error = new Error('Неопознанная роль пользователя');
            error.status = 3;
            error.statusText = 'Неопознанная роль пользователя';

            throw error;
        }
        
        return data;

    } catch (error) {
        throw error;
    };

};


const fetchUserProfile = async (authToken) => {
    try {
        const user = isLocalServerMode
        ? await getUserProfile(authToken)
        : await getData('https://realworker.ru/api/users/details/', null, authToken, {
            name: 'Name',
            // middleName: 'MiddleName',
            // surname: 'Surname',
            city: 'City',
            // company: 'Company',
            // position: 'Position',
            // email: 'Email',
            phone: 'Phone'
        });
        
        return user;
    } catch (error) {
        throw error;
    };

};

const fetchUserOrders = async (authToken) => {
    try {
        const orders = isLocalServerMode
        ? await getUserOrders(authToken)
        : await getData('https://realworker.ru/api/orders/my/', null, authToken);
        
        return orders;

    } catch (error) {
        throw error;
    };
};

const fetchUserSpecializations = async (authToken) => {
    try {
        const userSpecializations = isLocalServerMode
        ? await getUserSpecializations(authToken)
        : await getData('https://realworker.ru/api/users/my-specializations/show/', null, authToken);
        
        return userSpecializations;
    } catch (error) {
        throw error;
    };
};

const fetchUserReviews = async (authToken) => {
    try {
        const userReviews = isLocalServerMode
        ? await getUserReviews(authToken)
        : await getData('https://realworker.ru/api/reviews/user-reviews/', null, authToken);

        return userReviews;

    } catch (error) {
        throw error;
    };
};

const fetchUserOwnReviews = async (authToken) => {
    try {
        const userReviews = isLocalServerMode
        ? await getUserOwnReviews(authToken)
        : [];
        
        return userReviews;

    } catch (error) {
        throw error;
    };
}

const fetchUserSupportRequests = async (authToken) => {
    try {
        const userSupportRequests = isLocalServerMode
        ? await getUserSupportRequests(authToken)
        : await getData('https://realworker.ru/api/support/user-requests/', null, authToken);
        
        return userSupportRequests;

    } catch (error) {
        throw error;
    };
};

const fetchVacantOrders = async (authToken) => {
    try {
        const orders = isLocalServerMode
        ? await getVacantOrders(authToken)
        : await getData('https://realworker.ru/api/orders/open/', null, authToken);
        
        return orders;

    } catch (error) {
        throw error;
    };     
};

const fetchOrder = async (orderNumber, authToken) => {
    try {
        const order = isLocalServerMode
        ? await getOrder(orderNumber, authToken)
        : await getData(`https://realworker.ru/api/orders/details/${orderNumber}/`, null, authToken);

        return order;


    } catch (error) {
        throw error;
    };
};

const fetchUserNotifications = async (authToken) => {
    try {
        const userNotifications = isLocalServerMode
        ? await getUserNotifications(authToken)
        : await getData('https://realworker.ru/api/notifications/my/', null, authToken);
        
        return userNotifications;
    } catch(error) {
        throw error;
    };
};

const fetchUserChats = async (authToken) => {
    try {
        const chats = isLocalServerMode
        ? await getUserChats(authToken)
        : await getData('https://realworker.ru/api/messaging/user-chats/', null, authToken);
        
        return chats;
    } catch(error) {
        throw error;
    };
};

const fetchChatMessages = async (chatNumber, authToken) => {
    try {
        const messages = isLocalServerMode
        ? await getChatMessages(chatNumber, authToken)
        : getData(`https://realworker.ru/api/messaging/chat-messages/?chatNumber=${chatNumber}`, null, authToken);

        return messages;
    } catch (error) {
        throw error;
    };
};

const fetchUnreadData = async (authToken) => {
    try {
        const unreadData = isLocalServerMode
        ? await getUnreadData(authToken)
        : getData('https://realworker.ru/api/messaging/unread-count/', null, authToken);

        return unreadData;
        
    } catch(error) {
        throw error;
    }
};

const fetchCities = async () => {
    try {
        const cities = isLocalServerMode
        ? await getCities()
        : await getData('https://realworker.ru/api/users/cities/');
        
        return cities;

    } catch (error) {
        throw error;
    };
};

const fetchCategories = async () => {
    try {
        const categories = isLocalServerMode
        ? await getCategories()
        : await getData('https://realworker.ru/api/users/categories/');
        return categories;


    } catch (error) {
        throw error;
    };
};

const fetchSpecializations = async () => {
    try {
        const specializations = isLocalServerMode
        ? await getSpecializations()
        : await getData('https://realworker.ru/api/users/specializations/');

        return specializations;


    } catch (error) {
        throw error;
    };
};

const fetchQualifications = async () => {
    try {
        const qualifications = isLocalServerMode
        ? await getQualifications()
        : await getData('https://realworker.ru/api/users/qualifications/');

        return qualifications;

    } catch (error) {
        throw error;
    };
};

const fetchSupportTopics = async (authToken) => {
    try {
        const supportTopics = isLocalServerMode
        ? await getSupportTopics(authToken)
        : await getData('https://realworker.ru/api/support/topic-list/', null, authToken);

        return supportTopics;

    } catch (error) {
        throw error;
    };
};

const fetchPhoneValidation = async (type, phone, code) => {
    
    if (type === 'call') {
        const body = JSON.stringify({
            phone: phone.replace(/\D/g, ''),
            code: code
        });

        try {
            const phoneValidation = isLocalServerMode
            ? await getPhoneValidation(type, phone, code)
            : await patchData('https://realworker.ru/api/verification/verify-code/', body);
            
            return phoneValidation;
            
        } catch (error) {
            throw error;
        }
    };

    if (type === 'telegram') {
        const body = JSON.stringify({
            phone: phone.replace(/\D/g, '')
        });

        try {
            const phoneValidation = isLocalServerMode
            ? await getPhoneValidation(type, phone, code)
            : await postData('https://realworker.ru/api/verification/check-phone-verified/', body);

            return phoneValidation;
            
        } catch (error) {
            throw error;
        }
    };
};

const fetchWsToken = async (authToken) => {
    try {
        const wsToken = isLocalServerMode
            ? null
            : await getData('https://realworker.ru/api/ws/get-ws-token/', null, authToken);
        
        return wsToken;

    } catch (error) {
        throw error;
    }
};

const fetchAddressWithGeoData = async (address, authToken) => {
    const body = JSON.stringify({
        'address-prompt': address
    });
    
    try {
        const addressList = isLocalServerMode
            ? null
            : await postData('https://realworker.ru/api/users/suggest-address/', body, null, authToken);

        return addressList;

    } catch (error) {
        throw error;
    };
};


//-----------------------Основные запросы к серверу - ДОБАВЛЕНИЕ ДАННЫХ-----------------------------------

const createAuthToken = async (phone, password) => {
    const body = JSON.stringify({
        username: phone.replace(/\D/g, ''),
        password: password
    });
  
    try {
        const data = isLocalServerMode
        ? await postAuthToken(phone, password)
        : await postData('https://realworker.ru/api/auth/token/login/', body, null, null, {auth_token: 'authToken'});
        
        return { 
            authToken: data.auth_token 
        };
    
    } catch (error) {
        throw error;
    };

};

const createUser = async (userData) => {
    const {name, city, phone, sekretKey, email, password, role} = userData;

    const user = {
        name: name,
        city: city.id,
        username: phone.replace(/\D/g, ''),
        // sekretKey: sekretKey,
        email: email,
        password: password,
        role: role === 'customer' ? 1 : 'worker' ? 2 : '',
    };

    const body = JSON.stringify(user);
    
    try {
        const userPosted = isLocalServerMode
        ? await postUser(userData)
        : await postData('https://realworker.ru/api/auth/users/', body, null, null, {
            name: 'Name',
            // role: 'Role',
            // city: 'City',
            username: '7XXXXXXXXXX',
            // email: 'email@email'
        });
        
        return userPosted;

    } catch (error) {
        throw error;
    }; 
};

const createUserSpecialization = async (authToken, categoryName, specializationName) => {
    const newUserSpecialization = {
        category: categoryName,
        specialization: specializationName  
    };
    const body = JSON.stringify(newUserSpecialization);
    
    try {
        const userSpecializationPosted = isLocalServerMode
        ? await postUserSpecialization(authToken, categoryName, specializationName)
        : await patchData('https://realworker.ru/api/users/my-specializations/add/', body, null, authToken);

        return userSpecializationPosted;

    } catch (error) {
        throw error;
    };
};


const createOrder = async (orderData, authToken) => {
    const {address, dateTime, category, specialization, qualification, description, addressWithGeoData} = orderData;

    // const {geoLat, geoLon} = getRandomCoordsMoscow();

    const newOrder = {
        address: addressWithGeoData.address,
        category: category.id,
        specialization: specialization.id,
        qualification: qualification.id, 
        dateTime: dateTime,
        description: description,
        geoLat: addressWithGeoData.geoLat,
        geoLon: addressWithGeoData.geoLon
    };
    
    const body = JSON.stringify(newOrder);
    
    try {
        const postedOrder = isLocalServerMode
        ? await postOrder(orderData, authToken)
        : await postData('https://realworker.ru/api/orders/create/', body, null, authToken);
        
        return postedOrder;

    } catch (error) {
        throw error;
    };
};

const createNotification = async (orderNumber, notificationTypeName, authToken) => {
    const body = JSON.stringify({
        notificationType: notificationTypeName,
        orderNumber: orderNumber
    });
    
    try {
        const notificationPosted = isLocalServerMode
        ? await postNotification(orderNumber, notificationTypeName, authToken)
        : await postData('https://realworker.ru/api/notifications/', body, null, authToken);
        
        return notificationPosted;

    } catch(error) {
        throw error;
    };
};

const createChat = async (orderNumber, authToken) => {
    const body = JSON.stringify({
        orderNumber
    });

    try {
        const chatPosted = isLocalServerMode
        ? await postChat(orderNumber, authToken)
        : await postData('https://realworker.ru/api/messaging/get-chat/', body, null, authToken);

        return chatPosted;
    } catch (error) {
        throw error;
    }
};

const createMessage = async (chatNumber, messageText, files, authToken) => {
    const messageObj = {
        chatNumber,
        messageText,
        files: files.length > 0 ? files : null
    };
    const body = new FormData();
    for (let key in messageObj) {
        if (key === 'files' ) {
            if (Array.isArray(messageObj[key]) && messageObj[key].length > 0) {
                messageObj[key].forEach(file => {
                    body.append(key, file);
                });
            };
        } else {
            body.append(key, messageObj[key]);
        };
        
    };

    // const body = JSON.stringify({
    //     chatNumber,
    //     messageText,
    // });
    
    try {
        const messagePosted = isLocalServerMode
        ? await postMessage(chatNumber, messageText, authToken)
        : await postData('https://realworker.ru/api/messaging/create-message/', body, 'auto', authToken);
        console.log(messagePosted);
        return messagePosted;

    } catch (error) {
        throw error;
    }
};

const createReview = async (orderNumber, rating, text, authToken) => {
    const body = JSON.stringify({
        text,
        rating,
        orderNumber
    });

    try {
        const reviewPosted = isLocalServerMode
        ? await postReview(orderNumber, rating, text, authToken)
        : await postData('https://realworker.ru/api/reviews/create/', body, null, authToken);

        return reviewPosted;
        
    } catch (error) {
        throw error;
    };
};

const createSupportRequest = async (topicId, text, files, authToken) => {
    // const body = JSON.stringify({
    //     topicId,
    //     text,
    //     files
    // });
    const supportRequestData = {
        topicId,
        text,
        files: files.length > 0 ? files : null
    };

    const body = new FormData();
    for (let key in supportRequestData) {
        if (key === 'files' && Array.isArray(supportRequestData[key])) {
            supportRequestData[key].forEach(item => body.append(`${key}`, item));
        } else {
            body.append(key, supportRequestData[key]);
        };
    };

    try {
        const supportRequestPosted = isLocalServerMode
        ? await postSupportRequest(topicId, text, authToken)
        : await postData('https://realworker.ru/api/support/create-request/', body, 'auto', authToken);

        return supportRequestPosted;

    } catch (error) {
        throw error;
    };
};

const createPhoneVerification = async (type, phone) => {
    const body = JSON.stringify({
        phone: phone.replace(/\D/g, '')
    });

    try {
        const phoneVerificationPosted = isLocalServerMode
        ? await postPhoneVerification(type, phone)
        : await postData('https://realworker.ru/api/verification/get-code/', body);

        return phoneVerificationPosted;

    } catch (error) {
        throw error;
    };
};

//createPhoneRegistration - временный endPoint, добавлен временно отталкиваясь от логики Бэка, после доработки логики на бэке, endPoint нужно будет отключить, т.к. он не нужен.
const createPhoneRegistration = async (phone) => {
    const body = JSON.stringify({
        phone: phone.replace(/\D/g, '')
    });

    try {
        const phoneRegisterPosted = isLocalServerMode
        ? {status: 'OK'}
        : await postData('https://realworker.ru/api/verification/tg-register-phone/', body);

        return phoneRegisterPosted;

    } catch (error) {
        throw error;
    };
};

//-----------------------Основные запросы к серверу - ИЗМЕНЕНИЕ ДАННЫХ--------------------------

const updateOrderWorker = async (orderNumber, authToken) => {
    try {
        const orderUpdated = isLocalServerMode
        ? await patchOrderWorker(orderNumber, authToken)
        : await patchData(`https://realworker.ru/api/orders/assign/${orderNumber}/`, null, null, authToken);
        
        return orderUpdated;

    } catch (error) {
        throw error;
    };

};

const updateOrderWorkerStatus = async (orderNumber, authToken) => {
    try {
        const orderUpdated = isLocalServerMode
        ? await patchOrderWorkerStatus(orderNumber, authToken)
        : await patchData(`https://realworker.ru/api/orders/assign/${orderNumber}/`, null, null, authToken);
        
        return orderUpdated;

    } catch (error) {
        throw error;
    };

};

const updateOrderStatus = async (orderNumber, orderStatusName, authToken) => { 
    try {
        const body = JSON.stringify({
            orderStatus: orderStatusName
        });

        const orderUpdated = isLocalServerMode
        ? await patchOrderStatus(orderNumber, orderStatusName, authToken)
        : await patchData(`https://realworker.ru/api/orders/change-status/${orderNumber}/`, body, null, authToken);
        
        return orderUpdated;

    } catch (error) {
        throw error;
    };
};

const updateOrderData = async (orderNumber, newOrderData, authToken) => {
    try {
        const body = JSON.stringify(newOrderData);

        const orderUpdated = isLocalServerMode
        ?   await patchOrderData(orderNumber, newOrderData, authToken)
        :   await patchData(`https://realworker.ru/api/orders/update/${orderNumber}/`, body, null, authToken);
        
        return orderUpdated;
    } catch (error) {
        throw error;
    }
};

const updateUserData = async (newUserData, authToken) => {
    let photo = null;
    if (!isLocalServerMode && newUserData?.photo?.length > 0) {
        photo = newUserData?.photo[0];
    };

    const newUserDataMod = {
        name: newUserData?.name,
        middleName: newUserData?.middleName,
        surname: newUserData?.surname,
        country: newUserData?.country,
        city: newUserData?.city,
        company: newUserData?.company,
        position: newUserData?.position,
        photo: photo,
        email: newUserData?.email,
        // oldPassword: newUserData?.oldPassword,
        // newPassword: newUserData?.newPassword
    };
    const newUserDataModFiltered = {};
    for (let key in newUserDataMod) {
        if (newUserDataMod[key]) {
            newUserDataModFiltered[key] = newUserDataMod[key];
        }
    };
    
    const body = new FormData();
    
    for (let key in newUserDataModFiltered) {
        body.append(key, newUserDataModFiltered[key]);
    };
       
    try {
        const userUpdated = isLocalServerMode
        ? await patchUserData(newUserData, authToken)
        : await patchData(`https://realworker.ru/api/users/update-profile/`, body, 'auto', authToken);

        return userUpdated;

    } catch(error) {
        throw error;
    };
};

const updateNotificationStatus = async (orderNumber, notificationStatusName, authToken) => {
    const body = JSON.stringify({
        orderNumber,
        notificationStatusName
    });

    try {
        const notificationUpdated = isLocalServerMode
        ? await patchNotificationStatus(orderNumber, notificationStatusName, authToken)
        : await patchData('https://realworker.ru/api/notifications/change-status/', body, null, authToken);
        
        return notificationUpdated;

    } catch(error) {
        throw error;
    };
};


const cancelOrderByWorker = async (orderNumber, authToken) => {
    try {
        const orderUpdated = isLocalServerMode
        ? await patchOrderStatus(orderNumber, 'Поиск исполнителя', authToken)
        : await patchData(`https://realworker.ru/api/orders/remove-worker/${orderNumber}/`, null, null, authToken);

        return orderUpdated;

    } catch (error) {
        throw error;
    };
};



//-----------------------Основные запросы к серверу - УДАЛЕНИЕ ДАННЫХ-----------------------------------
const removeToken = async (authToken) => {
    try {
        const tokenRemoved = isLocalServerMode
        ? await deleteToken(authToken)
        : await postData('https://realworker.ru/api/auth/token/logout/', null, null, authToken);

        return tokenRemoved;

    } catch (error) {
        throw error;
    };
};





export {
    fetchUserRoleName, //ПРОВЕРЕНО
    fetchUserProfile, //ПРОВЕРЕНО
    fetchUserOrders, //ПРОВЕРЕНО
    fetchUserSpecializations, //ПРОВЕРЕНО
    fetchUserReviews, //ПРОВЕРЕНО
    fetchUserOwnReviews, //ПОКА НЕ ПРИМЕНЯЕТСЯ
    fetchUserSupportRequests, //ПРОВЕРЕНО
    fetchVacantOrders, //ПРОВЕРЕНО
    fetchOrder, //ПРОВЕРЕНО
    fetchUserNotifications, //ПРОВЕРЕНО
    fetchUserChats, //ПРОВЕРЕНО
    fetchChatMessages, //ПРОВЕРЕНО
    fetchUnreadData, //ПРОВЕРЕНО
    fetchCities, //ПРОВЕРЕНО
    fetchCategories, //ПРОВЕРЕНО
    fetchSpecializations, //ПРОВЕРЕНО
    fetchQualifications, //ПРОВЕРЕНО
    fetchSupportTopics, //ПРОВЕРЕНО
    fetchPhoneValidation,
    fetchWsToken,
    fetchAddressWithGeoData,

    createAuthToken, //ПРОВЕРЕНО
    createUser, //ПРОВЕРЕНО
    createUserSpecialization,
    createOrder, //ПРОВЕРЕНО
    createNotification, //ПРОВЕРЕНО
    createChat, //ПРОВЕРЕНО
    createMessage, //ПРОВЕРЕНО
    createReview, //ПРОВЕРЕНО
    createSupportRequest, //ПРОВЕРЕНО
    createPhoneVerification,
    createPhoneRegistration, //ВРЕМЕННЫЙ ENDPOINT
    
    updateOrderWorker, //ПОКА НЕ ПРИМЕНЯЕТСЯ
    updateOrderWorkerStatus, //ПРОВЕРЕНО
    updateOrderStatus, //ПРОВЕРЕНО
    updateOrderData, //ПРОВЕРЕНО
    updateUserData, //ПРОВЕРЕНО
    updateNotificationStatus, //ПРОВЕРЕНО
    cancelOrderByWorker,

    removeToken, //ПРОВЕРЕНО
    
};