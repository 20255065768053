import { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserInfo from "../../components/header/components/UserInfo";
import HeaderWorker from "../../components/header/HeaderWorker";
import ButtonBurger from '../../components/buttonBurger/ButtonBurger';
import MenuSideWorker from "../../components/menu/menuSide/MenuSideWorker";
import MenuQuickWorker from "../../components/menu/menuQuick/MenuQuickWorker";
import Filter from "../../components/filter/Filter";
import OrdersCounter from "../../components/ordersCounter/OrdersCounter";
import EventsCalendar from "../../components/eventsCalendar/EventsCalendar";
import OrderCardWorker from "../../components/card/OrderCardWorker";
import Spinner from "../../components/spinner/Spinner";
import CardEmpty from "../../components/card/CardEmpty";
import useAuth from "../../hooks/useAuth";
import useOrders from "../../hooks/useOrders";

import './WorkerOrdersPage.scss';


const WorkerOrdersPage = () => {
    const [filter, setFilter] = useState('active');
    const {orders, sortedOrders, filteredOrders, setUpdateOrdersList} = useOrders(filter);
    console.log(orders)
    const {authToken, userPhoto} = useAuth();
    const navigate = useNavigate();

    const renderOrders = (orders) => {
        if (!orders) {
            return <Spinner additionalClasses='h-full w-full'/>
        }
        if (orders.length < 1) {
            let title,
                description;
            if (filter === 'active') {
                title = 'Список активных заказов пуст';
                description = 'Здесь будут храниться активные заказы';
            };
            if (filter === 'done') {
                title = 'Список выполненных заказов пуст';
                description = 'Здесь будут храниться выполненные заказы';
            }
            if (filter === 'all') {
                title = 'Список Ваших заказов пуст';
                description = 'Здесь будут храниться Ваши заказы';
            }

            return <CardEmpty
                        title={title}
                        description={description}
                        buttonText='Найти заказ'
                        buttonClick={() => navigate('/vacant-orders')}/>
        }
        return orders.map((order, i) => (
            <OrderCardWorker
                key={i}
                authToken={authToken}
                setUpdateOrdersList={setUpdateOrdersList}
                {...order}
            />
        ));
    };


    return (
        <>
            <HeaderWorker>
                <div className="header__content">
                    <ButtonBurger />
                    <h1 className="header__title">Мои заказы</h1>
                    <UserInfo/>
                </div>
            </HeaderWorker>

            <div className="main__wrapper">
                <MenuSideWorker />
                <main className="main__content">
                    <div className="container container--small">
                        <Filter 
                            optionsList={[
                                {name: 'Активные', value: 'active'},
                                {name: 'Выполненные', value: 'done'},
                                {name: 'Все заказы', value: 'all'}
                            ]}
                            option={filter}
                            setOption={setFilter}/>
                        <OrdersCounter counters={[
                            {number: sortedOrders.searchWorkerOrders?.length + sortedOrders.foundedWorkerOrders?.length + sortedOrders.progressOrders?.length, text: 'Всего активных', handleClick: () => setFilter('active'), active: filter === 'active'},
                            {number: sortedOrders.doneOrders?.length, text: 'Всего выполнено', handleClick: () => setFilter('done'), active: filter === 'done'},
                            {number: orders?.length, text: 'Всего заказов', handleClick: () => setFilter('all'), active: filter === 'all'}
                        ]}/>
                        <EventsCalendar orders={orders} additionalClasses={'mt-20'}/>
                        <section className="mt-24">
                            {renderOrders(filteredOrders)}
                        </section>
                    </div>
                </main>
            </div>

            <MenuQuickWorker/>
        </>
    ); 
    
};

export default WorkerOrdersPage;