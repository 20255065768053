import { BrowserRouter, Routes, Route } from 'react-router-dom';
import WithAuth from './hoc/WithAuth';
import HomePage from './pages/homePage/HomePage';
import ManualPage from './pages/manualPage/ManualPage';
import FeedbackPage from './pages/feedbackPage/FeedbackPage';
import LoginPage from './pages/loginPage/LoginPage';
import RegisterPage from './pages/registerPage/RegisterPage';
import CustomerOrdersPage from './pages/customerOrdersPage/CustomerOrdersPage';
import CustomerOrderPage from './pages/orderPage/CustomerOrderPage';
import CustomerNotificationsPage from './pages/customerNotificationsPage/CustomerNotificationsPage';
import CustomerMessagesPage from './pages/customerMessagesPage/CustomerMessagesPage';
import CustomerSettingsPage from './pages/customerSettingsPage/CustomerSettingsPage';
import CustomerSupportPage from './pages/customerSupportPage/CustomerSupportPage';
import CustomerAboutPage from './pages/aboutPage/CustomerAboutPage';
import Contract from './pages/aboutPage/components/Contract';
import UserAgreement from './pages/aboutPage/components/UserAgreement';
import WorkerOrdersPage from './pages/workerOrdersPage/WorkerOrdersPage';
import VacantOrdersPage from './pages/vacantOrdersPage/VacantOrdersPage';
import WorkerOrderPage from './pages/orderPage/WorkerOrderPage';
import WorkerNotificationsPage from './pages/workerNotificationsPage/WorkerNotificationsPage';
import WorkerMessagesPage from './pages/workerMessagesPage/WorkerMessagesPage';
import WorkerSpecializationsPage from './pages/workerSpecializationsPage/WorkerSpecializationsPage';
import WorkerReviewsPage from './pages/workerReviewsPage/WorkerReviewsPage';
import WorkerSettingsPage from './pages/workerSettingsPage/WorkerSettingsPage';
import WorkerSupportPage from './pages/workerSupportPage/WorkerSupportPage';
import WorkerAboutPage from './pages/aboutPage/WorkerAboutPage';
import NotFoundPage from './pages/notFoundPage/NotFoundPage';
import InstallationAppIos from './pages/manualPage/components/installationApp/InstallationAppIos';
import QuickStartCustomer from './pages/manualPage/components/quickStart/QuickStartCustomer';
import OrderCreation from './pages/manualPage/components/orderCreation/OrderCreation';
import OrderManagement from './pages/manualPage/components/orderManagement/OrderManagement';
import CustomerChat from './pages/manualPage/components/customerChat/CustomerChat';
import CustomerReviews from './pages/manualPage/components/customerReviews/CustomerReviews';
import QuickStartWorker from './pages/manualPage/components/quickStart/QuickStartWorker';
import OrderSearching from './pages/manualPage/components/orderSearching/OrderSearching';
import OrderExecution from './pages/manualPage/components/orderExecution/OrderExecution';
import WorkerSpecializations from './pages/manualPage/components/workerSpecializations/WorkerSpecializations';
import WorkerRating from './pages/manualPage/components/workerRating/WorkerRating';
import Faq from './pages/manualPage/components/faq/Faq';
import RecoveryPage from './pages/recoveryPage/RecoveryPage';
import './assets/styles/variables.scss';
import './assets/styles/mixins.scss';
import './assets/styles/fonts.css';
import './assets/styles/global.scss';


function App() {

    return (
        <BrowserRouter >
            <Routes>

                {/* Только не авторизованные пользователи */}
                <Route element={<WithAuth mode='notAuth' />}>
                    <Route path='/login' element={<LoginPage/>}/>
                    <Route path='/register' element={<RegisterPage/>}/>
                    <Route path='/recovery' element={<RecoveryPage/>}/>
                </Route>

                {/* Только Заказчик */}
                <Route element={<WithAuth requiredRole='customer' />}>
                    <Route path='/customer-orders' element={<CustomerOrdersPage/>}/>
                    <Route path='/customer-orders/:orderNumber' element={<CustomerOrderPage/>}/>
                    <Route path='/customer-notifications' element={<CustomerNotificationsPage/>}/>
                    <Route path='/customer-messages' element={<CustomerMessagesPage/>}/>
                    <Route path='/customer-settings' element={<CustomerSettingsPage/>}/>
                    <Route path='/customer-support' element={<CustomerSupportPage/>}/>
                    {/* <Route path='/customer-about' element={<CustomerAboutPage/>}/> */}
                    <Route path='/customer-about/' element={<CustomerAboutPage/>}>
                        <Route path='user-agreement' element={<UserAgreement/>}/>
                        <Route path='personal-data-policy' element={<p>Personal data processing policy</p>}/>
                        <Route path='contract' element={<Contract/>}/>
                        <Route path='personal-data-consent' element={<p>Consent to the processing of personal data</p>}/>
                    </Route>
                </Route>

                {/* Только Исполнитель */}
                <Route element={<WithAuth requiredRole='worker' />}>
                    <Route path='/worker-orders' element={<WorkerOrdersPage/>}/>
                    <Route path='/vacant-orders' element={<VacantOrdersPage/>}/>
                    <Route path='/worker-orders/:orderNumber' element={<WorkerOrderPage/>}/>
                    <Route path='/worker-notifications' element={<WorkerNotificationsPage/>}/>
                    <Route path='/worker-messages' element={<WorkerMessagesPage/>}/>
                    <Route path='/worker-specializations' element={<WorkerSpecializationsPage/>}/>
                    <Route path='/worker-reviews' element={<WorkerReviewsPage/>}/>
                    <Route path='/worker-settings' element={<WorkerSettingsPage/>}/>
                    <Route path='/worker-support' element={<WorkerSupportPage/>}/>
                    <Route path='/worker-about/' element={<WorkerAboutPage/>}>
                        <Route path='user-agreement' element={<UserAgreement/>}/>
                        <Route path='personal-data-policy' element={<p>Personal data processing policy</p>}/>
                        <Route path='contract' element={<Contract/>}/>
                        <Route path='personal-data-consent' element={<p>Consent to the processing of personal data</p>}/>
                    </Route>
                </Route>

                {/* Без требований к авторизации */}
                <Route path='/' element={<HomePage/>}/>
                <Route path='*' element={<NotFoundPage/>}/>
                {/* <Route path='/manual' element={<ManualPage/>}/> */}
                <Route path='/manual/' element={<ManualPage/>}>
                    <Route path='installation/ios' element={<InstallationAppIos/>}/>
                    <Route path='installation/android' element={<InstallationAppIos/>}/>

                    <Route path='quick-start/customer' element={<QuickStartCustomer/>}/>
                    <Route path='order-creation' element={<OrderCreation/>}/>
                    <Route path='order-management' element={<OrderManagement/>}/>
                    <Route path='chat' element={<CustomerChat/>}/>
                    <Route path='service-estimation' element={<CustomerReviews/>}/>

                    <Route path='quick-start/worker' element={<QuickStartWorker/>}/>
                    <Route path='order-searching' element={<OrderSearching/>}/>
                    <Route path='order-execution' element={<OrderExecution/>}/>
                    <Route path='worker-specializations' element={<WorkerSpecializations/>}/>
                    <Route path='worker-rating' element={<WorkerRating/>}/>
                    <Route path='faq' element={<Faq/>}/>
                </Route>
                <Route path='/feedback' element={<FeedbackPage/>}/>
                
            </Routes>
        </BrowserRouter>
    );
}

export default App;
