import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { DecorCircle, DecorDots, DecorIndicator, MessageLink, PhoneLink, EnterLink, FooterInfo, FooterInfoBtn } from './CardComponents';
import useUserAction from '../../hooks/useUserAction';
import useAlert from '../../hooks/useAlert';
import useDate from '../../hooks/useDate';
import { createChat} from '../../api/api';

import './Card.scss';

const OrderCardWorker = ({...props}) => {
    const [footerLinks, setFooterLinks] = useState({
        messageLink: false,
        phoneLink: false
    });
    const {formatDate} = useDate();
    const {number, dateTime, address, specialization, qualification, customer, orderStatus, authToken, setUpdateOrdersList, requestedStatusChange} = props;
    const {workerArrived, workerStartedWorkingRequest, workerFinishedWorkingRequest} = useUserAction();
    const {showAlert, showAlertRequire} = useAlert();
    const navigate = useNavigate();
    
    
    const handleClick = async () => {
        if (requestedStatusChange && orderStatus === 'Исполнитель приехал') {
            showAlert('Уведомление о начале работ отправлено. Ожидайте подтверждение от Заказчика.');
            return;
        };
        if (requestedStatusChange && orderStatus === 'Исполнитель работает') {
            showAlert('Уведомление о выполнении работ отправлено. Ожидайте подтверждение от Заказчика.');
            return;
        };

        try {
            
            if (orderStatus === 'Назначен исполнитель') {
                await showAlertRequire('Уведомить Заказчика о прибытии на объект?', 'ok-cancel');
                await workerArrived(number, authToken);
                setUpdateOrdersList(state => !state);
                showAlert('Уведомление о Вашем прибытии отправлено Заказчику.');
            }
            if (orderStatus === 'Исполнитель приехал') {
                await showAlertRequire('Уведомить Заказчика о начале производства работ?', 'ok-cancel');
                await workerStartedWorkingRequest(number, authToken);
                setUpdateOrdersList(state => !state);
                showAlert('Уведомление о начале работ отправлено. Ожидайте подтверждение от Заказчика.');
            }
            if (orderStatus === 'Исполнитель работает') {
                await showAlertRequire('Уведомить Заказчика о выполнении работ?', 'ok-cancel');
                await workerFinishedWorkingRequest(number, authToken);
                setUpdateOrdersList(state => !state);
                showAlert('Уведомление о выполнении работ отправлено. Ожидайте подтверждение от Заказчика.');
            }
            
        } catch (error) {
            //Проверяем наличие error.status чтобы не обработать reject, возвращенный showAlertRequire
            if (error?.status) {
                console.log(error);
                showAlert(error.message);
            };
        };
    }

    const createNotificationText = (orderStatus, requestedStatusChange) => {
        let notificationText = '';

        switch (orderStatus) {
            case 'Назначен исполнитель':
                notificationText = 'Я прибыл на объект';
                break;
            case 'Исполнитель приехал':
                notificationText = 'Я начал работать';
                break;
            case 'Исполнитель работает':
                notificationText = 'Я выполнил заказ';
                break;
            case 'Заказ выполнен':
                notificationText = 'Заказ выполнен';
                break;
            case 'Заказ закрыт':
                notificationText = 'Заказ закрыт';
                break;
            default:
                notificationText = '';
        }

        if (requestedStatusChange) {
            notificationText = 'Запрошено подтверждение';
        }

        return notificationText;
    };

    const onMessageClick = async () => {
        try {
            const chatCreated = await createChat(number, authToken);
            navigate('/worker-messages', {state: {
                chatNumber: chatCreated.chatNumber
            }});

        } catch (error) {
            console.log(error);
            showAlert(error.message);
        }

    };

    useEffect(() => {
        const footerLinks = {
            messageLink: false,
            phoneLink: false
        };
        switch (orderStatus) {
            case 'Назначен исполнитель':
                footerLinks.messageLink = true;
                footerLinks.phoneLink = true;
                break;
            case 'Исполнитель приехал':
                footerLinks.messageLink = true;
                footerLinks.phoneLink = true;
                break;
            case 'Исполнитель работает':
                footerLinks.messageLink = true;
                footerLinks.phoneLink = true;
                break;
            case 'Заказ выполнен':
                break;
            case 'Заказ закрыт':
                break;
            default:
                break;
        };
        setFooterLinks(footerLinks);
    }, [orderStatus]);

    return (
        <div className="card">
            <div className="card__header">
                <div className="card__order-number" onClick={() => navigate(`/worker-orders/${number}`)}>
                    Заказ №{number}
                </div>
                <div className="card__order-time">
                    {formatDate(dateTime)}
                </div>
                <DecorIndicator orderStatus={orderStatus} requestedStatusChange={requestedStatusChange}/>
            </div>
            <div className="card__content">
                <div className="card__content__item">
                    <div className="card__content__item__param">
                        Адрес
                    </div>
                    <div className="card__content__item__value">
                        {address}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                        <DecorDots/>
                    </div>
                </div>
                <div className="card__content__item">
                    <div className="card__content__item__param">
                        Специализация
                    </div>
                    <div className="card__content__item__value">
                        {specialization}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                        <DecorDots/>
                    </div>
                </div>
                <div className="card__content__item">
                    <div className="card__content__item__param">
                        Квалификация
                    </div>
                    <div className="card__content__item__value">
                        {qualification}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                        <DecorDots/>
                    </div>
                </div>
                <div className="card__content__item">
                    <div className="card__content__item__param">
                        Заказчик
                    </div>
                    <div className="card__content__item__value">
                        {customer}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                    </div>
                </div>
            </div>
            <div className="card__footer">
                <FooterInfo orderStatus={orderStatus} requestedStatusChange={requestedStatusChange} handleClick={handleClick}>
                    {createNotificationText(orderStatus, requestedStatusChange)}
                    {/* <div className='card__info__btn' onClick={handleClick}>&gt;</div> */}
                    {!requestedStatusChange && <FooterInfoBtn orderStatus={orderStatus}/>}
                </FooterInfo >
                <div className="card__links">
                    <IconContext.Provider value={{className: 'card__links__icon'}}>
                        {footerLinks.messageLink && <MessageLink handleClick={onMessageClick}/>}
                        {footerLinks.phoneLink && <PhoneLink/>}
                        <EnterLink handleClick={() => navigate(`/worker-orders/${number}`)}/>
                    </IconContext.Provider>
                </div>
            </div>
        </div>
    );
};

export default OrderCardWorker;



