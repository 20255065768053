import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { DecorCircle, DecorDots, EnterLink } from './CardComponents';
import Star from '../star/Star';

import useDate from '../../hooks/useDate';

import './Card.scss';

const ReviewCard = ({orderNumber, timeCreate, rating, text, creatorName, creatorSurname, ...props }) => {
    const {formatDate} = useDate();

    const renderRatingStars = (rating) => {
        const ratingStars = [];

        for (let i = 0; i < 5; i++) {
            ratingStars.push(<Star key={i} className={`mr-5 h-16 w-16 ${i < rating ? 'color-orange-4' : ''}`}/>);
        };

        return ratingStars;
    };

    return (
        <div className="card">
            <div className="card__header">
                <div className="card__order-number">
                    {`${creatorName} ${creatorSurname ? creatorSurname : ''}`}
                </div>
                <div className="card__order-time">
                    {formatDate(timeCreate)}
                </div>
            </div>
            <div className="card__content">
                <div className="card__content__item">
                    {/* <div className="card__content__item__param">
                        Оценка
                    </div> */}
                    <div className="card__content__item__value">
                        {renderRatingStars(rating)}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                        {text &&
                        <DecorDots/>}
                    </div>
                </div>
                {text &&
                <div className="card__content__item">
                    {/* <div className="card__content__item__param">
                        Комментарий
                    </div> */}
                    <div className="card__content__item__value">
                        {text}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                    </div>
            </div>}
            </div>
        </div>
    );
}; 

export default ReviewCard;