import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useAlert from "./useAlert";
import { fetchUserChats, fetchOrder, fetchChatMessages, createMessage } from "../api/api";

const useChat = (authToken) => {
    const [chats, setChats] = useState(null);
    const [chatSelected, setChatSelected] = useState(null);

    const [order, setOrder] = useState(null); //стейт нужен для работы popup с данными заказа
    const [orderNumberSelected, setOrderNumberSelected] = useState(null); //стейт нужен для работы popup с данными заказа
    
    const [messages, setMessages] = useState(null);
    const [interlocutor, setInterlocutor] = useState(null);
    const messagesListRef = useRef(null);
    const textareaRef = useRef(null);
    const location = useLocation();
    const {showAlert} = useAlert();
    const {wsToken} = useSelector(state => state.user);
    const {unreadMessages} = useSelector(state => state.unreadData);

    const webSocket = useRef(null); //поместим соединение webSoket в useRef для того, чтобы изменение в useRef не вызывало повторный рендер компонента
    
    //Функция для прокрутки списка сообщений
    // const scrollToBottom = () => {
    //     const element = messagesListRef.current;
    //     element.scrollTop = element.scrollHeight;
    // };

    const scrollToBottom = () => {
        const element = messagesListRef.current;
        //Т.к. функция scrollToBottom может быть вызвана до того, как отрисовано DOM-дерево, нужно поставить проверку - отрисован ли элемент. Если элемент уже отрисован на странице, то выполним прокрутку в низ, если еще не успел отрисоваться, поставим таймер с задержкой 100 мс.
        if (element) {
            element.scrollTop = element.scrollHeight;
        } else {
            setTimeout(scrollToBottom, 100);
        }
        
    };

    //Функция для фокусировки на поле ввода сообщений
    const focusOnInput = () => {
        const input = textareaRef.current;
        input.focus();
    };

    //Функция для очистки списка сообщений
    const clearMessages = () => {
        setMessages(null);
    };

    //Функция для очистки данных собеседника
    const clearInterlocutor = () => {
        setInterlocutor(null);
    };

    
    const loadChats = async () => {
        try {
            const userChats = await fetchUserChats(authToken);
            setChats(userChats);
        } catch (error) {
            console.log(error);
            showAlert(error.message);
        };
    };

    const loadOrder = async (orderNumber) => {
        try {
            const order = await fetchOrder(orderNumber, authToken);
            setOrder(order);

            return order;
        } catch (error) {
            console.log(error);
            showAlert(error.message);
        }
    };

    const loadMessages = async () => {
        try {
            const messages = await fetchChatMessages(chatSelected, authToken);
            const sortedMessages = [...messages].sort((a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime());
    
            setMessages(state => { 
                //Временное решение - проверка длины массива сообщений, если идентично, не обновляем стейт
                if (state && (state.length === sortedMessages.length)) {
                    return state;
                }

                return sortedMessages;
            });

        } catch (error) {
            console.log(error);
            showAlert(error.message);
        };
    };

    const loadInterlocutor = () => {
        const chat = chats?.find(chat => chat.chatNumber === chatSelected);

        const interlocutor = {
            name: chat?.interlocutorName,
            surname: chat?.interlocutorSurname,
            photo: chat?.interlocutorPhoto,
            lastActive: chat?.interlocutorLastActive
        };

        setInterlocutor(interlocutor);
    };
    
    const onSendMessage = async (messageText, files) => {
        try {
            await createMessage(chatSelected, messageText, files, authToken);
            loadChats();
            // setTimeout(scrollToBottom, 2000); //Временное решение скролла к последнему сообщению - убрать из продакшена
        } catch (error) {
            console.log(error);
            showAlert(error.message);
        };
    };

    //Ф-я установит соединение webSocket, а также назначит слушателей событий onopen, onMessage, onError, onClose
    const connectWebSocket = () => {
        webSocket.current = new WebSocket(`wss://realworker.ru/ws/chat/${wsToken}/${chatSelected}/`);

        webSocket.current.onopen = (event) => {
            console.log('Установлено WS соединение', event);
        };

        webSocket.current.onmessage = (event) => {
            //Иногда бывает что после отправки сообщения, приходит по WS ошибка. Поэтому будем проверять, пришло сообщение или нет, если нет - делаем дублирующую загрузку сообщений по http, таким образом мы увидим ранее отправленное сообщение, которое по причине сбоя не пришло к нам по каналу webSocket. 
            const eventData = JSON.parse(event.data);
            console.log('Получено сообщение', eventData);
            if (eventData?.message?.text) {
                setMessages(state => {
                    return [...state, eventData.message];
                });
            } else {
                loadMessages();
            }
        };

        webSocket.current.onerror = (event) => {
            console.log('Ошибка в работе WS соединения', event);
        };

        webSocket.current.onclose = (event) => {
            console.log('Соединение WS прекращено', event);
        };
    }

    //Ф-я разорвет соединение webSocket
    const removeWebSocket = () => {
        if (webSocket.current) {
            webSocket.current?.close(1000, 'Закрыто по требованию пользователя');
            webSocket.current = null;
        };
    };

    //Если при переходе на страницу сообщений, в параметре state передан номер чата (при переходе из карточки заказа),
    //то установим номер чата в chatSelected
    useEffect(() => {
        if (location.state?.chatNumber) {
            setChatSelected(location.state.chatNumber);
        };

        // loadChats();

    }, []);
    
    useEffect(() => {
        // if (location.state?.chatNumber) {
        //     setChatSelected(location.state.chatNumber);
        // };

        loadChats();

    }, [unreadMessages]);

    useEffect(() => {
        if (orderNumberSelected) {
            loadOrder(orderNumberSelected);
        }
    }, [orderNumberSelected]);

    useEffect(() => {

        if (chatSelected) {
            clearInterlocutor();
            clearMessages();
            loadInterlocutor();
            loadMessages()
            .then(() => loadChats())
            .then(() => focusOnInput());

            return () => {
                
                // Разорвем соединение websocket
                removeWebSocket();
            }
        }

    }, [chatSelected]);

    useEffect(() => {
        //Когда чаты загружены, загружаем данные собеседника
        if (chats?.length > 0 && chatSelected) loadInterlocutor();
    }, [chats]);

    
    useEffect(() => {    
        //Установим соединение webSocket только тогда, когда выбран чат, загружены сообщения и соединение webSocket не установлено
        if (chatSelected && messages && !webSocket.current) {
            connectWebSocket();
        };

        //После загрузки сообщений, выполним прокрутку поля отображения сообщений в самый низ
        if (messages && messages?.length > 0) {
            // console.log(messages);
            scrollToBottom();
        };

    }, [messages]);

    
    return {
        chats,
        chatSelected,
        setChatSelected,
        order,
        setOrder,
        orderNumberSelected,
        setOrderNumberSelected,
        interlocutor,
        setInterlocutor,
        messages,
        setMessages,
        messagesListRef,
        onSendMessage,
        textareaRef
    }
};

export default useChat;