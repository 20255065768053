import { useState } from "react";
import HeaderCustomer from "../../components/header/HeaderCustomer";
import ButtonBurger from "../../components/buttonBurger/ButtonBurger";
import MenuSideCustomer from "../../components/menu/menuSide/MenuSideCustomer";
import MenuQuickCustomer from "../../components/menu/menuQuick/MenuQuickCustomer";
import Filter from "../../components/filter/Filter";
import Spinner from '../../components/spinner/Spinner';
import CardEmpty from "../../components/card/CardEmpty";
import NotificationCard from "../../components/card/NotificationCard";
import UserInfo from "../../components/header/components/UserInfo";
import useNotifications from "../../hooks/useNotifications";

import './CustomerNotificationsPage.scss';


const CustomerNotificationsPage = () => {
    const [filter, setFilter] = useState('all');

    const {filteredNotifications, confirmNotification, rejectNotification} = useNotifications(filter);

    const renderNotifications = (notifications) => {
        if (!notifications) {
            return <Spinner additionalClasses='h-full w-full'/>
        }
        if (notifications.length < 1) {
            let title,
                description;
            if (filter === 'requireConfirmation') {
                title = 'Список уведомлений пуст';
                description = 'Здесь будут храниться уведомления, требующие потверждения';
            };
            if (filter === 'all') {
                title = 'Список уведомлений пуст';
                description = 'Здесь будут храниться Ваши уведомления';
            };

            return <CardEmpty
                        title={title}
                        description={description}/>
        }
        return notifications.map((notification, i) => (
            <NotificationCard
                key={i}
                confirmNotification={confirmNotification}
                rejectNotification={rejectNotification}
                {...notification}/>
        ));
    };

    return (
        <>
            <HeaderCustomer>
                <div className="header__content">
                    <ButtonBurger />
                    <h1 className="header__title">Уведомления</h1>
                    <UserInfo/>
                </div>
            </HeaderCustomer>

            <div className="main__wrapper">
                <MenuSideCustomer />
                <main className="main__content">
                    <div className="container container--small">
                        <Filter
                            optionsList={[
                                {name: 'Все', value: 'all'},
                                {name: 'На подтверждение', value: 'requireConfirmation'}
                            ]}
                            option={filter}
                            setOption={setFilter}/>

                        <section className="mt-24">
                            {renderNotifications(filteredNotifications)}
                        </section>
                    </div>
                </main>
            </div>

            <MenuQuickCustomer/>
        </>
    );
};

export default CustomerNotificationsPage;