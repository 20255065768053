import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    // id: null,
    name: null,
    // city: null,
    role: null,
    // phone: null,
    // email: null,
    // password: null,
    authToken: null,
    wsToken: null,
    photo: null
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            // state.id = action.payload.id;
            state.name = action.payload.name;
            // state.city = action.payload.city;
            state.role = action.payload.role;
            // state.phone = action.payload.phone;
            // state.email = action.payload.email;
            // state.password = action.payload.password;
            state.authToken = action.payload.authToken;

        },
        setUserPhoto: (state, action) => {
            state.photo = action.payload.photo;
        },
        setWsToken: (state, action) => {
            state.wsToken = action.payload.wsToken;
        },
        removeUser: (state) => {
            // state.id = null;
            state.name = null;
            // state.city = null;
            state.role = null;
            // state.phone = null;
            // state.email = null;
            // state.password = null;
            state.authToken = null;
            state.photo = null;
            state.wsToken = null;
        }
    }
});

const {actions, reducer} = userSlice;

export const {setUser, setUserPhoto, setWsToken, removeUser} = actions;
export default reducer;