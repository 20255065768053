import OrderCard from "./OrderCard";

const OrderCardCustomer = ({...props}) => {
    return (
        <OrderCard 
            {...props}
            role='customer'
        />
    );
};

export default OrderCardCustomer;