import { useState } from 'react';
import { CgOptions } from 'react-icons/cg';
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import HeaderWorker from '../../components/header/HeaderWorker';
import ButtonBurger from '../../components/buttonBurger/ButtonBurger';
import MenuSideWorker from '../../components/menu/menuSide/MenuSideWorker';
import MenuQuickWorker from '../../components/menu/menuQuick/MenuQuickWorker';
import Filter from "../../components/filter/Filter";
import OrderCardVacant from '../../components/card/OrderCardVacant';
import OrderCardVacantMini from '../../components/card/OrderCardVacantMini';
import SearchOptions from './components/SearchOptions/SearchOptions';
import Spinner from '../../components/spinner/Spinner';
import CardEmpty from '../../components/card/CardEmpty';
import useVacantOrders from '../../hooks/useVacantOrders';

import './VacantOrdersPage.scss';
import 'leaflet/dist/leaflet.css';

import marker from '../../../node_modules/leaflet/dist/images/marker-icon-2x.png';
// import marker from '../../assets/icons/map/marker-icon-custom.png';

const VacantOrdersPage = () => {
    const [mode, setMode] = useState('list');
    const [isOptionsVisible, setIsOptionsVisible] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    // const [searchValue, setSearchValue] = useState('');

    const {filteredOrders, resetFilters, startDate, setStartDate, endDate, setEndDate, identialSpecialization, setIdentialSpecialization, identialQualification, setIdentialQualification, searchText, setSearchText} = useVacantOrders();
    

    const toggleOptions = () => {
        setIsOptionsVisible(state => !state);
    };

    const renderOrders = (orders) => {
        if (!orders) {
            return <Spinner additionalClasses='h-full w-full'/>
        };
        if (orders.length < 1) {
            return <CardEmpty
                        title='Свободных заказов сейчас нет'
                        description='Все заказы уже разобрали. Зайдите немного позже, заказы обязательно появятся'/>
        };

        return orders.map((item, i) => (
            <OrderCardVacant
                key={i}
                {...item}/>
        ));
    };

    const mapCenter = [55.753544, 37.621202];
    
    const myIcon = new Icon({
        iconUrl: marker,
        iconSize: [25, 41],
        // iconSize: [50, 50],
        // iconAnchor: [12, 41],
        popupAnchor: [0, 0],
        // className: 'my-icon'
      });

    return (
        <>
            <HeaderWorker>
                <div className="header__content">
                    <ButtonBurger />
                    <input 
                        className="input input--header"
                        type="text"
                        placeholder='Поиск по объявлениям'
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}/>
                    <CgOptions 
                        className={`header__img cursor-pointer ${startDate || endDate || identialSpecialization || identialQualification ? 'header__img--orange' : 'header__img--grey'}`}
                        onClick={toggleOptions}/>
                </div>
            </HeaderWorker>

            <div className="main__wrapper">
                <MenuSideWorker />
                <main className="main__content">
                    {/* <Filter 
                        optionsList={[
                            {name: 'Список', value: 'list'},
                            {name: 'Карта', value: 'map'}
                        ]}
                        option={mode}
                        setOption={setMode}
                        additionalClasses={`${mode === 'map' ? 'filter--absolute' : ''} ${isPopupOpen ? 'd-none' : ''}`}
                        position={mode === 'map' ? 'absolute' : ''}
                        isVisible={!isPopupOpen}
                    /> */}
                    <div className={`vacant-orders__filter ${mode === 'map' ? 'absolute' : ''} ${isPopupOpen ? 'd-none' : ''}`}>
                        <Filter 
                            optionsList={[
                                {name: 'Список', value: 'list'},
                                {name: 'Карта', value: 'map'}
                            ]}
                            option={mode}
                            setOption={setMode}
                            // additionalClasses={`${mode === 'map' ? 'filter--absolute' : ''} ${isPopupOpen ? 'd-none' : ''}`}
                            // position={mode === 'map' ? 'absolute' : ''}
                            // isVisible={!isPopupOpen}
                        />
                    </div>

                    {mode === 'list' && !isOptionsVisible && 
                    <div className="container container--small">
                        <section className="mt-24">
                            {renderOrders(filteredOrders)}
                        </section>
                    </div>
                    }
                    {mode === 'map' && !isOptionsVisible &&
                    <div className="map">
                        <MapContainer 
                            center={mapCenter} 
                            zoom={10} 
                            scrollWheelZoom={true} 
                            attributionControl={false} 
                            style={{ width: '100%', height: '100vH' }}
                            zoomControl={false}>
                            <TileLayer
                                // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                                {filteredOrders.map((order, i) => (
                                    <Marker
                                        key={i} 
                                        position={[order.geoLat, order.geoLon]}
                                        icon={myIcon}
                                        eventHandlers={{ 
                                            popupopen: () => setIsPopupOpen(true), 
                                            popupclose: () => setIsPopupOpen(false),
                                             
                                        }}
                                    >
                                        <Popup>
                                            {/* {order.address} */}
                                            <OrderCardVacantMini
                                                key={i}
                                                {...order}/>
                                        </Popup>
                                    </Marker>
                                ))}
                        </MapContainer>
                    </div>
                    }
                    {isOptionsVisible && 
                        <SearchOptions
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            identialSpecialization={identialSpecialization}
                            setIdentialSpecialization={setIdentialSpecialization}
                            identialQualification={identialQualification}
                            setIdentialQualification={setIdentialQualification}
                            resetFilters={resetFilters}
                            toggleOptions={toggleOptions}
                    />}
                </main>
            </div>

            <MenuQuickWorker/>
        </>
    );
};

export default VacantOrdersPage;