import ImageZoomer from "../../../../components/imageZoomer/ImageZoomer";
import reviewTrigger from '../../../../assets/images/manual/customer-reviews/review-trigger.png';
import reviewPopup from '../../../../assets/images/manual/customer-reviews/new-review-popup.png';


const CustomerReviews = () => {
    return (
        <>
            <h1>Оценка оказанных услуг</h1>
            <p className="mt-30">После выполнения заказа, Вы можете оставить отзыв об Исполнителе. Отзывы пользователей позволяют алгоритмам нашего приложения рекомендовать заказы лучшим Исполнителям.</p>
            <div className="images-wrapper mt-30">
                <ImageZoomer>
                    <img src={reviewTrigger} alt="review trigger" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={reviewPopup} alt="review popup" />
                </ImageZoomer>
            </div>
        </>
    )
};

export default CustomerReviews;