import { useNavigate } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { DecorIndicator, DecorCircle, MessageLink, PhoneLink, FooterInfo } from './CardComponents';
import useDate from '../../hooks/useDate';
import useAlert from '../../hooks/useAlert';

import './Card.scss';


const NotificationCard = ({...props}) => {
    const {orderNumber, orderDateTime, notificationType, notificationStatus, notificationCreatedDate, customerName, workerName, notificationReadDate, confirmNotification, rejectNotification} = props;
    const navigate = useNavigate();
    const {formatDate} = useDate();
    const {showAlertRequire} = useAlert();

    const createMessage = (notificationType) => {
        let message = '';

        switch (notificationType) {
            case 'Поиск исполнителя':
                message = 'Ваш заказ размещен в поиск.';
                break;
            case 'Назначен исполнитель':
                message = `На Ваш Заказ назначен исполнитель${workerName ? ' ' + workerName : ''}.`;
                break;
            case 'Исполнитель приехал':
                message = `По Вашему заказу приехал Исполнитель${workerName ? ' ' + workerName : ''}. Встречайте.`;
                break;
            case 'Исполнитель работает':
                message = `Исполнитель ${workerName ? workerName + ' ' : ''}приступил к работе. Пожалуйста, подтвердите.`;
                break;
            case 'Исполнитель работает подтверждено':
                message = `Заказчик ${customerName ? customerName + ' ' : ''}подтвердил начало выполнения заказа.`;
                break;
            case 'Исполнитель работает отклонено':
                message = `Заказчик ${customerName ? customerName + ' ' : ''}отклонил начало выполнения заказа.`;
            break;
            case 'Заказ выполнен':
                message = `Исполнитель ${workerName ? workerName + ' ' : ''}выполнил заказ. Пожалуйста, подтвердите.`;
                break;
            case 'Заказ выполнен подтверждено':
                message = `Заказчик ${customerName ? customerName + ' ' : ''}подтвердил выполнение заказа.`;
                break;
            case 'Заказ выполнен отклонено':
                message = `Заказчик ${customerName ? customerName + ' ' : ''}отклонил выполнение заказа.`;
            break;
            case 'Исполнитель отказался':
                message = `К сожалению, Исполнитель ${workerName ? workerName + ' ' : ''}отказался от выполнения Вашего Заказа. Мы уже начали поиск другого Исполнителя.`;
                break;
            case 'Заказ закрыт':
                message = `К сожалению, Заказчик ${customerName ? customerName + ' ' : ''}закрыл заказ. Найдите новый заказ в нашем поиске.`;
                break;
            case 'Внесены изменения в заказ':
                message = `Заказчик ${customerName ? customerName + ' ' : ''}внес изменения в заказ. Пожалуйста, подтвердите.`;
                break;
            case 'Изменения приняты исполнителем':
                message = `Исполнитель ${workerName ? workerName + ' ' : ''}принял изменения к заказу.`;
                break;
            case 'Изменения отклонены исполнителем':
                message = `Исполнитель ${workerName ? workerName + ' ' : ''}не принял изменения к заказу. Мы начали поиск нового Исполнителя.`;
                break;
            default:
                break;
        };

        return message;
    };


    const onClickBtn = (e) => {
        const btnType = e.target.getAttribute('data-type');
        const alertMessage = btnType === 'confirm' ? 'Желаете подтвердить?' : 'Желаете отклонить?'

        showAlertRequire(alertMessage, 'ok-cancel')
            .then(async () => {
                if (btnType === 'confirm') {
                    await confirmNotification(notificationType, orderNumber);
                };
                if (btnType === 'cancel') {
                    await rejectNotification(notificationType, orderNumber);
                };
            })
            .catch(() => {});
    };


    return (
        <div className="card">
            <div className="card__header">
                <div className="card__order-number" onClick={() => navigate(`/customer-orders/${orderNumber}`)}>
                    Заказ №{orderNumber}
                </div>
                <div className="card__order-time">
                    {formatDate(notificationCreatedDate)}
                </div>
                <DecorIndicator notificationReadDate={notificationReadDate}/>
            </div>
            <div className="card__content">
                <div className="card__content__item">
                    <div className="card__content__item__value">
                        {createMessage(notificationType)}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                    </div>
                </div>
            </div>
            <div className="card__footer">
                <FooterInfo notificationStatus={notificationStatus}>
                    {notificationStatus === 'Запрошено подтверждение'
                        ?   <>
                                <button 
                                    className='btn btn--secondary btn--red'
                                    data-type='cancel'
                                    onClick={onClickBtn}
                                >Отклонить</button>
                                <button 
                                    className='btn btn--secondary btn--green'
                                    data-type='confirm'
                                    onClick={onClickBtn}
                                >Подтвердить</button>
                            </>
                        : notificationStatus}
                    
                </FooterInfo>
                <div className="card__links">
                    <IconContext.Provider value={{className: 'card__links__icon'}}>
                        {/* <MessageLink/> */}
                        {/* <PhoneLink/> */}
                    </IconContext.Provider>
                </div>
            </div>
        </div>
    );
};

export default NotificationCard;