import ImageZoomer from "../../../../components/imageZoomer/ImageZoomer";
import specializationsMenuTrigger from '../../../../assets/images/manual/worker-specializations/specializations-menu-trigger.png';
import specializationsEmptyList from '../../../../assets/images/manual/worker-specializations/specializations-empty-list.png';
import specializationsFullList from '../../../../assets/images/manual/worker-specializations/specializations-full-list.png';

const WorkerSpecializations = () => {
    return (
        <>
            <h1>Выбор специальности</h1>
            <div className="mt-20">
                Чтобы каждый работник занимался своим делом, в нашем приложении реализована привязка работника к конкретным, освоенным им, рабочим специальностям. При этом, Вы можете обучиться новым специальностям и расширить спектр выполняемых работ. Для того чтобы просмотреть список Ваших специальностей и добавить новые, зайдите в соответствующий раздел главного меню.
                <div className="images-wrapper mt-20">
                    <ImageZoomer>
                        <img src={specializationsMenuTrigger} alt="specializations menu trigger" />
                    </ImageZoomer>
                    <ImageZoomer>
                        <img src={specializationsEmptyList} alt="specializations empty list" />
                    </ImageZoomer>
                    <ImageZoomer>
                        <img src={specializationsFullList} alt="specializations full list" />
                    </ImageZoomer>
                </div>
            </div>
        
        </>
    )
};

export default WorkerSpecializations;