import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { AccordionBlock, AccordionHeader, AccordionContent } from '../../accordion/Accordion';
import MenuSide, {MenuSideHeader, MenuSideContent} from './MenuSide';

export default function MenuSideManual({isActive, setIsActive}) {
    const [isMenuActive, setIsMenuActive] = useState(isActive);

    return (
        <MenuSide btnLogOut={false} isActive={isActive} additionalClasses={{main:'top-auto', content:'mt-0'}}>
            <MenuSideHeader>
                <div 
                    className="menu-side__header__btn-close text-end fs-36 color-orange4"
                    onClick={() => setIsActive(state => !state)}>&times;</div>
            </MenuSideHeader>
            <MenuSideContent>
                <div className="menu-side__links">
                    <AccordionBlock>
                        <AccordionHeader>
                            <div className="menu-side__links__item">
                                <p className="menu-side__links__item__text">
                                    Установка приложения
                                </p>
                                <div className='accordion__arrow'>
                                    <IoIosArrowDown />
                                </div>
                            </div>
                        </AccordionHeader>
                        <AccordionContent>
                            <NavLink 
                                to={{
                                    pathname: '/manual/installation/ios',
                                    // search: new URLSearchParams({device: 'iphone'}).toString()
                                }}
                                className="menu-side__links__item">
                                <p className="menu-side__links__item__text">
                                    Установка на IOS
                                </p>
                            </NavLink>

                            <NavLink 
                                to={{
                                    pathname: '/manual/installation/android',
                                    // search: new URLSearchParams({device: 'android'}).toString()
                                }}
                                className="menu-side__links__item">
                                <p className="menu-side__links__item__text">
                                    Установка на Android
                                </p>
                            </NavLink>
                        </AccordionContent>
                    </AccordionBlock>

                    <AccordionBlock>
                        <AccordionHeader>
                            <div className="menu-side__links__item">
                                <p className="menu-side__links__item__text">
                                    Для Заказчика
                                </p>
                                <div className='accordion__arrow'>
                                    <IoIosArrowDown />
                                </div>
                            </div>
                        </AccordionHeader>
                        <AccordionContent>
                            <NavLink to='/manual/quick-start/customer' className="menu-side__links__item">
                                <p className="menu-side__links__item__text">
                                    Быстрый старт
                                </p>
                            </NavLink>
                            
                            <NavLink to='/manual/order-creation' className="menu-side__links__item">
                                <p className="menu-side__links__item__text">
                                    Создание заказа
                                </p>
                            </NavLink>
                            
                            <NavLink to='/manual/order-management' className="menu-side__links__item">
                                <p className="menu-side__links__item__text">
                                    Контроль выполнения заказа
                                </p>
                            </NavLink>
                            
                            <NavLink to='/manual/chat' className="menu-side__links__item">
                                <p className="menu-side__links__item__text">
                                    Чат с Исполнителем
                                </p>
                            </NavLink>
                            
                            <NavLink to='/manual/service-estimation' className="menu-side__links__item">
                                <p className="menu-side__links__item__text">
                                    Оценка оказанных услуг
                                </p>
                            </NavLink>
                        </AccordionContent>
                    </AccordionBlock>

                    <AccordionBlock>
                        <AccordionHeader>
                            <div className="menu-side__links__item">
                                <p className="menu-side__links__item__text">
                                    Для Исполнителя
                                </p>
                                <div className='accordion__arrow'>
                                    <IoIosArrowDown />
                                </div>
                            </div>
                        </AccordionHeader>
                        <AccordionContent>
                            <NavLink to='/manual/quick-start/worker' className="menu-side__links__item">
                                <p className="menu-side__links__item__text">
                                    Быстрый старт
                                </p>
                            </NavLink>

                            <NavLink to='/manual/order-searching' className="menu-side__links__item">
                                <p className="menu-side__links__item__text">
                                    Поиск заказа
                                </p>
                            </NavLink>

                            <NavLink to='/manual/order-execution' className="menu-side__links__item">
                                <p className="menu-side__links__item__text">
                                    Выполнение заказа
                                </p>
                            </NavLink>

                            <NavLink to='/manual/worker-specializations' className="menu-side__links__item">
                                <p className="menu-side__links__item__text">
                                    Выбор специальностей
                                </p>
                            </NavLink>

                            <NavLink to='/manual/worker-rating' className="menu-side__links__item">
                                <p className="menu-side__links__item__text">
                                    Ваш рейтинг
                                </p>
                            </NavLink>
                        </AccordionContent>
                    </AccordionBlock>
                    
                    <NavLink to='/manual/faq' className="menu-side__links__item">
                        <p className="menu-side__links__item__text">
                            Вопросы и ответы
                        </p>
                    </NavLink>
                </div>
            </MenuSideContent>
        </MenuSide>
    );
};