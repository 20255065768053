import NotificationCard from "../card/NotificationCard";
import Spinner from '../spinner/Spinner';
import CardEmpty from '../card/CardEmpty';

const NotificationsList = ({notifications, confirmNotification, rejectNotification, filter}) => {
    if (!notifications) {
        return <Spinner additionalClasses='h-full w-full'/>
    };

    if (notifications.length < 1) {
        let title,
            description;
        if (filter === 'requireConfirmation') {
            title = 'Список уведомлений пуст';
            description = 'Здесь будут храниться Ваши уведомления, требующие подтверждения';
        };
        if (filter === 'all') {
            title = 'Список уведомлений пуст';
            description = 'Здесь будут храниться Ваши уведомления';
        };

        return <CardEmpty
                    title={title}
                    description={description}/>
    };
    
    return (
        <section className="mt-24">
            {notifications.map((notification, i) => (
                <NotificationCard
                    key={i}
                    confirmNotification={confirmNotification}
                    rejectNotification={rejectNotification}
                    {...notification}/>
            ))}
        </section>
    );
};

export default NotificationsList;