import { ErrorInput } from '../../../components/error/Error';
import { useEffect } from 'react';
import InputIntLabel from '../../../components/inputs/InputInnerLabel';
import { setPhoneMask } from '../../../utils/inputMask';

const CustomerSettingsAccount = ({user, setSettingsChanged, useFormMethodsAccount}) => {
    const {register, watch, formState:{errors, isValid}} = useFormMethodsAccount;

    const newDataAccount = watch();

    const validateEmail = (mailAddress) => {
        if (mailAddress) {
            let pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (pattern.test(mailAddress)) {
                return true;
            } else {
                return 'Некорректный email'
            };
        };
    };

    const validatePhone = (value) => {
        if (value) {
            const number = value.replace(/\D/g,'');
            return (number.length === 11) || 'Некорректный телефон';
        }
        
    };

    useEffect(() => {
        const isAccountChanged = Object.values(newDataAccount).some(item => !!item);
        setSettingsChanged(isAccountChanged);
    }, [newDataAccount]);

    return (
        <section>
            <form>
                <InputIntLabel
                    useFormMethods={{
                        register: {
                            register,
                            name: 'email',
                            options: {validate: validateEmail}
                        }
                    }}
                    labelText='Email:'
                    type='email'
                    placeholder={user.email}/>
                {errors?.email && <ErrorInput message={errors?.email?.message || 'Ошибка'}/> }
                <InputIntLabel
                    useFormMethods={{
                        register: {
                            register,
                            name: 'phone',
                            options: {validate: validatePhone}
                        }
                    }}
                    labelText='Телефон:'
                    // type='number'
                    mask='phone'
                    placeholder={setPhoneMask(user.phone)}/>
                {errors?.phone && <ErrorInput message={errors?.phone?.message || 'Ошибка'}/> }
                <InputIntLabel
                    useFormMethods={{
                        register: {
                            register,
                            name: 'oldPassword'
                        }
                    }}
                    labelText='Пароль:'
                    type='text'
                    placeholder='Введите старый пароль'/>
                <InputIntLabel
                    useFormMethods={{
                        register: {
                            register,
                            name: 'newPassword'
                        }
                    }}
                    labelText='Пароль:'
                    type='text'
                    placeholder='Введите новый пароль'/>
                {/* <InputIntLabel
                    useFormMethods={{
                        register: {
                            register,
                            name: 'newPasswordCopy',
                        }
                    }}
                    labelText='Пароль:'
                    type='text'
                    placeholder='Повторите новый пароль'/> */}
            </form>
        </section>
    )
}

export default CustomerSettingsAccount;