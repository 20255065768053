import { configureStore } from "@reduxjs/toolkit";
import userReducer from './slices/userSlice';
import menuReducer from './slices/menuSlice';
import popupReducer from './slices/popupSlice';
import alertReducer from './slices/alertSlice';
import unreadDataReducer from './slices/unreadDataSlice';
import { loadUnreadDataMiddleware } from "./slices/unreadDataSlice";

const store = configureStore({
    reducer: {
        user: userReducer,
        menu: menuReducer,
        popup: popupReducer,
        alert: alertReducer,
        unreadData: unreadDataReducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: {
            // Ignore these action types
            ignoredActions: ['alert/openAlert'],

            // // Ignore these field paths in all actions
            // ignoredActionPaths: ['meta.arg', 'payload.timestamp'],

            // // Ignore these paths in the state
            ignoredPaths: ['alert.resolve', 'alert.reject']
        }
    })
    .concat(loadUnreadDataMiddleware)
});

export default store;