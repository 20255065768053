import './CustomInput.scss';
//ОПЦИИ:
// withIntLabel - инпут с лейблом внутри
// withExtLabel - инпут с лейблом снаружи
// withCheckbox - с лейблом и чекбоксом внутри
// withDatepicker - с лейблом и DatePicker внутри
// withPhone - с номером телефона внутри
// withPhoneConfirm - с номером телефона и проверкой номера
// labelText - текст лейбла
// registerWithName - проп применяется для управления инпутом через react-hook-form,
// представляет собой объект {register: register, name: 'email'}
// additionalClasses - дополнительные классы, применяемые для внешнего вида инпута
//

//ПРИМЕР ПРИМЕНЕНИЯ: 
{/* <CustomInput
withIntLabel
labelText='Кастомный инпут'
/> */}



const CustomInput = ({ withIntLabel=null, withExtLabel=null, withCheckbox=null, withDatepicker=null,withPhoneConfirm=null, labelText=null, registerWithName={}, additionalClasses=null, children,...props}) => {
    
    if (withIntLabel) {
        return (
            <div className={`input input--withIntLabel ${additionalClasses ? additionalClasses : ''}`}>
            <label>
                <span>{labelText}</span>
                <input 
                    {...(registerWithName?.register ? registerWithName?.register(registerWithName?.name, registerWithName?.options) : {})} 
                    {...props} />
            </label>
        </div>
        );
    };

    if (withExtLabel) {
        return (
            <div className={`input--withExtLabel ${additionalClasses ? additionalClasses : ''}`}>
                <label>
                    <span>{labelText}</span>
                    <input 
                        className={`input ${additionalClasses ? additionalClasses : ''}`}
                        {...(registerWithName?.register ? registerWithName?.register(registerWithName?.name, registerWithName?.options) : {})} 
                        {...props} />
                </label>
            </div>
        );
    };

    if (withCheckbox) {
        return (
            <label className="input input--withCheckbox">
                <input 
                    type="checkbox"
                    {...props} />
                <span>{labelText}</span>
            </label>
        )
    }
};

export default CustomInput;