import Header from "./Header";
import PopupNewOrder from "../popup/PopupNewOrder";
import Alert from "../alert/Alert";

const HeaderCustomer = ({children, ...props}) => {
    const {setUpdateOrdersList} = props;
    return (
        <Header>
            {children}
            <PopupNewOrder setUpdateOrdersList={setUpdateOrdersList}/>
            <Alert/>
        </Header>
    )
};

export default HeaderCustomer;