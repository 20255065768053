

const StepIndicator = ({registerStep}) => {
    return (
        <div className="step-indicator">
            <div 
                className={`step-indicator__circle ${registerStep === 'step1' ? 'step-indicator__circle--active' : ''}`}>
                1
            </div>
            <div className="step-indicator__line"></div>
            <div 
                className={`step-indicator__circle ${registerStep === 'step2' ? 'step-indicator__circle--active' : ''}`}>
                2
            </div>
        </div>
    );
};

export default StepIndicator;