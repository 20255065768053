import { useState, useEffect } from 'react';
import { IconContext } from 'react-icons';
import {RiSendPlaneFill} from 'react-icons/ri';
import {AiOutlinePaperClip} from 'react-icons/ai';
import FileUploaderNew from '../../../../../fileUploaderNew/FileUploaderNew';
import ImageZoomer from '../../../../../imageZoomer/ImageZoomer';
import './InputPanel.scss';

const InputPanel = ({onSendMessage, textareaRef}) => {
    const [messageText, setMessageText] = useState('');
    const [files, setFiles] = useState([]);
    
    const handleKeyDown = (event) => {
        const textarea = textareaRef.current;
        //Проверим, является ли устройство мобильным
        const isMobile = window.matchMedia('(max-width: 992px)').matches;

        if (textarea.style.height > textarea.scrollHeight) {
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
        textarea.style.height = `${textarea.scrollHeight}px`;

        if (event.key === 'Enter') {
            //Проверяем является ли устройство ПК с ненажатым shift, делаем отправку сообщения
            if (!isMobile && !event.shiftKey) {
                event.preventDefault();
                onSendMessage(messageText, files);
                clearInputPanel();
            } else { //Для всех других случаев, делаем просто перенос строки
                textarea.style.height = `${textarea.scrollHeight}px`;
            }
        }
    };

    const clearInputPanel = () => {
        setMessageText('');
        setFiles([]);
        textareaRef.current.style.height = '';
    };

    const deleteFile = (file) => {
        setFiles(files => {
            return files.filter((item, i) => item !==file );
        })
    };

    const renderFiles = (files) => {
        return files.map((file, index) => {
            return (
                <div className="input-panel__files__item" key={index}>
                    <div 
                        className="input-panel__files__item__btn-delete"
                        onClick={() => deleteFile(file)}>&times;</div>
                    <ImageZoomer>
                    <img  
                        src={URL.createObjectURL(file)} 
                        alt={file.name} />
                    </ImageZoomer>
                </div>
            );
        });
    };

    useEffect(() => {
        if (!messageText) {
            textareaRef.current.style.height = '';
        };
    }, [messageText]);



    return (
        <div className="input-panel">
            {files.length > 0 &&
            <div className="input-panel__files">
                <div className="input-panel__files__wrapper">
                    {renderFiles(files)}
                </div>
            </div>}
            <div className="input-panel__inputs">
                <IconContext.Provider value={{className: 'input-panel__icon'}}>
                    <FileUploaderNew
                        files={files}
                        setFiles={setFiles} 
                        fileTypes={['.jpg', '.jpeg', '.png', '.gif']}
                        filesListView={false}>
                        <AiOutlinePaperClip />
                    </FileUploaderNew>
                    {/* <AiOutlinePaperClip /> */}
                    <textarea 
                        className='input-panel__input'
                        value={messageText}
                        onChange={ e => setMessageText(e.target.value)}
                        ref={textareaRef}
                        onKeyDown={handleKeyDown}/>
                    <div onClick={() => {
                        onSendMessage(messageText, files);
                        clearInputPanel();
                    }}>
                        <RiSendPlaneFill color='#FF9E2D'/>
                    </div>
                    
                </IconContext.Provider>
            </div>
            
        </div>
    );
};

export default InputPanel;