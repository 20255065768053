import { AccordionBlock, AccordionHeader, AccordionContent } from '../../../../components/accordion/Accordion';


import './Faq.scss';

const Faq = () => {
    return (
        <>
            <h1>Вопросы и ответы</h1>
            <div className="faq">
                <AccordionBlock>
                    <AccordionHeader>
                        <div className="faq__question">
                            <div className="faq__question__text">
                                При регистрации, приложение просит подтвердить номер телефона, но смс не приходит
                            </div>
                            <div className="faq__question__icon">
                                <div className="plus"></div>
                            </div>
                        </div>
                    </AccordionHeader>
                    <AccordionContent>
                        <div className='faq__answer'>
                            <span>Ответ:</span>Если Вы выбираете смс-код в качестве подтверждения Вашего номера телефона, но смс не приходит, Вы можете воспользоваться подтверждением телефона через Telegram, этот способ такой же быстрый и удобный.
                            
                             
                        </div>
                    </AccordionContent>
                </AccordionBlock>

                <AccordionBlock>
                    <AccordionHeader>
                        <div className="faq__question">
                            <div className="faq__question__text">
                                Я забыл свой пароль, как мне зайти в приложение?
                            </div>
                            <div className="faq__question__icon">
                                <div className="plus"></div>
                            </div>
                        </div>
                    </AccordionHeader>
                    <AccordionContent>
                        <div className='faq__answer'>
                            <span>Ответ:</span>
                            Нажмите на кнопку "Личный кабинет", затем, на форме авторизации, Вам необходимо нажать на кнопку "Забыли пароль" и воспользоваться диалоговым окном восстановления пароля. Для восстановления, Вы можете использовать номер телефона или email, указанные в Вашем профиле пользователя.
                        </div>
                    </AccordionContent>
                </AccordionBlock>
            </div>
        </>
    );
};

export default Faq;