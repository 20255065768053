import { useState } from "react";

const usePopup = () => {
    const [isPopupActive, setIsPopupActive] = useState(false);

    const showPopup = () => {
        setIsPopupActive(true);
    };

    const hidePopup = () => {
        setIsPopupActive(false);
    };

    return {
        isPopupActive,
        showPopup,
        hidePopup
    };
};

export default usePopup;