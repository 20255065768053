import Header from "./Header";
import Alert from "../alert/Alert";

const HeaderWorker = ({children}) => {
    return (
        <Header>
            {children}
            <Alert/>
        </Header>
    );
};

export default HeaderWorker;