import ImageZoomer from "../../../../components/imageZoomer/ImageZoomer";
import orderSearchingTrigger from '../../../../assets/images/manual/order-searching/new-order-trigger.png';
import orderSearchingModeList from '../../../../assets/images/manual/order-searching/searching-mode-list.png';
import orderSearchingModeMap from '../../../../assets/images/manual/order-searching/searching-mode-map.png';
import orderSearchingFilter from '../../../../assets/images/manual/order-searching/searching-filter.png';
import orderCardTrigger from '../../../../assets/images/manual/order-searching/order-card-trigger.png';
import orderAcceptTrigger from '../../../../assets/images/manual/order-searching/order-accept-trigger.png';
import orderAccepted from '../../../../assets/images/manual/order-searching/order-accepted.png';

const OrderSearching = () => {
    return (
        <>
            <h1>Поиск заказа</h1>
            <p className="mt-20">
                Для того, чтобы найти новый подходящий Вам заказ, нажмите на иконку "Поиск" в панели быстрого доступа. Пользователю доступно 2 варианта отображения - поиск в виде списка или поиск на карте.
            </p>
            <div className="images-wrapper">
                <ImageZoomer>
                    <img src={orderSearchingTrigger} alt="order searching trigger" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={orderSearchingModeList} alt="searching mode - list" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={orderSearchingModeMap} alt="searching mode - map" />
                </ImageZoomer>
            </div>

            <p className="subtitle mt-30">Фильтры для удобного поиска</p>
            <div className="images-wrapper">
                <p className="mt-20">Для того, чтобы поиск подходящего заказа был еще более удобный и быстрый, Вы можете отфильтровать список заказов по заданным Вами параметрам.</p>
                <ImageZoomer>
                    <img src={orderSearchingFilter} alt="order searching filter" />
                </ImageZoomer>
            </div>

            <p className="subtitle mt-30">Прием подходящего заказа</p>
            <p className="mt-20">Для того, чтобы принять подходящий заказ, нужно перейти в карточку заказа и нажать кнопку "Принять заказ"</p>
            <div className="images-wrapper">
                <ImageZoomer>
                    <img src={orderCardTrigger} alt="order card trigger" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={orderAcceptTrigger} alt="order accept trigger" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={orderAccepted} alt="order accepted" />
                </ImageZoomer>
            </div>
            <p className="mt-20">Вот так просто Вы нашли заказ и забронировали за собой его выполнение.</p>
        </>
    );
};

export default OrderSearching;