import './Advantages.scss';
import promoImg from '../../../../assets/images/advantages/promo-img.png';
import builder from '../../../../assets/images/advantages/builder.png';
import interfaceIco from '../../../../assets/images/advantages/interface-ico.svg';
import guideIco from '../../../../assets/images/advantages/guide-ico.svg';
import notificationIco from '../../../../assets/images/advantages/notification-ico.svg';
import growingIco from '../../../../assets/images/advantages/growing-ico.svg';
import ratingIco from '../../../../assets/images/advantages/rating-ico.svg';
import motivationIco from '../../../../assets/images/advantages/motivation-ico.svg';
import tariffIco from '../../../../assets/images/advantages/tariff-ico.svg';


const Advantages = () => {
    return (
        <div className="advantages">
            <div className="container">
                <div className="title">Преимущества нашего решения</div>
                <div className='advantages__intro'>Когда мы разрабатывали наше приложение, мы старались решить следующие проблемы строительной отрасли:</div>
                <div className="advantages__wrapper">
                    <div className="advantages__item">
                        <div className="subtitle color--main">Для заказчика</div>
                        <p>Поиск рабочих должен быть быстрый и удобный</p>
                        <p>Стоимость смены работника должна быть фиксированной и известной заказчику с момента размещения заказа</p>
                        <p>Работники должны быть ответсвенными и иметь требуемую квалификацию</p>
                    </div>
                    <div className="advantages__item">
                        <div className="subtitle color--second">Для работника</div>
                        <p>Поиск заказов должен быть быстрый и удобный</p>
                        <p>Выполненнная работа должна быть всегда оплачена</p>
                        <p>Работник должен иметь возможность выбрать удобный для него заказ</p>
                        <p>Взаимодействие между заказчиком и работником должно проходить максимально комфортно, с фиксацией всех этапов выполнения заказа</p>
                    </div>
                </div>
                <div className="advantages__instruments">
                    <div className="advantages__promo">
                        <div className="advantages__promo__circle1">
                            <div className="advantages__promo__circle2">
                                <div className="advantages__promo__title">Мы добавили в приложение такие инструменты:</div>
                                <img className="advantages__promo__img" src={promoImg} alt="smart-house"/>
                            </div>
                        </div>
                        <img className="advantages__promo__builder" src={builder} alt='advantages-bulder'></img>
                    </div>
                    <div className="advantages__instruments__item">
                        <div className="advantages__instruments__item__icon-wrapper">
                            <img src={interfaceIco} alt="interface-ico" className="advantages__instruments__item__icon" />
                        </div>
                        <div className="advantages__instruments__item__descr">
                            <span>Интуитивно-понятный</span> интерфейс
                        </div>
                    </div>
                    <div className="advantages__instruments__item">
                        <div className="advantages__instruments__item__icon-wrapper">
                            <img src={guideIco} alt="guide-ico" className="advantages__instruments__item__icon" />
                        </div>
                        <div className="advantages__instruments__item__descr">
                            Удобный <span>справочник строительных специальностей</span> и квалификаций
                        </div>
                    </div>
                    <div className="advantages__instruments__item">
                        <div className="advantages__instruments__item__icon-wrapper">
                            <img src={notificationIco} alt="notification-ico" className="advantages__instruments__item__icon" />
                        </div>
                        <div className="advantages__instruments__item__descr">
                            <span>Система уведомлений</span>, позволяющая отследить все этапы выполнения заказа
                        </div>
                    </div>
                    <div className="advantages__instruments__item">
                        <div className="advantages__instruments__item__icon-wrapper">
                            <img src={growingIco} alt="growing-ico" className="advantages__instruments__item__icon" />
                        </div>
                        <div className="advantages__instruments__item__descr">
                            <span>Система профессионального роста</span> для ответственных работников
                        </div>
                    </div>
                    <div className="advantages__instruments__item">
                        <div className="advantages__instruments__item__icon-wrapper">
                            <img src={ratingIco} alt="rating-ico" className="advantages__instruments__item__icon" />
                        </div>
                        <div className="advantages__instruments__item__descr">
                            <span>Система оценок и рейтинга</span> как для заказчика, так и для работника
                        </div>
                    </div>
                    <div className="advantages__instruments__item">
                        <div className="advantages__instruments__item__icon-wrapper">
                            <img src={motivationIco} alt="motivation-ico" className="advantages__instruments__item__icon" />
                        </div>
                        <div className="advantages__instruments__item__descr">
                            <span>Система поощрения</span> ответственных заказчиков и работников и дисквалификации недобросовестных 
                        </div>
                    </div>
                    <div className="advantages__instruments__item">
                        <div className="advantages__instruments__item__icon-wrapper">
                            <img src={tariffIco} alt="tariff-ico" className="advantages__instruments__item__icon" />
                        </div>
                        <div className="advantages__instruments__item__descr">
                            <span>Тарифная сетка</span>, привязанная к специальности и квалификации работника
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Advantages;