//Компонент FileUploaderNew построен по следующему принципу:
//Есть блок file-uploader-new, внутри label, внтури которого скрытый input[type=file] и какой-то элекмент, который отвечает за отображение - текст, или иконка..., элемент, отвечающий за отображение должен быть передан в качестве children.
//При загрузке файлов в инпут, мы запускаем метод handleFileChange, который переберет все файлы и поместит их во внешний стейт files, который передан как проп, заодно выполнив проверку на уже существование файла


import { useEffect, useState } from "react";
import { ErrorInput } from "../error/Error";
import { useRef } from "react";
import './FileUploaderNew.scss';

const FileUploaderNew = ({files, setFiles, fileTypes, maxFilesQty=10, maxFileSize='5Mb', filesListView=true, children}) => {
    const [maxFileSizeInBytes, setMaxFileSizeInBytes] = useState();
    const [error, setError] = useState('');
    const inputRef = useRef();

    const modifyFileSize = (fileSize) => {
        const size = parseFloat(fileSize);
        const unit = fileSize.replace(size,'').trim().toLowerCase();
        let multiplier = 1;

        switch(unit) {
            case 'kb':
                multiplier = 1024;
                break;
            case 'mb':
                multiplier = 1024 * 1024;
                break;
            case 'gb':
                multiplier = 1024 * 1024 *1024;
                break;
        };

        const sizeInBytes = size * multiplier;
        return sizeInBytes;
    };

    useEffect(() => {
        //Если состав файлов изменился, отправим все файлы в локальный стейт внешнего компонента
        // exportFiles(files);
        if (maxFilesQty) {
            if (files.length >= maxFilesQty && !error) {
                setError(`Достигнуто максимальное количество файлов (${maxFilesQty} шт.)`);
                setTimeout(() => setError(''), 2000);
            } 
            if (files.length < maxFilesQty && error && !error.includes('Максимальный размер загружаемого файла')) setError('');
        };
        inputRef.current.value = null; //очиcтим инпут после изменения списка файлов, в противном случае после удаления файла из стейта, он все-равно хранится в инпуте и отсутствует возможность повторной загрузки удаленного файла
    }, [files]);

    useEffect(() => {
        setMaxFileSizeInBytes(modifyFileSize(maxFileSize));
    },[]);

    //Ф-я handleFileChange примет объект события, и методом перебора массива файлов, добавит каждый из файлов (кроме тех, которые уже есть) в локальный стейт files
    const handleFileChange = (e) => {
        console.log(e.target.files)
        const newFiles = [...e.target.files].filter(file => {
            if (file.size > maxFileSizeInBytes) {
                setError(`Максимальный размер загружаемого файла ${maxFileSizeInBytes/1024/1024} Mb.`);
                setTimeout(() => setError(''), 3000);
                return false;
            };
            return !files.some(item => item.name === file.name && item.size === file.size);
        }).slice(0, maxFilesQty - files.length);
        setFiles(files => [...files, ...newFiles]);
    };

    //Ф-я handleDeleteFile примет файл, отфильтрует массив файлов по поученному файлу и изменит стейт setKeyInput, для того чтобы вызвать перерендер компонента, т.к. при фильтрации массива, перерендер не стартует, т.к. ссылка на массив остается прежней
    const handleDeleteFile = (file) => {
        const newFiles = files.filter(item => item !== file);
        setFiles(newFiles);
    };


    return (
        <div className="file-uploader-new">
            <label>
                {children}
                <input 
                    type="file" 
                    multiple
                    accept={fileTypes}
                    onChange={handleFileChange}
                    ref={inputRef}/>
            </label>

            {filesListView && files.length > 0 ? (
                <ul className="file-uploader-new__files-list">
                    {files.map((file, index) => (
                        <li className="file-uploader-new__files-list__item" key={index}>
                            {file.name}
                            <div 
                                className="file-uploader-new__btn-delete"
                                onClick={() => handleDeleteFile(file)}>&times;</div>
                        </li>
                    ))}
                </ul>
            ) : null}
            {error && <ErrorInput message={error}/>}
        </div>
    );
};

export default FileUploaderNew;