import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {useForm} from 'react-hook-form'
import InputPhoneWithConfirm from '../../components/inputs/InputPhoneWithConfirm';
import RecoveryModePhone from "./components/RecoveryModePhone";
import RecoveryModeEmail from "./components/RecoveryModeEmail";
import './RecoveryPage.scss';

import logo from '../../../src/assets/images/logo/logo_color.svg';

const RecoveryPage = () => {
    const [option, setOption] = useState('phone');
    const navigate = useNavigate();
    const {register, handleSubmit, formState:{errors, isValid}, reset, watch, setValue, setError, clearErrors} = useForm({mode: "onBlur"});

    const phoneInputValue = watch('phone');

    const changeOption = (e) => {
        setOption(e.target.value);
    };

    const validatePhone = (value) => {
        const number = value.replace(/\D/g,'');
        
        return (number.length === 11) || 'Некорректный телефон';
    };

    return (
        <div className="recovery-page">
            <div className="container">
                <div className="recovery-page__logo" onClick={() => navigate('/')}>
                    <img src={logo} alt="logo" />
                </div>
                <div className="recovery-page__subtitle">
                    Платформа для эффективной работы
                </div>

                <div className="form">
                    <div className="form__title">ВОССТАНОВЛЕНИЕ ПАРОЛЯ</div>
                    <div className="recovery-page__options">
                        <label className="recovery-page__options__item">
                            <input type="radio" onChange={changeOption} value='phone' checked={option === 'phone'}/>
                            <p>По телефону</p>
                        </label>
                        <label className="recovery-page__options__item">
                            <input type="radio" onChange={changeOption} value='email' checked={option === 'email'}/>
                            <p>По email</p>
                        </label>
                    </div>

                    {/* <form >
                        <InputPhoneWithConfirm
                            additionalWrapperClasses='mt-20'
                            additionalInputClasses={ `w-140`}
                            errorPhone={errors?.phone}
                            errorPhoneConfirmation={errors?.isPhoneApproved}
                            phoneInputValue={phoneInputValue}
                            setValue={setValue}
                            clearErrors={clearErrors}
                            confirmOptions={{callConfirm: true, telegramConfirm: false}}
                            {...register('phone',{
                                validate: validatePhone
                            })}
                        />
                        <div className="buttons-group mt-40">
                            <button
                                type="button" 
                                className="btn btn--primary btn--outline m-center p-5 h-40 w-100 ls-2"
                                onClick={() => navigate('/login')}>Отменить</button>
                            <button 
                                className="btn btn--primary btn--orange m-center p-5 h-40 w-210 ls-2"
                                onClick={handleSubmit((data) => console.log(data))} >Продолжить</button>
                        </div>
                    </form> */}
                    {option === 'phone' && <RecoveryModePhone/>}
                    {option === 'email' && <RecoveryModeEmail/>}
                    
                </div>
                
            </div>
        </div>
    );
};

export default RecoveryPage;