import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import Spinner from '../spinner/Spinner';
import { ErrorInput } from '../error/Error';
import Popup from "./Popup";



const PopupNewSpecialization = ({isActive, categories, specializations, togglePopup, createSpecialization, updateSpecializations}) => {

    const {register, handleSubmit, formState: {errors, isValid}, reset, watch, setValue} = useForm({mode: 'onBlur'});
    const category = watch('category');
    // const specialization = watch('specialization');

    const filteredSpecializations = useMemo(() => {
        if (category) {
            const filteredSpecializations = specializations.filter(item => item.categoryId === category.id);
            
            return filteredSpecializations;
            
        } else {
            return [];
        }
    },[category]);

    const handleCategoryChange = (e) => {
        setValue('category', e.target.value);
        setValue('specialization', '');
    };

    const handleClose = () => {
        togglePopup();
        reset();
    };

    const onSubmit = async (data) => {
        await createSpecialization(data);
        updateSpecializations();
        handleClose();
    };

    return (
        <Popup
            isActive={isActive}>
            
            <div className="popup__close" onClick={handleClose}>&times;</div>
            <div className="popup__header">
                <div className="popup__title">
                    Добавить специальность
                </div>
            </div>
            {categories.length > 0 && specializations.length > 0
            ? <div className="popup__content">
                <form >
                    <select 
                        className='input input--grey'
                        {...register('category', {
                            required: 'Выберите категорию',
                            setValueAs: (value) => categories.find(item => item.name === value)
                        })}
                        onChange={handleCategoryChange}>
                        <option value="">Выберите категорию</option>
                        {categories.map((categorie, i) => (
                           <option key={i} value={categorie.name}>{categorie.name}</option> 
                        ))}
                    </select>
                    {(errors?.category && !category) && <ErrorInput message={errors.category?.message || 'Ошибка'}/>}
                    <select 
                        className='input input--grey'
                        {...register('specialization', {
                            required: 'Выберите специальность',
                            setValueAs: (value) => specializations.find(item => item.name === value)
                        })}>
                        <option value="">Выберите специальность</option>
                        {category?.id && filteredSpecializations.map((specialization, i) => (
                            <option key={i} value={specialization.name}>{specialization.name}</option>
                        ))}
                    </select>
                    {errors?.specialization && <ErrorInput message={errors.specialization?.message || 'Ошибка'}/>}
                </form>
            </div>
            : <Spinner additionalClasses='mt-30'/>}
            <div className="popup__footer">
                <button 
                    className='btn btn--primary btn--orange m-center mt-30'
                    onClick={handleSubmit(onSubmit)}>
                    Добавить
                </button>
            </div>
        </Popup>
    )
};

export default PopupNewSpecialization;