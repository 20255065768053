import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import useAuth from "../../hooks/useAuth";
import { ErrorInput } from "../../components/error/Error";
import Alert from "../../components/alert/Alert";
import CustomCheckbox from "../../components/customCheckbox/CustomCheckbox";
import './LoginPage.scss';

import logo from '../../../src/assets/images/logo/logo_color.svg';


const LoginPage = () => {
    const navigate = useNavigate();
    const {logIn} = useAuth();

    const {register, handleSubmit, formState:{errors, isValid}} = useForm({
        mode: 'onBlur',
        defaultValues: {
            rememberUser: true
        }
    });
    

    const validatePhone = (value) => {
        if (value) {
            const number = value.replace(/\D/g,'');
            return (number.length === 11) || 'Некорректный телефон';
        }
        
    };

    return (
        <>
            {/* <Header>
                <Link to='/'>
                    <p className="home-header-logo">Real Worker</p>
                </Link>
            </Header> */}
          
            <div className="login-page">
                <div className="container">
                    <div className="login-page__logo">
                        <Link to='/'>
                            {/* <p className="login-page__title">Real Worker</p> */}
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>
                    <div className="login-page__subtitle">
                        Платформа для эффективной работы
                    </div>
                    <form className="form" onSubmit={handleSubmit(logIn)}>
                        <div className="form__title">ВХОД</div>

                        <div className="input-group">
                            <label className="input__label">
                                Телефон 
                                <ReactInputMask 
                                    className="input input--grey mt-5"
                                    mask="+7(999)999-99-99" 
                                    type="tel"
                                    placeholder="+7(XXX)XXX-XX-XX"
                                    // autoComplete="new-password"
                                    {...register('phone', {
                                                                validate: validatePhone
                                                            })}
                                />
                            </label>
                        </div>
                        {errors?.phone && <ErrorInput message={errors?.phone?.message || 'Ошибка'}/> }

                        <div className="input-group">
                            <label className="input__label">
                                Пароль 
                                <input
                                    className="input input--grey mt-5" 
                                    type="password"
                                    autoComplete="new-password"
                                    {...register('password', {required: 'Введите пароль'})} />
                            </label>
                            {errors?.password && <ErrorInput message={errors?.password?.message || 'Ошибка'}/> }
                        </div>

                        {/* <div className="checkbox-group">
                            <input
                                className="checkbox" 
                                type="checkbox"
                                
                                {...register('rememberUser')} />
                            <label className="checkbox__label" >Запомнить меня</label>
                        </div> */}
                        <CustomCheckbox 
                            labelText={'Запомнить меня'}
                            additionalClasses={'mt-20'}
                            useFormMethods={{
                                register: {
                                    register,
                                    name: 'rememberUser'
                                }
                            }}/>
                        <button
                            className={`btn btn--primary btn--orange m-center mt-30 h-40 w-210 ls-2 ${!isValid ? 'btn--disabled' : ''}`}
                            type="submit" 
                            disabled={!isValid}
                        >Войти</button>
                        <button
                            type="button" 
                            className="btn btn--primary btn--outline m-center mt-30 h-40 w-210 ls-2"
                            onClick={() => navigate('/register')}>Регистрация</button>
                        <div className="form__line"></div>
                        <button 
                            className="btn btn--primary btn--transparent m-center mt-30 h-40 w-210 ls-2"
                            onClick={() => navigate('/recovery')}>Забыли пароль?</button>
                    </form>
                </div>
            </div>
            <Alert/>                                                
        </>
    )
};

export default LoginPage;