import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openPopupNewOrder } from "../../../store/slices/popupSlice";
import MenuQuick from "./MenuQuick";


const MenuQuickCustomer = ({...props}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    return (
        <MenuQuick
            {...props}
            role='customer'
            handleClickBtn1={() => navigate('/customer-orders')}
            handleClickBtn2={() => navigate('/customer-messages')}
            handleClickBtn3={() => dispatch(openPopupNewOrder())}
            handleClickBtn4={() => navigate('/customer-notifications')}
            handleClickBtn5={() => navigate('/customer-settings')}/>
    );
};

export default MenuQuickCustomer;