import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   newOrder: false
};

const popupSlice = createSlice({
    name: 'popup',
    initialState,
    reducers: {
        openPopupNewOrder: (state) => {
            state.newOrder = true;
        },
        closePopupNewOrder: (state) => {
            state.newOrder = false;
        }
    }
});

const {actions, reducer} = popupSlice;

export const {openPopupNewOrder, closePopupNewOrder} = actions;
export default reducer;