import './UnredBadge.scss';

export default function UnreadBadge({type='indicator', unreadNumber}) {
    
    const createStyles = (unreadNumber) => {
        let classes = 'unread-badge';
        if (!unreadNumber || unreadNumber === 0) {
            return classes;
        }
        if (type === 'counter') {
            classes += ' unread-badge--with-counter';
        }

        classes += ' unread-badge--visible';

        return classes;
    };

    return (
        <div className={createStyles(unreadNumber)}>
            {type === 'counter' && unreadNumber}
        </div>
    );
};