import FilesList from '../../../../../../../filesList/FilesList';
import './Message.scss';

const Message = ({text, dateTime, isOwnMessage=true, files}) => {
    console.log(files)
    // const filesList = Object.values(files);
    const filesList = formateFiles(files);

    function formateFiles (filesObj) {
        const filesArr = [];
        for (let key in filesObj) {
            filesArr.push({url: filesObj[key], alt: key})
        };
        return filesArr;
    };

    const formateDate = (date) => {
        if (!date) {
            return '';
        };
        const tempDate = new Date(date);
        const hours = tempDate.getHours();
        const minutes = tempDate.getMinutes();
        return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
    };

    const styleMessage = (isOwnMessage) => {
        let style = 'message';
        isOwnMessage ? style += ' message--own' : style += ' message--interlocutor';

        return style;
    };

    const formateText = (text) => {
        if (text.includes('\n')) {
            const textParts = text.split('\n');
            return textParts.map((textPart, i) => (
                <p key={i}>
                    {textPart}
                </p>
            ));
        };
        return text;
    };

    return (
        <div className={styleMessage(isOwnMessage)}>
            <div className="message__text">
                {formateText(text)}
            </div>
            {filesList.length > 0 &&
            <div className="message__files">
                <FilesList
                    additionalClasses={'mt-10'}
                    filesList={filesList}/>
            </div>}
            <div className="message__date-time">
                {formateDate(dateTime)}
            </div>
        </div>
    );
};

export default Message;