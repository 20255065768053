import { useState, forwardRef, useEffect } from "react";


const Suggestions = ({suggestions, onClickSuggestion}) => {
    const renderSuggestions = (suggestions) => {
        return suggestions.map((item, i) => {
            return (
                <div className="suggestions__item" key={i} onClick={() => onClickSuggestion(item)}>
                    {item.address}
                </div>)
        });
    };

    if (!suggestions?.length > 0) return null;
    
    return (
        <div className="suggestions">
            {renderSuggestions(suggestions)}
        </div>
    );
};

const InputWithSuggestions = forwardRef(({suggestions, onClickSuggestion, ...props}, ref) => {
    const [isVisibleSuggestions, setIsVisibleSuggestions] = useState(false);
    
    // useEffect(() => {
    //     if (suggestions?.length > 0) setIsVisibleSuggestions(true);

    // }, [suggestions]);

    const handleBlur = (e) => {
        setTimeout(() => {
            setIsVisibleSuggestions(false);
        }, 150);
    };

    const handleFocus = () => {
        setIsVisibleSuggestions(true);
    };

    return (
        <div className="input--with-suggestions">
            <input  {...props} ref={ref} onBlur={handleBlur} onFocus={handleFocus}/>
            
            {isVisibleSuggestions &&
                <Suggestions suggestions={suggestions} onClickSuggestion={onClickSuggestion}/> 
            }
            
        </div>
    );
});

export default InputWithSuggestions;