import { useEffect, useState } from 'react';
import useDate from '../../hooks/useDate';
import EventCard from '../card/EventCard';
import checkOrderStatusToActive from '../../utils/checkOrderStatusToActive';

import './EventsCalendar.scss';

const EventsCalendar = ({orders, additionalClasses}) => {
    const [days, setDays] = useState([]);
    const [events, setEvents] = useState([]);
    const {compareDate} = useDate();

    const getNewDate = (num) => {
        const daysOfWeek = ['Вс','Пн','Вт','Ср','Чт','Пт','Сб'];
        const now = new Date();
        const newDate = new Date();
        newDate.setDate(now.getDate() + num);
        const events = orders ? orders.filter(order => compareDate({date1: order.dateTime, date2: newDate}) && checkOrderStatusToActive(order.orderStatus)) : [];

        return {
            date: newDate,
            dateNumber: newDate.getDate(),
            dayOfWeek: daysOfWeek[newDate.getDay()],
            isEvent: events.length > 0,
            events
        }
    };

    const handleClick = (dayEvents) => {

        if (dayEvents.length > 0) {
            if (events.length > 0) {
                if (dayEvents[0].number === events[0].number) {
                    setEvents([]);
                } else {
                    setEvents(dayEvents);
                }

            } else {
                setEvents(dayEvents);
            }
        }
    };

    const renderEvents = (events) => {
        return (
            <div className="events-calendar__info">
                <div 
                    className="events-calendar__info__close"
                    onClick={() => setEvents([])}>&times;</div>
                {events.map((event, i) => (
                    [
                        i > 0 ? <div className='decor__divider' key={i}></div> : null,
                        <EventCard key={`order-${i}`} order={event}/>
                    ]
                    
                ))}
            </div>
        );
    };

    useEffect(() => {
        const days = [];
        for (let i = 0; i < 30; i++) {
            days.push(getNewDate(i));
        };

        setDays(days);
    },[orders]);

    return (
        <section className={additionalClasses}>
            <div className="events-calendar">
                {days.map((item, i) => {
                    // const isMarked = orders.some(order => compareDate({date1: item.date, date2: order.dateTime}));
                    return (
                        <div 
                            key={i} 
                            className={`events-calendar__item ${item.isEvent ? 'events-calendar__item--marked' : ''}`}
                            onClick={() => handleClick(item.events)}>
                            <p>{item.dateNumber}</p>
                            <p>{item.dayOfWeek}</p>
                            {item.isEvent ? <p className='events-calendar__item__eventsMark'></p> : null }
                        </div>
                    )
                })}
            </div>
            {events.length > 0 ? renderEvents(events) : null}
        </section>
    );
};

export default EventsCalendar;