import { DecorCircle, DecorDots } from "./CardComponents";
import { RatingBadge } from "./CardComponents";

const SpecializationCard = ({specialization, qualification, doneOrders, averageRating}) => {

    return (
        <div className="card card--specialization">
            <div className="card__header">
                <RatingBadge 
                    averageRating={averageRating}
                    doneOrders={doneOrders}/>
            </div>
            <div className="card__content">
                <div className="card__content__item">
                    <div className="card__content__item__param">
                        Специальность
                    </div>
                    <div className="card__content__item__value">
                        {specialization}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                        <DecorDots/>
                    </div>
                </div>
                <div className="card__content__item">
                    <div className="card__content__item__param">
                        Квалификация
                    </div>
                    <div className="card__content__item__value">
                        {qualification}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                        <DecorDots/>
                    </div>
                </div>
                <div className="card__content__item">
                    <div className="card__content__item__param">
                        Выполнено заказов
                    </div>
                    <div className="card__content__item__value">
                        {doneOrders}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default SpecializationCard;