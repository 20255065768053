import { useDispatch } from 'react-redux';
import { openSideMenu } from '../../store/slices/menuSlice';

import './ButtonBurger.scss';

const ButtonBurger = ({handleClick}) => {
    const dispatch = useDispatch();

    return (
        <div 
            className="button-burger"
            onClick={handleClick ? handleClick : () => dispatch(openSideMenu())}>
                <span></span>
                <span></span>
                <span></span>
        </div>
    );
};

export default ButtonBurger;