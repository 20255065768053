import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AiOutlineBulb, AiOutlineMail, AiOutlineQuestionCircle } from 'react-icons/ai';
import { BiSupport } from 'react-icons/bi';
import { GiSuitcase } from 'react-icons/gi';
import { IoSettingsOutline } from 'react-icons/io5';
import { closeSideMenu } from '../../../store/slices/menuSlice';
import useUrl from '../../../hooks/useUrl';
import useUnreadData from '../../../hooks/useUnreadData';
import { openPopupNewOrder } from "../../../store/slices/popupSlice";
import UnreadBadge from '../../unreadBadge/UnreadBadge';

import MenuSide from './MenuSide';


const MenuSideCustomer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {checkUrlContains} = useUrl();
    const {unreadMessages, unreadNotifications} = useUnreadData();

    const iconStyles = {
        customerOrders: checkUrlContains('customer-orders') ? 'menu-side__links__item__icon menu-side__links__item__icon--active' : 'menu-side__links__item__icon',
        customerNotifications: checkUrlContains('customer-notifications') ? 'menu-side__links__item__icon menu-side__links__item__icon--active' : 'menu-side__links__item__icon',
        customerMessages: checkUrlContains('customer-messages') ? 'menu-side__links__item__icon menu-side__links__item__icon--active' : 'menu-side__links__item__icon',
        customerSettings: checkUrlContains('customer-settings') ? 'menu-side__links__item__icon menu-side__links__item__icon--active' : 'menu-side__links__item__icon',
        customerSupport: checkUrlContains('customer-support') ? 'menu-side__links__item__icon menu-side__links__item__icon--active' : 'menu-side__links__item__icon',
        customerAbout: checkUrlContains('customer-about') ? 'menu-side__links__item__icon menu-side__links__item__icon--active' : 'menu-side__links__item__icon',
    };


    return (
        <MenuSide>
            <ul className="menu-side__links">
                <li 
                    className="menu-side__links__item"
                    onClick={() => navigate('/customer-orders')}>
                    <div className={iconStyles.customerOrders}>
                        <GiSuitcase />
                    </div>
                    <p className="menu-side__links__item__text">
                        Мои заказы
                    </p>
                </li>
                <li 
                    className="menu-side__links__item"
                    onClick={() => navigate('/customer-notifications')}>
                    <div className={iconStyles.customerNotifications}>
                        <AiOutlineBulb />
                    </div>
                    <p className="menu-side__links__item__text">
                        Уведомления
                    </p>
                    <UnreadBadge
                        type='counter' 
                        unreadNumber={unreadNotifications}/>
                </li>
                <li 
                    className="menu-side__links__item"
                    onClick={() => navigate('/customer-messages')}>
                    <div className={iconStyles.customerMessages}>
                        <AiOutlineMail />
                    </div>
                    <p className="menu-side__links__item__text">
                        Сообщения
                    </p>
                    <UnreadBadge
                        type='counter' 
                        unreadNumber={unreadMessages}/>
                </li>
                <li 
                    className="menu-side__links__item"
                    onClick={() => navigate('/customer-settings')}>
                    <div className={iconStyles.customerSettings}>
                        <IoSettingsOutline />
                    </div>
                    <p className="menu-side__links__item__text">
                        Настройки
                    </p>
                </li>
                <li 
                    className="menu-side__links__item"
                    onClick={() => navigate('/customer-about')}>
                    <div className={iconStyles.customerAbout}>
                        <AiOutlineQuestionCircle />
                    </div>
                    <p className="menu-side__links__item__text">
                        О платформе
                    </p>
                </li>
                <li 
                    className="menu-side__links__item"
                    onClick={() => navigate('/customer-support')}>
                    <div className={iconStyles.customerSupport}>
                        <BiSupport />
                    </div>
                    <p className="menu-side__links__item__text">
                        Служба поддержки
                    </p>
                </li>
            </ul>
            <button 
                className="btn btn--primary btn--orange mt-30"
                onClick={() => dispatch(openPopupNewOrder())}>
                    Новый заказ
            </button>
        </MenuSide>
    );
};

export default MenuSideCustomer;