import { useState, useEffect } from "react";
import Fuse from "fuse.js";
import useAuth from '../hooks/useAuth';
import useAlert from "./useAlert";
import { fetchQualifications, fetchUserSpecializations, fetchVacantOrders } from "../api/api";

const useVacantOrders = () => {
    const [orders, setOrders] = useState(null);
    const [userSpecializations, setUserSpecializations] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [identialSpecialization, setIdentialSpecialization] = useState(false);
    const [identialQualification, setIdentialQualification] = useState(false);
    // const [specialization, setSpecialization] = useState(null);
    const [qualifications, setQualifications] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState(null);
    const [searchText, setSearchText] = useState('');

    const {authToken} = useAuth();
    const {showAlert} = useAlert();
    
    const filterOrders = (orders) => {
        if (!orders) {
            return null;
        };
        
        let filteredOrders = orders;
        
        if (startDate) {
            filteredOrders = filteredOrders.filter(order => {
                const orderDate = new Date(order.dateTime).getTime();
                const minDate = new Date(startDate).getTime();
                return orderDate > minDate;
            });
        }

        if (endDate) {
            filteredOrders = filteredOrders.filter(order => {
                const orderDate = new Date(order.dateTime).getTime();
                const maxDate = new Date(endDate).getTime();
                return orderDate < maxDate;
            });
        }

        if (identialSpecialization) {
            filteredOrders = filteredOrders.filter(order => {
                const userSpecialization = userSpecializations.find(userSpecialization => userSpecialization.specialization === order.specialization);

                return userSpecialization;
            });
        };

        if (identialQualification) {
            filteredOrders = filteredOrders.filter(order => {
                const userSpecialization = userSpecializations.find(userSpecialization => userSpecialization.specialization === order.specialization && userSpecialization.qualification === order.qualification);

                return userSpecialization;
            });
        };

        if (searchText) {
            const fuse = new Fuse(filteredOrders, {keys: ['number', 'address', 'category', 'specialization', 'qualification'], threshold: 0.3});
            filteredOrders = fuse.search(searchText).map(result => result.item);
        };
        
        return filteredOrders;
    };

    const resetFilters = () => {
        setStartDate(null);
        setEndDate(null);
        setIdentialSpecialization(false);
        setIdentialQualification(false);
    };

    useEffect(() => {
        fetchVacantOrders(authToken)
            .then(orders => {
                const sortedOrders = [...orders].sort((a, b) => b.number - a.number);
                setOrders(sortedOrders);
            })
            .catch(error => {
                console.log(error);
                showAlert(error.message);
            });
        fetchUserSpecializations(authToken)
            .then(userSpecializations => setUserSpecializations(userSpecializations))
            .catch(error => {
                console.log(error);
                showAlert(error.message);
            });
    }, []);

    useEffect(() => {
        setFilteredOrders(filterOrders(orders));
    }, [orders, startDate, endDate, identialSpecialization, identialQualification]);

    useEffect(() => {
        const delay = 1000;
        if (searchText) {
            const delayedFilter = setTimeout(() => {
                setFilteredOrders(filterOrders(orders));
            }, delay);
    
            return () => clearTimeout(delayedFilter);
        } else {
            setFilteredOrders(filterOrders(orders));
        };
        
    }, [searchText]);

    return {
        filteredOrders,
        resetFilters,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        identialSpecialization,
        setIdentialSpecialization,
        identialQualification,
        setIdentialQualification,
        searchText,
        setSearchText
    }
};

export default useVacantOrders;