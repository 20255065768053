import { createOrder, createNotification, updateOrderStatus, updateNotificationStatus, updateOrderWorkerStatus, cancelOrderByWorker } from "../api/api";

const useUserAction = () => {

    const customerCreatedOrder = async (orderData, authToken) => {
        
        try {
            const orderPosted = await createOrder(orderData, authToken);
            const notificationPosted = await createNotification(orderPosted.number, 'Поиск исполнителя', authToken);
        
            return orderPosted;
        } catch(error) {
            throw error;
        };
    };

    const workerAcceptedOrder = async (orderNumber, authToken) => {
        try {
            const orderUpdated = await updateOrderWorkerStatus(orderNumber, authToken);
            const notificationPosted = await createNotification(orderNumber, 'Назначен исполнитель', authToken);

            return orderUpdated;
        } catch (error) {
            throw error;
        };
    };

    const workerArrived = async (orderNumber, authToken) => {
        try {
            const orderUpdated = await updateOrderStatus(orderNumber, 'Исполнитель приехал', authToken);
            const notificationPosted = await createNotification(orderNumber, 'Исполнитель приехал', authToken);

            return orderUpdated;
        } catch (error) {
            throw error;
        }

    };

    const workerStartedWorkingRequest = async (orderNumber, authToken) => {
        try {
            const notificationPosted = await createNotification(orderNumber, 'Исполнитель работает', authToken);

            return notificationPosted;
        } catch (error) {
            throw error;
        }

    };

    const customerStartedWorkingConfirmed = async (orderNumber, authToken) => {
        try {
            const notificationUpdated = await updateNotificationStatus(orderNumber,'Подтверждено', authToken);
            const orderUpdated = await updateOrderStatus(orderNumber, 'Исполнитель работает', authToken);
            const notificationPosted = await createNotification(orderNumber, 'Исполнитель работает подтверждено', authToken);

            return notificationUpdated;
        } catch (error) {
            throw error;
        };
    };

    const customerStartedWorkingRejected = async (orderNumber, authToken) => {
        try {
            const notificationUpdated = await updateNotificationStatus(orderNumber,'Отклонено', authToken);
            const notificationPosted = await createNotification(orderNumber, 'Исполнитель работает отклонено', authToken);

            return notificationUpdated;
        } catch (error) {
            throw error;
        };
    };

    const workerFinishedWorkingRequest = async (orderNumber, authToken) => {
        try {
            const notificationPosted = await createNotification(orderNumber, 'Заказ выполнен', authToken);

            return notificationPosted;
        } catch (error) {
            throw error;
        }
    };

    const customerFinishedWorkingConfirmed = async (orderNumber, authToken) => {
        try {
            const notificationUpdated = await updateNotificationStatus(orderNumber,'Подтверждено', authToken);
            const orderUpdated = await updateOrderStatus(orderNumber, 'Заказ выполнен', authToken);
            const notificationPosted = await createNotification(orderNumber, 'Заказ выполнен подтверждено', authToken);
    
            return notificationUpdated;
        } catch (error) {
            throw error;
        };
    };

    const customerFinishedWorkingRejected = async (orderNumber, authToken) => {
        try {
            const notificationUpdated = await updateNotificationStatus(orderNumber,'Отклонено', authToken);
            const notificationPosted = await createNotification(orderNumber, 'Заказ выполнен отклонено', authToken);

            return notificationUpdated;
        } catch (error) {
            throw error;
        };
    };

    const customerCanceledOrder = async (orderNumber, authToken) => {    
        try {
            const orderUpdated = await updateOrderStatus(orderNumber, 'Заказ закрыт', authToken);
            //Если Исполнитель назначен, формируем и отправляем уведомления
            if (orderUpdated?.worker) {
                const notificationPosted = await createNotification(orderNumber, 'Заказ закрыт', authToken);
                const notificationUpdated = await updateNotificationStatus(orderNumber,'Подтверждение не требуется', authToken);
            }
            
            //Переберем все уведомления со статусом "Запрошено подтверждение" и присвоим им статус "Подтверждение не требуется"
            // const orderNotificationsRequired = await fetchNotificationsRequiredByOrderNumber(orderNumber);
            // for (let i = 0; i < orderNotificationsRequired.length; i++) {
            //     await setNotificationStatusNotRequired(orderNotificationsRequired[i].id);
            // }
            

            return orderUpdated;
        } catch (error) {
            throw error;
        };
    };

    const workerCanceledOrder = async (orderNumber, authToken) => {
        try {
            const notificationPosted1 = await createNotification(orderNumber, 'Исполнитель отказался', authToken);
            // const orderUpdated = await updateOrderStatus(orderNumber, 'Поиск исполнителя', authToken);
            const orderUpdated = await cancelOrderByWorker(orderNumber, authToken);
            const notificationPosted2 = await createNotification(orderNumber, 'Поиск исполнителя', authToken);

            return orderUpdated;
        } catch (error) {
            throw error;
        };
    };

    const customerChangedOrder = async (orderNumber, authToken) => {
        try {
            const notificationPosted = await createNotification(orderNumber, 'Внесены изменения в заказ', authToken);
        } catch (error) {
            throw error;
        };
    };

    const workerChangesConfirmed = async (notificationId, orderNumber, authToken) => {
        try {
            const notificationUpdated = await updateNotificationStatus(orderNumber,'Подтверждено', authToken);
            const notificationPosted = await createNotification(orderNumber, 'Изменения приняты исполнителем', authToken);

            return notificationUpdated;
        } catch (error) {
            throw error;
        };
    };

    const workerChangesRejected = async (notificationId, orderNumber, authToken) => {
        try {
            const notificationUpdated = await updateNotificationStatus(orderNumber,'Отклонено', authToken);
            const notificationPosted1 = await createNotification(orderNumber, 'Изменения отклонены исполнителем', authToken);
            const orderUpdated = await updateOrderStatus(orderNumber, 'Поиск исполнителя', authToken);
            const notificationPosted2 = await createNotification(orderNumber, 'Поиск исполнителя', authToken);

            return orderUpdated;
        } catch (error) {
            throw error;
        };
    };

    return {
        customerCreatedOrder,
        workerAcceptedOrder,
        workerArrived,
        workerStartedWorkingRequest,
        customerStartedWorkingConfirmed,
        customerStartedWorkingRejected,
        workerFinishedWorkingRequest,
        customerFinishedWorkingConfirmed,
        customerFinishedWorkingRejected,
        customerCanceledOrder,
        workerCanceledOrder,
        customerChangedOrder,
        workerChangesConfirmed,
        workerChangesRejected
    }
};

export default useUserAction;