import { useState } from "react";
import UserInfo from "../../components/header/components/UserInfo";
import HeaderWorker from "../../components/header/HeaderWorker";
import ButtonBurger from "../../components/buttonBurger/ButtonBurger";
import MenuSideWorker from "../../components/menu/menuSide/MenuSideWorker";
import MenuQuickWorker from "../../components/menu/menuQuick/MenuQuickWorker";
import Filter from "../../components/filter/Filter";
import NotificationsList from "../../components/list/NotificationsList";
import useNotifications from "../../hooks/useNotifications";

import './WorkerNotificationsPage.scss';


const WorkerNotificationsPage = () => {
    const [filter, setFilter] = useState('all');
    const {filteredNotifications, confirmNotification, rejectNotification} = useNotifications(filter);

    return (
        <>
            <HeaderWorker>
                <div className="header__content">
                    <ButtonBurger />
                    <h1 className="header__title">Уведомления</h1>
                    <UserInfo/>
                </div>
            </HeaderWorker>

            <div className="main__wrapper">
                <MenuSideWorker />
                <main className="main__content">
                    <div className="container container--small">
                        <Filter
                            optionsList={[
                                {name: 'Все', value: 'all'},
                                {name: 'На подтверждение', value: 'requireConfirmation'}
                            ]}
                            option={filter}
                            setOption={setFilter}/>

                        <NotificationsList 
                            notifications={filteredNotifications}
                            confirmNotification={confirmNotification}
                            rejectNotification={rejectNotification}
                            filter={filter}/>
                    </div>
                </main>
            </div>

            <MenuQuickWorker/>
        </>
    );
};

export default WorkerNotificationsPage;