import { useState, cloneElement } from "react";
import './ImageZoomer.scss';



const ImageZoomer = ({children}) => {
    const [isZoomed, setIsZoomed] = useState(false);
    
    const zoomImage = () => {
        if (!isZoomed) setIsZoomed(true);
    };

    const unZoomImage = (e) => {
        if (isZoomed) setIsZoomed(false);
    };

    if (!children) return null; 
    
    const childrenWithProps = cloneElement(children, {
        onClick: zoomImage,
        style: {cursor: 'pointer'} 
    });

    let childrenZoomed = cloneElement(children, {
        style: {width: 'auto', maxWidth: '60vW', maxHeight: '80vH'}
    });

    if (children && children.props['data-url']) {
        childrenZoomed = <img 
                            src={children.props['data-url']} 
                            alt={children.props['data-url']}
                            style={{width: 'auto', maxWidth: '60vW', maxHeight: '80vH'}} />
    };
    

    if (!isZoomed) return childrenWithProps;

    return (
        <>
            {childrenWithProps}
            <div 
                className={`popup ${isZoomed ? 'popup--active' : ''}`}>
                <div className="image-wrapper">
                    {childrenZoomed}
                    <span className="close-trigger" onClick={unZoomImage}>&times;</span>
                </div>
            </div>
        </>
        
    );
};

export default ImageZoomer;