//Способ подключения к серверу - Web Socket
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { openAlert, closeAlert, modifyMessage } from "./alertSlice";
import { setWsToken } from "./userSlice";
import { fetchUnreadData, fetchWsToken } from "../../api/api";

const initialState = {
    unreadMessages: null,
    unreadNotifications: null
};


// Создаем middleware для подключения/отключения сервера SSE
export const loadUnreadDataMiddleware = (storeAPI) => {
    const state = storeAPI.getState();
    const {authToken} = state.user;
    const {isOpen} = state.alert;
    let webSocket = null;

    //Функция setUnreadDataListener подключит к серверу SSE и установит слушателя на получение сообщений, который при получении уведомления от сервера обновит стейт
    const setUnreadDataListener = async (authToken) => {
        
        try {
            const {wsToken} = await fetchWsToken(authToken);
            
            // Запишем wsToken в store для того, чтобы разные компоненты могли подключаться по протоколу websocket (в нашем случае подключаемся для получения количества непрочитанных сообщений/уведомлений и подключаемся в чате)
            storeAPI.dispatch(setWsToken({wsToken}));
            // Подключимся по протоколу websocket к точке, которая сообщает о непрочитанных сообщениях/уведомлениях
            webSocket = new WebSocket(`wss://realworker.ru/ws/notification/${wsToken}/`);
            
            webSocket.onopen = (event) => {
                console.log('Установлено соединение', event);
                const isAlertOpen = storeAPI.getState().alert.isOpen
                if (isAlertOpen) {
                    storeAPI.dispatch(modifyMessage({message: 'Подключение восстановлено'}));
                    setTimeout(() => {
                        storeAPI.dispatch(closeAlert());
                    }, 2000);
                }
            }

            webSocket.onmessage = (event) => {
                const eventData = JSON.parse(event.data);
                const unreadData = eventData?.message;
                console.log('Получено сообщение', eventData);
                if (unreadData?.unreadMessages || unreadData?.unreadMessages === 0 || unreadData?.unreadNotifications || unreadData?.unreadNotifications === 0) {
                    storeAPI.dispatch(setUnreadData(unreadData));
                }
            }
            //Обработаем ошибку в случае проблем с подключением к серверу
            webSocket.onerror = (event) => {
                storeAPI.dispatch(openAlert({message: 'Ошибка подключения к серверу уведомлений. Переподключаемся...'}));
                // webSocket?.close && webSocket?.close(3001, 'Закрыто из-за ошибки подключения');
                setTimeout(() => setUnreadDataListener(authToken), 2000); //Поставим таймер для переподключения webSocket-соединения
            };

            webSocket.onclose = (event) => {
                console.log('Отключаем канал WS', event)
            };

        } catch (error) {
            console.log(error);
            storeAPI.dispatch(openAlert({message: error.message}));
        }
    };

    //Функция removeUnreadDataListener отключит канал с сервером SSE
    const removeUnreadDataListener = () => {
        if (webSocket) {
            webSocket?.close(1000, 'Закрыто по требованию пользователя');
        }
    };

    //Проверим - если при первом запуске токен есть, установим соединение WebSocket
    if (authToken) {
        setUnreadDataListener();
    };

    // Возвращаем следующую функцию middleware для корректной работы в Redux Toolkit 
    return (next) => (action) => {
        // Если authToken изменился, останавливаем или запускаем таймер
        const prevState = storeAPI.getState();
        const prevAuthToken = prevState.user.authToken;
        const result = next(action);
        const currentState = storeAPI.getState();
        const currentAuthToken = currentState.user.authToken;

        if (prevAuthToken !== currentAuthToken) {
            if (currentAuthToken) {
                setUnreadDataListener(currentAuthToken);
                // console.log('Соединение установлено')
            } else {
                removeUnreadDataListener();
                // console.log('Соединение отключено')
            }
        };

        return result;
    };
};

const unreadDataSlice = createSlice({
    name: 'unreadData',
    initialState,
    reducers: {
        setUnreadData: (state, action) => {
            state.unreadMessages = action.payload.unreadMessages;
            state.unreadNotifications = action.payload.unreadNotifications;
        },
        clearUnreadData: (state) => {
            state.unreadMessages = null;
           state.unreadNotifications = null;
        }
    },
    
});

const {reducer, actions} = unreadDataSlice;

export default reducer;
export const {setUnreadData, clearUnreadData } = actions;