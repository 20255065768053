import { useEffect, useState } from "react";
import { IoIosArrowUp } from "react-icons/io";
import './ButtonScrollToTop.scss';

const ButtonScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);
    
    const scrollToTop = () => {
        const scrollY = window.scrollY;

        if (scrollY > 1) {
            window.scrollTo({
                top: scrollY - 30,
                left: 0,
                behavior: 'auto'
            });
            setTimeout(scrollToTop, 10);
        };
    };

    useEffect(() => {
        const toggleVisible = () => {
            const scrollY = window.scrollY;
            if (scrollY >= 300) {
                setIsVisible(true);
            };
            if (scrollY < 300) {
                setIsVisible(false);
            };
        };

        window.addEventListener('scroll', toggleVisible);

        return () => {
            window.removeEventListener('scroll', toggleVisible);
        }
    }, []);

    return (
        <div 
            className={`scroll-btn ${isVisible ? 'scroll-btn--active' : ''}`}
            onClick={scrollToTop}>
            <IoIosArrowUp className="scroll-btn__arrow"/>
        </div>
    )
};

export default ButtonScrollToTop;