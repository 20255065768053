import ReactInputMask from "react-input-mask";
import './Input.scss';

const InputIntLabel = ({labelText, useFormMethods={}, mask=null, ...props}) => {

    const registerProps = useFormMethods?.register?.register 
        ? useFormMethods?.register?.register(useFormMethods?.register?.name, useFormMethods?.register?.options 
            ? useFormMethods?.register?.options 
            : {}) 
        : {};

    return (
        <div className="input">
            <label>
                <span>{labelText}</span>
                {mask === 'phone'
                ? <ReactInputMask 
                    className="input--internal"
                    mask="+7(999)999-99-99" 
                    type="tel"
                    {...(registerProps)}
                    {...props}/>
                : <input 
                    className="input--internal"
                    {...(registerProps)} 
                    {...props} />}
            </label>
        </div>
    );
};

export default InputIntLabel;
