import { GiCheckMark } from "react-icons/gi";
import './CustomCheckbox.scss';

const CustomCheckbox = ({labelText, additionalClasses, useFormMethods, ...props}) => {
    const registerProps = useFormMethods?.register?.register 
        ? useFormMethods?.register?.register(useFormMethods?.register?.name, useFormMethods?.register?.options 
            ? useFormMethods?.register?.options 
            : {}) 
        : {};
    return (
        <div className={`checkbox ${additionalClasses ? additionalClasses : ''}`}>
            <label >
                <input 
                    type="checkbox" 
                    {...registerProps}
                    {...props}
                />
                <p>{labelText}</p>
            </label>
        </div>
    );
};

export default CustomCheckbox;