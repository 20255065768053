import { useState } from "react";

import './Filter.scss';


const Filter = ({optionsList, option, setOption, additionalClasses}) => {
    
    return (
        // <section className={`filter ${position === 'absolute' ? 'filter--absolute' : ''} ${!isVisible ? 'd-none' : ''}`}>
        <section className={`filter ${additionalClasses ? additionalClasses : ''}`}>
            {optionsList.map((item, i) => (
                <div 
                    key={i}
                    className={`filter__item ${option === item.value ? 'filter__item--active' : ''}`}onClick={() => setOption(item.value)}>
                        {item.name}
                </div>
            ))}
        </section>
    );
};

export default Filter;