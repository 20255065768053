import { useNavigate } from "react-router-dom";
import HomeHeader from "../homePage/components/homeHeader/HomeHeader";
import Footer from "../homePage/components/footer/Footer";
import image404 from '../../assets/images/page404/image404.png';
import './NotFoundPage.scss';

const NotFoundPage = () => {
    const navigate = useNavigate();
    
    return (
        <div className="page404">
            <HomeHeader/>
            <main className="main__content">
                <div className="container container--small">
                    <div className="page404__wrapper">
                        <img src={image404} alt="page-not-found" />
                        <h1 className="page404__title">Страница не найдена</h1>
                        <button className="btn" onClick={() => navigate(-1)}>Назад</button>
                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    );
};

export default NotFoundPage;