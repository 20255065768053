import Spinner from "../spinner/Spinner";
import ChatsList from './components/chatsList/ChatsList';
import ChatRoom from "./components/chatRoom/ChatRoom";
import CardEmpty from '../card/CardEmpty';

import './Chat.scss';

const Chat = ({chats, chatSelected, setChatSelected, order, setOrder, orderNumberSelected, setOrderNumberSelected, interlocutor, setInterlocutor, messages, setMessages, messagesListRef, onSendMessage, textareaRef}) => {
    
    if (!chats) {
        return (
            <Spinner/>
        );
    }
    if (chats.length < 1) {
        return (
            <div className="container container--small">
                <CardEmpty
                    title="Список сообщений пуст"
                    description="Здесь будет храниться Ваша переписка"/>
            </div>
            
        );
    }

    return (
        <div className="chat">
            <ChatsList 
                chats={chats} 
                chatSelected={chatSelected} 
                setChatSelected={setChatSelected}
                order={order}
                setOrder={setOrder}
                orderNumberSelected={orderNumberSelected}
                setOrderNumberSelected={setOrderNumberSelected}/>
            <ChatRoom 
                chatSelected={chatSelected} 
                setChatSelected={setChatSelected}
                interlocutor={interlocutor}
                setInterlocutor={setInterlocutor}
                messages={messages}
                setMessages={setMessages}
                messagesListRef={messagesListRef}
                onSendMessage={onSendMessage}
                textareaRef={textareaRef}/>
        </div>
    );
};

export default Chat;