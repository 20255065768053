import { compareData } from "../utils/compareData";

const getData = async (url, headers=null, authToken=null, etalonData=null) => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...(!headers ? {'Content-type': 'application/json'} : headers),
                ...(authToken ? {'Authorization': `Token ${authToken}`} : {})
            }
        });
        
        if (!response.ok) {
            const responseObj = await response.json();
            const responseObjValuesArr = Object.values(responseObj);
            const errorMessage = `${responseObjValuesArr.length > 0 ? responseObjValuesArr[0] : 'Запрос к серверу завершен ошибкой'} Статус: ${response.status}`;
            // const error = new Error(`Could not fetch ${url}. Status: ${response.status}`);
            const error = new Error(errorMessage);
            error.status = response.status;
            error.statusText = response.statusText;

            throw error;
        };

        const data = await response.json();
        
        if (etalonData) {
            if (!compareData(data, etalonData)) {
                const error = new Error(`Не корректная структура данных в ответе сервера: ${url}`);
                error.status = 2;
                error.statusText = 'Не корректная структура данных в ответе сервера'

                throw error;
            };
        };

        return data;

    } catch (error) {
        if (error.status) {
            throw error;
        };

        error.message = `Запрос завершен ошибкой: ${url}. ErrorMessage: ${error.message}`;
        error.status = 1;
        error.statusText = 'Ошибка в запросе fetch';

        throw error;
    };
};

const postData = async (url, body=null, headers=null, authToken=null, etalonData=null) => {
    try {
        const response = await fetch(url, {
            method: 'POST',
            body,
            headers: {
                ...(!headers ? {'Content-type': 'application/json'} : headers === 'auto' ? {} : headers),
                ...(authToken ? {'Authorization': `Token ${authToken}`} : {})
            }
        });
        
        if (!response.ok) {
            const responseObj = await response.json();
            const responseObjValuesArr = Object.values(responseObj);
            const errorMessage = `${responseObjValuesArr.length > 0 ? responseObjValuesArr[0] : 'Запрос к серверу завершен ошибкой'} Статус: ${response.status}`;
            // const error = new Error(`Could not fetch ${url}. Status: ${response.status}`);
            const error = new Error(errorMessage);
            error.status = response.status;
            error.statusText = response.statusText;

            throw error;
        };

        if (response.status === 204) {
            return response?.statusText;
        }

        const data = await response.json();
        
        if (etalonData) {
            if (!compareData(data, etalonData)) {
                const error = new Error(`Не корректная структура данных в ответе сервера: ${url}`);
                error.status = 2;
                error.statusText = 'Не корректная структура данных в ответе сервера'

                throw error;
            };
        };

        return data;
        
    }catch (error) {
        if (error.status) {
            throw error;
        };

        error.message = `Запрос завершен ошибкой: ${url}. ErrorMessage: ${error.message}`;
        error.status = 1;
        error.statusText = 'Ошибка в запросе fetch';

        throw error;
    };
};

const patchData = async (url, body=null, headers=null, authToken=null) => {
    
    try {
        const response = await fetch(url, {
            method: 'PATCH',
            body,
            headers: {
                ...(!headers ? {'Content-type': 'application/json'} : headers === 'auto' ? {} : headers),
                ...(authToken ? {'Authorization': `Token ${authToken}`} : {})
            }
        });
        
        if (!response.ok) {
            const responseObj = await response.json();
            const responseObjValuesArr = Object.values(responseObj);
            const errorMessage = `${responseObjValuesArr.length > 0 ? responseObjValuesArr[0] : 'Запрос к серверу завершен ошибкой'} Статус: ${response.status}`;
            // const error = new Error(`Could not fetch ${url}. Status: ${response.status}`);
            const error = new Error(errorMessage);
            error.status = response.status;
            error.statusText = response.statusText;

            throw error;
        }

        const data = await response.json();

        return data;

    } catch (error) {
        if (error.status) {
            throw error;
        };

        error.message = `Запрос завершен ошибкой: ${url}. ErrorMessage: ${error.message}`;
        error.status = 1;
        error.statusText = 'Ошибка в запросе fetch';

        throw error;
    };
};

const deleteData = async (url, headers={'Content-type': 'application/json'}, authToken=null) => {
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                ...headers,
                ...(authToken ? {'Authorization': `Token ${authToken}`} : {})
            }
        });

        if (!response.ok) {
            const responseObj = await response.json();
            const responseObjValuesArr = Object.values(responseObj);
            const errorMessage = `${responseObjValuesArr.length > 0 ? responseObjValuesArr[0] : 'Запрос к серверу завершен ошибкой'} Статус: ${response.status}`;
            // const error = new Error(`Could not fetch ${url}. Status: ${response.status}`);
            const error = new Error(errorMessage);
            error.status = response.status;
            error.statusText = response.statusText;

            throw error;
        };

        const data = await response.json();

        return data;

    } catch (error) {
        if (error.status) {
            throw error;
        };
        
        error.message = `Запрос завершен ошибкой: ${url}. ErrorMessage: ${error.message}`;
        error.status = 1;
        error.statusText = 'Ошибка в запросе fetch';

        throw error;
    };
};

export {
    getData,
    postData,
    patchData,
    deleteData
};