import { DecorCircle, FooterInfo} from './CardComponents';
import FilesList from '../filesList/FilesList';
import useDate from "../../hooks/useDate";

const SupportCard = ({number, timeCreate, topic, text, status, filesList}) => {
    const {formatDate} = useDate();
    
    return (
        <div className="card">
            <div className="card__header">
                <div className="card__order-number">
                    Заявка №{number}
                </div>
                <div className="card__order-time">
                    {formatDate(timeCreate)}
                </div>
            </div>
            <div className="card__content">
                <div className="card__content__item">
                    <div className="card__content__item__param">
                        Тема обращения
                    </div>
                    <div className="card__content__item__value">
                        {topic}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                    </div>
                </div>
                <div className="card__content__item">
                    <div className="card__content__item__param">
                        Описание проблемы
                    </div>
                    <div className="card__content__item__value">
                        {text}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                    </div>
                </div>
            </div>
            <div className="card__footer">
                <FooterInfo supportStatus={status}>
                    {status}
                </FooterInfo>
                <FilesList filesList={filesList}/>
            </div>
        </div>
    );
};

export default SupportCard;