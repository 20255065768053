//Хук содержит функции, отвечающие за:
// - проверку авторизации пользователя,
// - выполнение авторизации пользователя,
// - выполнение регистрации пользователя

import { useDispatch, useSelector } from "react-redux";
import { setUser, setUserPhoto, removeUser } from "../store/slices/userSlice";
import { closeSideMenu } from "../store/slices/menuSlice";
import useAlert from "./useAlert";
import { createUser, createAuthToken, fetchUserRoleName, removeToken } from "../api/api";

const useAuth = () => {
    const dispatch = useDispatch();
    const {name, role, photo, authToken} = useSelector(state => state.user);
    const {showAlert, showAlertRequire} = useAlert();
  
    const checkLocalToken = () => {
        //Выполним проверку: если в localStorage есть token, запишем токен в глобальный стейт
        
        if (localStorage.getItem('user')) {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user.authToken !== authToken) {
                
                dispatch(setUser(user));
            }
        } //тут проверка завершается
        return true;
    }

    // const checkUser = () => {
        
    //     return {
    //         isAuth: !!authToken,
    //         authToken
    //     };

    // };

    const signUp = async (userData) => {
        
        try {
            const user = await createUser(userData);
            
            await showAlertRequire(`Зарегистрирован пользователь ${user.name}`);
            const authTokenCreated = await createAuthToken(user.username, userData.password);
            localStorage.setItem('user', JSON.stringify({authToken: authTokenCreated.authToken}));
            dispatch(setUser({authToken: authTokenCreated.authToken}));
        } catch(error) {
            console.log(error);
            showAlert(error.message);
        };
    };

    const logIn = async (userData) => {
        
        try {
            const phone = userData.phone.replace(/\D/g,'');
            const password = userData.password;

            const authTokenCreated = await createAuthToken(phone, password);
            const userProfile = await fetchUserRoleName(authTokenCreated.authToken);
            await showAlertRequire(`Добро пожаловать, ${userProfile.name}!`);
            if (userData.rememberUser) localStorage.setItem('user', JSON.stringify({
                name: userProfile.name,
                role: userProfile.role,
                authToken: authTokenCreated.authToken
                
            }));
            dispatch(setUser({
                name: userProfile.name,
                role: userProfile.role,
                authToken: authTokenCreated.authToken
            }));
            if (userProfile?.photo) {
                dispatch(setUserPhoto({ photo: userProfile.photo }));
            }

        } catch(error) {
            console.log(error);
            showAlert(error.message);
        };
    };

    const logOut = () => {
        try {
            dispatch(removeUser());
            dispatch(closeSideMenu());
            localStorage.removeItem('user');
            removeToken(authToken);
        } catch (error) {
            console.log(error);
            showAlert(error.message);
        }
    };

    const clearUser = () => {
        dispatch(removeUser());
        dispatch(closeSideMenu());
        localStorage.removeItem('user');
    };

    const setNewUserPhoto = (newUserPhoto) => {
        if ((!photo && newUserPhoto) || ((photo && newUserPhoto) && (photo !== newUserPhoto))) {
            dispatch(setUserPhoto({photo: newUserPhoto}));
        };
    };

    const refreshToken = () => {

    };


    return {
        isAuth: !!authToken,
        authToken,
        userName: name,
        userRole: role,
        userPhoto: photo,
        checkLocalToken,
        // checkUser,
        signUp,
        logIn,
        logOut,
        clearUser,
        setNewUserPhoto,
        refreshToken 
    }

};

export default useAuth;