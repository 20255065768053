import './Price.scss';
import builder from '../../../../assets/images/price/builder.png';

const Price = () => {
    return (
        <div className='price'>
            <div className="container">
                <div className="title">Стоимость наших услуг</div>
                <div className="price__wrapper">
                    <div className="price__item">
                        <div className="price__item__number">1</div>
                        <div className="price__item__text">
                            Для заказчика услуги нашего сервиса предоставляются совершенно бесплатно. Заказчик оплачивает только услуги работника, без каких-либо комиссий со стороны приложения. Мы очень надеемся, что наш сервис будет вам полезен и рассчитываем на то, что вы будете ответственно подходить к оплате услуг работников.
                        </div>
                    </div>
                    <div className="price__item">
                        <div className="price__item__number">2</div>
                        <div className="price__item__text">
                            Исполнитель оплачивает скромную комиссию, толко тогда, когда принимает понравившийся ему заказ. Комиссия действительно скромная и направлена на поддержание штанов разработчикам данного социально-значимого приложения.
                        </div>
                    </div>
                    <div className="price__item">
                        <div className="price__item__number">3</div>
                        <div className="price__item__text">
                            <span>БОНУС!</span> Мы сделаем первые 3 месяца пользования приложением абсолютно бесплатным. Регистрируйтесь и пользуйтесь с удовольствием.
                        </div>
                    </div>
                    <img className="price__builder" src={builder} alt='price-builder'></img>
                </div>
            </div>
        </div>
    )
};

export default Price;