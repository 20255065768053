
import HeaderWorker from "../../components/header/HeaderWorker";
import ButtonBurger from '../../components/buttonBurger/ButtonBurger';
import MenuSideWorker from "../../components/menu/menuSide/MenuSideWorker";
import MenuQuickWorker from "../../components/menu/menuQuick/MenuQuickWorker";
import Chat from "../../components/chat/Chat";
import UserInfo from "../../components/header/components/UserInfo";
import useAuth from "../../hooks/useAuth";
import useChat from '../../hooks/useChat';


const WorkerMessagesPage = () => {
    const {authToken} = useAuth();
    const {chats, chatSelected, setChatSelected, order, setOrder, orderNumberSelected, setOrderNumberSelected, interlocutor, setInterlocutor, messages, setMessages, messagesListRef, onSendMessage, textareaRef } = useChat(authToken); 
    
    return (
        <>
            <HeaderWorker>
                <div className="header__content">
                    <ButtonBurger />
                    <h1 className="header__title">Мои заказы</h1>
                    <UserInfo/>
                </div>
            </HeaderWorker>

            <div className="main__wrapper">
                <MenuSideWorker />
                <main className='w-full'>
                    <Chat 
                        chats={chats} 
                        chatSelected={chatSelected} 
                        setChatSelected={setChatSelected}
                        order={order}
                        setOrder={setOrder}
                        orderNumberSelected={orderNumberSelected}
                        setOrderNumberSelected={setOrderNumberSelected}
                        interlocutor={interlocutor}
                        setInterlocutor={setInterlocutor}
                        messages={messages}
                        setMessages={setMessages}
                        messagesListRef={messagesListRef}
                        onSendMessage={onSendMessage}
                        textareaRef={textareaRef}/>  
                </main>
            </div>

            <MenuQuickWorker isVisible={!chatSelected}/>
        </>
    ); 
    
};

export default WorkerMessagesPage;