import ImageZoomer from "../../../../components/imageZoomer/ImageZoomer";
import orderStatus from '../../../../assets/images/manual/order-management/orderStatus.png';
import confirmation from '../../../../assets/images/manual/order-management/confirmation.png';
import orderConfirmed from '../../../../assets/images/manual/order-management/orderConfirmed.png';


const OrderManagement = () => {

    return (
        <>
            <h1>Контроль выполнения заказа</h1>
            <p className="mt-30">После того как Вы создали заказ, Вы можете отслеживать его состояние в разделе "Мои заказы". Достаточно отслеживать "Статус заказа" чтобы у Вас было полное представление об исполнении заказа.</p>
            <div className="images-wrapper mt-30">
                <div>
                    <p >Каждый заказ последовательно принимает следующие статусы:</p>
                    <ul className="mt-20">
                        <li className="fw-600">- Поиск исполнителя</li>
                        <li className="fw-600">- Назначен Исполнитель</li>
                        <li className="fw-600">- Исполнитель приехал</li>
                        <li className="fw-600">- Исполнитель работает</li>
                        <li className="fw-600">- Заказ выполнен</li>
                    </ul>
                </div>
                <ImageZoomer>
                    <img src={orderStatus} alt="order status" />
                </ImageZoomer>
            </div>
            <p className="mt-20">Для того, чтобы фиксировать факт начала и завершения работ, статусы <span className="fw-600">"Исполнитель работает"</span> и <span className="fw-600">"Заказ выполнен"</span> присваиваются только после подтверждения со стороны Заказчика.</p>
            <div className="images-wrapper mt-30">
                <p >Для подтверждения, после того как Вы получили соответствующее уведомление, Вам необходимо зайти в "Уведомления" и нажать кнопку "Подтвердить". Если Исполнитель не приступил к работе или не завершил рабочий день, и при этом направил Вам запрос на подтверждение, нажмите "Отклонить" и статус заказа не изменится.</p>
                <ImageZoomer>
                    <img src={confirmation} alt="order status confirmation" />
                </ImageZoomer>
            </div>
            <div className="images-wrapper mt-30">
                <p className="mt-20">Если Заказчик принял решение отменить заказ, то он принимает статус <span className="fw-600">"Заказ отменен"</span> и заказ становится недоступным для Исполнителей.</p>
                <ImageZoomer>
                    <img src={orderConfirmed} alt="order status confirmed" />
                </ImageZoomer>
            </div>
        </>
    )
};

export default OrderManagement;