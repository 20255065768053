export default function checkOrderStatusToActive (orderStatus) {
    let result;

    switch (orderStatus) {
        case 'Назначен исполнитель':
            result = true;
            break;
        case 'Исполнитель приехал':
            result = true;
            break;
        case 'Исполнитель работает':
            result = true;
            break;
        default:
            result = false;
    };

    return result;
};