import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {useForm} from 'react-hook-form';
import InputExtLabel from "../../../components/inputs/InputExtLabel";
import { ErrorInput } from "../../../components/error/Error";
import { validateEmail } from "../../../utils/validate";

const RecoveryModeEmail = () => {
    const [mode, setMode] = useState('step1');
    const navigate = useNavigate();
    const {register, handleSubmit, formState:{errors, isValid}, reset, watch, setValue, setError, clearErrors} = useForm({mode: "onBlur"});

    const onSubmitStep1 = (data) => {
        console.log(data);
        setMode('step2');
    };

    return (
        <form >
            {mode === 'step1' &&
                <>
                    <InputExtLabel
                    labelText='Укажите email'
                    additionalWrapperClasses='mt-20'
                    additionalInputClasses='input--grey'
                    {...register('email', {
                        required: 'Укажите Ваш email',
                        validate: validateEmail})}
                    type='email'
                    autoComplete='new-password'/>
                    {errors?.email && <ErrorInput message={errors?.email?.message || 'Ошибка'}/> }
                    <div className="buttons-group mt-40">
                        <button
                            type="button" 
                            className="btn btn--primary btn--outline m-center p-5 h-40 w-100 ls-2"
                            onClick={() => navigate('/login')}>Отменить</button>
                        <button 
                            className="btn btn--primary btn--orange m-center p-5 h-40 w-210 ls-2"
                            onClick={handleSubmit(onSubmitStep1)} >Продолжить</button>
                    </div>
                </>
            }
            {mode === 'step2' &&
                <div className="mt-20 text-center fs-14 lh-20">
                    На Ваш email отправлена ссылка для сброса пароля. Перейдите по ссылке и следуйте инструкциям.
                </div>
            }
        </form>
    );
};

export default RecoveryModeEmail;