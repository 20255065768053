import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ErrorInput } from "../../../components/error/Error";
import Captcha from "../../../components/captcha/Captcha";
import useAlert from "../../../hooks/useAlert";
import { fetchCities } from "../../../api/api";

import customerLogo from '../../../assets/icons/register/customer-image.png';
import workerLogo from '../../../assets/icons/register/worker-image.png';



const RegisterStep1 = ({setUserData, setRegisterStep}) => {
    const [cities, setCities] = useState([]);
    const [role, setRole] = useState('');
    const [roleError, setRoleError] = useState(false);

    const navigate = useNavigate();
    const {showAlert} = useAlert();

    const {register, handleSubmit, formState:{errors, isValid}, reset} = useForm({mode: "onBlur"});

    const onSubmitForm1 = (data) => {
        if (role) {
            setUserData({...data, role});
            setRoleError(false);
            setRegisterStep('step2');
        } else {
            setRoleError(true);
        }
    }


    useEffect(() => {
        fetchCities()
            .then(data => setCities(data))
            .catch(error => {
                console.log(error);
                showAlert(error.message);
            });
    }, [])

    return (
        <form onSubmit={handleSubmit(onSubmitForm1)}>
            <div className='input-group'>
                <label className="input__label">
                    Имя
                    <input 
                        className={ `input input--grey mt-5 ${errors?.name ? 'input--error' : ''}`}
                        type="text" 
                        {...register('name', {required: 'Укажите Ваше имя'})}/>
                </label>
                {errors?.name && <ErrorInput message={errors.name?.message || 'Ошибка'}/>}
            </div>
            <div className="input-group">
                <label className="input__label">
                    Город
                    <select
                        {...register('city', {
                            required: 'Выберите Ваш город',
                            setValueAs: (value) => cities.find(city => city.name === value)
                        })}
                        className={`input input--grey mt-5 ${errors?.city ? 'input--error' : ''}`}>
                            <option value=""></option>
                            {cities.map((item, i) => (
                                <option key={i} value={item.name}>{item.name}</option>
                            ))}
                            {/* <option value="Moscow">Москва</option>
                            <option value="Saint-Petersburg">Санкт-Петербург</option> */}
                    </select>
                </label>
                {errors?.city && <ErrorInput message={errors.city?.message || 'Ошибка'}/>}
            </div>
            <div className="register-page__roles">
                <div className="form__subtitle">Выберите роль</div>
                {roleError && <ErrorInput message='Выберите роль'/>}
                <div className="register-page__roles__wrapper">
                    <div className={`register-page__roles__item ${role === 'customer' ? 'register-page__roles__item--active' : ''}`} onClick={(e) => setRole('customer')}>
                        <img src={customerLogo} alt="customer" className="register-page__roles__item__img"/>
                        <div className="register-page__roles__item__text">Заказчик</div>
                    </div>
                    <div className={`register-page__roles__item ${role === 'worker' ? 'register-page__roles__item--active' : ''}`} onClick={(e) => setRole('worker')}>
                        <img src={workerLogo} alt="worker" className="register-page__roles__item__img"/>
                        <div className="register-page__roles__item__text">Исполнитель</div>
                    </div>
                </div>
            </div>
            {/* <Captcha/> */}
            <div className="buttons-group mt-40">
                <button
                type="button" 
                    className="btn btn--primary btn--outline m-center p-5 h-40 w-100 ls-2"
                    onClick={() => navigate('/login')}>Отменить</button>
                <button className="btn btn--primary btn--orange m-center p-5 h-40 w-210 ls-2" >Продолжить</button>
            </div>
        </form>
    );
};

export default RegisterStep1;