import { useLocation } from "react-router-dom";

const useUrl = () => {
    const pageUrl = useLocation().pathname;

    const checkUrlContains = (str) => {
        return pageUrl.includes(str);
    };

    const checkUrlNotContains = (str) => {
        return !pageUrl.includes(str);
    };
    
    
    
    return {
        checkUrlContains,
        checkUrlNotContains
    };
}
export default useUrl;