import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from 'react-hook-form';
import DatePicker from "react-datepicker";
import ru from 'date-fns/locale/ru';
import FileUploader from "../fileUploader/FileUploader";
import { ErrorInput } from "../error/Error";
import InputWithSuggestions from "../inputs/InputWithSuggestions";
import { closePopupNewOrder } from "../../store/slices/popupSlice";
import useAuth from "../../hooks/useAuth";
import useAlert from "../../hooks/useAlert";
import useUserAction from "../../hooks/useUserAction";
import { fetchCategories, fetchSpecializations, fetchQualifications, fetchAddressWithGeoData } from "../../api/api";

import './Popup.scss';
import "react-datepicker/dist/react-datepicker.css";



const PopupNewOrder = ({setUpdateOrdersList}) => {
    const [categories, setCategories] = useState([]);
    const [specializations, setSpecializations] = useState([]);
    const [qualifications, setQualifications] = useState([]);
    const [addressSuggestions, setAddressSuggestions] = useState();
    const [selectedAddress, setSelectedAddress] = useState();
    const [files, setFiles] = useState([]);

    const dispatch = useDispatch();
    const isVisible = useSelector(state => state.popup.newOrder);
    const {authToken} = useAuth();
    const {showAlert} = useAlert();
    const {customerCreatedOrder} = useUserAction();

    const {register, handleSubmit, formState: {errors, isValid}, reset, watch, setValue, setError, setFocus, control} = useForm({mode: 'onBlur'});
    const category = watch('category');
    const specialization = watch('specialization');
    const qualification = watch('qualification');
    const address = watch('address');

    const onSubmit = async (data) => {
        
        if (address.length < 10) {
            setError('address', {
                type: 'required',
                message: 'Укажите не менее 10 символов'
            });
            setFocus('address');
            return;
        };

        if (!address.replace(/\D/g, '')) {
            setError('address', {
                type: 'required',
                message: 'Укажите номер дома'
            });
            setFocus('address');
            return;
        };
        
        if (!selectedAddress?.address) {
            setError('address', {
                type: 'required',
                message: 'Выберите адрес из выпадающего списка'
            });
            setFocus('address');
            return;
        };
    

        try {
            const order = await customerCreatedOrder({...data, files, addressWithGeoData: selectedAddress}, authToken);
            reset();
            setFiles([]);
            setSelectedAddress(null);
            setAddressSuggestions(null);
            dispatch(closePopupNewOrder());
            showAlert(`Спасибо! Ваш заказ №${order.number} размещен в поиск.`);
            setUpdateOrdersList && setUpdateOrdersList(state => !state);
        } catch (error) {
            console.log(error);
            showAlert(error.message);
        }
    };

    const handleClose = () => {
        reset();
        setFiles([]);
        dispatch(closePopupNewOrder());
    };

    //Реализуем фильтрацию через переменную, в которую запишем результат работы кэшированной функции
    const filteredSpecializations = useMemo(() => {
        if (category) {
            const filteredSpecializations = specializations.filter(item => item.categoryId === category.id);
            
            return filteredSpecializations;
            
        } else {
            return [];
        }
    },[category, specializations]);
    
    const filteredQualifications = useMemo(() => {
        if (specialization?.id) {
            const filteredQualifications = qualifications.filter(item => item.specializationId === specialization.id);
            return filteredQualifications;
        } else {
            return [];
        }
    }, [category, specialization, qualifications]);
    
    const handleCategoryChange = (e) => {
        setValue('category', e.target.value);
        setValue('specialization', '');
        setValue('qualification', null);
        // reset({specialization: ''});
    };

    // !!!Сделать функцию, для сброса квалификации, при изменении специализации
    const handleSpecializationChange = (e) => {
        setValue('specialization', e.target.value);
        setValue('qualification', '');
    };

    const onClickSuggestion = (suggestion) => {
        setValue('address', suggestion.address);
        setSelectedAddress(suggestion);
        setAddressSuggestions(null);
    };

    useEffect(() => {
        fetchCategories(authToken)
            .then(data => setCategories(data))
            .catch(error => {
                console.log(error);
                showAlert(error.message);
            });
        fetchSpecializations(authToken)
            .then(data => setSpecializations(data))
            .catch(error => {
                console.log(error);
                showAlert(error.message);
            });
        fetchQualifications(authToken)
            .then(data => setQualifications(data))
            .catch(error => {
                console.log(error);
                showAlert(error.message);
            });
    },[]);

    //С помощью useEffect реализуем ввод адрес с задержкой запрос а к серверу - когда пользователь перестал вводить данные, через 1 сек делаем запрос к серверу и получаем подсказки адресов
    useEffect(() => {
        const delay = 1000;

        //Сбросим ранее выбранный адрес если он есть и обнулим подсказки
        if (selectedAddress?.address && selectedAddress.address !== address) setSelectedAddress(null);
        if (addressSuggestions) setAddressSuggestions(null);

        const addressInputTimer = setTimeout(async () => {
            const isShouldRequestSuggestions = (address?.length > 10 && address.replace(/\D/g, '')) &&
                                                (selectedAddress?.address ? address !== selectedAddress.address : true)
            try {
                if (isShouldRequestSuggestions) {
                    const addressSuggestions = await fetchAddressWithGeoData(address, authToken);
                    setAddressSuggestions(addressSuggestions);
                }
            } catch (error) {
                console.log(error);
                showAlert(error.message);
            }
        }, delay);

        return () => clearTimeout(addressInputTimer);
    }, [address]);



    return (
        <div 
            className={`popup ${isVisible ? 'popup--active' : ''}`}
            onClick={handleClose}>
            <div 
                className="popup__dialog"
                onClick={(e) => e.stopPropagation()}>
                <div 
                    className="popup__close"
                    onClick={handleClose}
                >&times;</div>
                <div className="popup__header">
                    <div className="popup__title">
                        Новый заказ
                    </div>
                </div>
                <div className="popup__content">
                    <form>
                        <select
                            className="input input--grey"
                            {...register('category', {
                                required: 'Выберите категорию',
                                setValueAs: (value) => categories.find(item => item.name === value)
                            })}
                            onChange={handleCategoryChange}
                        >
                            <option value="">Выберите категорию</option>
                            {categories.map((item, i) => (
                                <option key={i} value={item.name}>{item.name}</option>
                            ))}
                        </select>
                        {(errors?.category && !category) && <ErrorInput message={errors.category?.message || 'Ошибка'}/>}

                        <select 
                            className="input input--grey"
                            {...register('specialization', {
                                required: 'Выберите специализацию',
                                setValueAs: (value) => specializations.find(item => item.name === value)
                            })}
                            // value={selectedSpecialization?.name || ''} 
                            onChange={handleSpecializationChange}
                        >
                            <option value="">Выберите нужного специалиста</option>
                            {category?.id && filteredSpecializations.map((item, i) => (
                                <option key={i} value={item.name}>{item.name}</option>
                            ))}
                        </select>
                        {errors?.specialization && <ErrorInput message={errors.specialization?.message || 'Ошибка'}/>}

                        {/* <input
                            className="input input--grey" 
                            type="text"
                            placeholder="Укажите адрес объекта"
                            {...register('address', {required: 'Укажите адрес'})}
                            // onChange={handleAddressChange}
                        />
                        {errors?.address && <ErrorInput message={errors.address?.message || 'Ошибка'}/>} */}
                        
                        <InputWithSuggestions
                            className="input input--grey" 
                            type="text"
                            placeholder="Укажите адрес объекта"
                            {...register('address', {
                                required: 'Укажите адрес',
                                // setValueAs: (value) => value?.address || value 
                            })}
                            // onChange={handleAddressChange}git
                            suggestions={addressSuggestions}
                            onClickSuggestion={onClickSuggestion}
                        />
                        {errors?.address && <ErrorInput message={errors.address?.message || 'Ошибка'}/>}

                        <Controller
                            name="dateTime"
                            control={control}
                            defaultValue={null}
                            rules={{required: 'Выберите дату и время'}}
                            render={({field: {onChange, value}}) => (
                                <DatePicker
                                    className="input input--grey" 
                                    selected={value ? new Date(value) : null} 
                                    onChange={val => onChange(val.toISOString())}
                                    showTimeSelect
                                    dateFormat="d MMMM yyyy, HH:mm"
                                    timeFormat="HH:mm"
                                    timeCaption="Время"
                                    minDate={new Date()}
                                    minTime={new Date().setHours(9,0,0)}
                                    maxTime={new Date().setHours(18,0,0)}
                                    locale={ru}
                                    placeholderText="Выберите дату и время"/>
                            )}
                        />
                        {errors?.dateTime && <ErrorInput message={errors.dateTime?.message || 'Ошибка'}/>}

                        <input
                            className="input input--grey" 
                            type="number"
                            // defaultValue={1}
                            placeholder="Укажите количество специалистов"
                            {...register('numberOfWorkers', {required: 'Укажите количество работников'})}/>
                        {errors?.numberOfWorkers && <ErrorInput message={errors.numberOfWorkers?.message || 'Ошибка'}/>}

                        <textarea
                            className="input input--grey input--textarea"
                            placeholder="Опишите задачу"
                            {...register('description',{required: 'Опишите задачу'})}/>
                        {errors?.description && <ErrorInput message={errors.description?.message || 'Ошибка'}/>}

                        <FileUploader
                            label='Загрузите фото'
                            fileTypes={['.jpg', '.jpeg', '.png', '.gif']}
                            exportFiles={setFiles}
                            resetFiles={files.length < 1}/>

                        {/* <Controller
                            name="files"
                            defaultValue={null}
                            control={control}
                            render={({field: {onChange, value}}) => (
                                <FileUploader 
                                    fileTypes={['.jpg', '.jpeg', '.png', '.gif']}
                                    onChange={files => onChange(files)}
                                    value={value}/>
                            )}/> */}


                        {specialization && 
                            <div className="new-order__qualifications">
                                <div className="new-order__qualifications__title">
                                    Выберите квалификацию специалиста
                                </div>
                                {errors?.qualification && <ErrorInput message={errors.qualification?.message || 'Ошибка'}/>}

                                <Controller
                                    name="qualification"
                                    control={control}
                                    defaultValue={null}
                                    rules={{required: 'Выберите квалификацию'}}
                                    onChange={(data) => {
                                        return data;
                                    }}
                                    render={({ field: { onChange } }) => (
                                        <>
                                            {specialization?.id && filteredQualifications.map((item, i) => (
                                                <div 
                                                    className={`input input--grey new-order__qualifications__item ${qualification?.name === item.name ? 'new-order__qualifications__item--active' : ''}`} 
                                                    key={i}
                                                    onClick={() => onChange(item)}>
                                                    <div className="new-order__qualifications__item__name">
                                                        {item.name}
                                                    </div>
                                                    <div className="new-order__qualifications__item__price">
                                                        {`${item.price} руб.`}
                                                    </div>
                                                </div>
                                            ))} 
                                        </>
                                    )}
                                />
                            </div>
                        }
                        <button 
                            className="btn btn--primary btn--orange new-order__btn"
                            onClick={handleSubmit(onSubmit)}>Разместить заказ</button>
                    </form>
                </div>
                <div className="popup__footer"></div>
            </div>
        </div>
    );
};

export default PopupNewOrder;