import { useEffect } from 'react';
import useAlert from '../../hooks/useAlert';

import './Alert.scss';


const Alert = () => {
    const {checkAlert, hideAlert} = useAlert();
    const {isOpen, message, type} = checkAlert();


    return (
        <div className={`alert ${isOpen ? 'alert--active' : ''} `}>
            <div className="alert__dialog">
                <div className="alert__content">
                    {message}
                </div>
                <div className="alert__footer">
                    <div className="buttons-group mt-20">
                        <button
                            className="btn btn--primary btn--green"
                            onClick={hideAlert}>ОК</button>
                        {type === 'ok-cancel' &&
                        <button
                            className="btn btn--primary btn--red"
                            onClick={() => hideAlert('reject')}>Отмена</button>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Alert;