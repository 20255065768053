import { useState } from "react";
import { useForm } from "react-hook-form";
import Popup from "./Popup";
import CustomInput from "../inputs/CustomInput";
import FileUploader from "../fileUploader/FileUploader";
import { ErrorInput } from "../error/Error";

const PopupNewSupportRequest = ({isActive, hidePopup, supportTopics, sendSupportRequest}) => {
    const [files, setFiles] = useState([]);
    const {register, handleSubmit, formState:{errors, isValid}, reset} = useForm();
    
    const handleClose = () => {
        hidePopup();
        reset();
        setFiles([]);
    };

    const onSubmit = async (data) => {
        const {topic, text} = data;
        const SupportRequestPosted = await sendSupportRequest(topic.id, text, files);
        hidePopup();
        reset();
    };

    return (
        <Popup isActive={isActive}>
            <div className="popup__close" onClick={handleClose}>&times;</div>
            <div className="popup__header">
                <div className="popup__title">
                    Создание обращения
                </div>
            </div>
            <div className="popup__content">
                <form >
                    <select 
                        className="input"
                        {...register('topic', {
                            required: 'Укажите тему обращения',
                            setValueAs: (value) => supportTopics.find(topic => topic.name === value)
                        })}>
                        <option value=''>Тема обращения</option>
                        {supportTopics.map((topic, i) => (
                            <option 
                                key={i}
                                value={topic.name}>{topic.name}</option>
                        ))}
                    </select>
                    {errors?.topic && <ErrorInput message={errors.topic?.message || 'Ошибка'}/>}
                    <textarea 
                        className="input h-auto" 
                        rows={6} 
                        placeholder="Описание проблемы"
                        {...register('text', {
                            required: 'Опишите проблему'
                        })}/>
                    {errors?.text && <ErrorInput message={errors.text?.message || 'Ошибка'}/>}
                    <FileUploader
                            label={'Загрузите файлы'} 
                            fileTypes={['.jpg', '.jpeg', '.png', '.gif']}
                            exportFiles={setFiles}
                            resetFiles={files.length < 1}
                            maxFilesQty={3}
                            maxFileSize="5Mb"/>
                </form>
            </div>
            <div className="popup__footer">
                <button 
                    className={`btn btn--primary btn--orange m-center mt-30`}
                    onClick={handleSubmit(onSubmit)}>
                    Отправить
                </button>
            </div>
        </Popup>
    )
};

export default PopupNewSupportRequest;