


export default function downloadFile(url, newBlank=false) {
    console.log(newBlank);
    if (newBlank) {
        window.open(url, '_blank');
        return;
    };

    const link = document.createElement('a');
    link.href = url;
    link.download = '';
    link.click();
    link.remove();

    return;
};