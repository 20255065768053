import { useState, useEffect, useRef } from 'react';
import './Reviews.scss';
import userPhoto1 from '../../../../assets/images/reviews/user-photo-1.png';
import userPhoto2 from '../../../../assets/images/reviews/user-photo-2.png';
import userPhoto3 from '../../../../assets/images/reviews/user-photo-3.jpg';
import userPhoto4 from '../../../../assets/images/reviews/user-photo-4.png';
import userPhoto5 from '../../../../assets/images/reviews/user-photo-5.jpg';
import clockImg from '../../../../assets/images/reviews/clock-icon.png';
import deal from '../../../../assets/images/reviews/deal.png';
import {ReactComponent as PrevArrow} from '../../../../assets/images/reviews/prev-arrow.svg';
import {ReactComponent as NextArrow} from '../../../../assets/images/reviews/next-arrow.svg';


const Reviews = () => {
    const [slidesOffset, setSlidesOffset] = useState(0);
    const isSliderVertical = window.matchMedia('(max-width: 768px)').matches;

    const slides = [
        {
            date: '31 августа 2023',
            name: 'Александр',
            position: 'Директор строительной компании',
            photo: userPhoto1,
            text: 'Мы делаем ремонты квартир и часто не хватает рук. С приложением RealWorker, мы нанимаем столько рабочих, сколько нужно. Теперь мы можем выполнять больше заказов. Спасибо разрработчикам.'
        },
        {
            date: '25 августа 2023',
            name: 'Сергей',
            position: 'Главный инженер управляющей компании',
            photo: userPhoto2,
            text: 'У нас в управлении несколько домов в ЦАО. Часто требуются промышленные альпинисты для небольших задач на высоте. Компании за маленький объем не берутся, нас очень выручает RealWorker. Рекомендую.'
        },
        {
            date: '25 июля 2023',
            name: 'Даниил',
            position: 'Монтажник НВФ',
            photo: userPhoto3,
            text: 'Удобный сервис. Нахожу здесь отличные заказы с ежедневной оплатой. Действительно полезное приложение, которое должно быть у каждого строителя.'
        },
        {
            date: '19 июня 2023',
            name: 'Владимир',
            position: 'Руководитель проекта строительной компании',
            photo: userPhoto4,
            text: 'Могу смело рекомендовать приложение RealWorker как отличный инструмент для строительной компании. Всех рабочих, которых не хватает в штате ищем здесь.'
        },
        {
            date: '03 июня 2023',
            name: 'Вячеслав',
            position: 'Бригадир, частная бригада',
            photo: userPhoto5,
            text: 'С приложением RealWorker я решил сразу 2 задачи – наша бригада теперь работает без простоев, если нужна работа, здесь она всегда есть и также быстро нахожу подсобных рабочих для усиления бригады.'
        },
    ]

    const showNextSlide = () => {
        if (slidesOffset > -60) setSlidesOffset(state => state - 20);
    };


    const showPrevSlide = () => {
        if (slidesOffset < 0) setSlidesOffset(state => state + 20);
    };

    const getSliderInlineStyles = (slidesOffset) => {
        //Проверим, превышеает ли ширина экрана 768px, на этой отметке слайдер становится горизонтальным
        const isSliderVertical = window.matchMedia('(max-width: 768px)').matches;

        const styles = {transform: isSliderVertical ? `translateY(${slidesOffset}%)` : `translateX(${slidesOffset}%)`};

        return styles;
    };

    const validateText = (text) => {
        if (text.length > 200) {
            return `${text.substring(0, 200)} .....`
        }
        return text;
    };


    return (
        <div className='reviews'>
            <div className="container">
                <div className="title">Кейсы наших клиентов</div>
                <div className="reviews__wrapper">
                    <img src={deal} alt="deal" className="reviews__img" />
                    <div className="reviews__slider">
                        <div className="reviews__slider__wrapper">
                            <div className="reviews__slider__inner" style={getSliderInlineStyles(slidesOffset)}>
                                {slides.map((review, i) => (
                                    <div 
                                        className="reviews__item"
                                        key={i}>
                                        <div className="reviews__user">
                                            <img src={review.photo} alt={review.name} className="reviews__user__photo" />
                                            <div className="reviews__user__descr">
                                                <div className="reviews__name">{review.name}</div>
                                                <div className="reviews__position">
                                                    {review.position}
                                                </div>
                                                <div className="reviews__date">
                                                    <img className="reviews__date__icon" src={clockImg} alt='clock'></img>
                                                    <div className="reviews__date__text">{review.date}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="reviews__text">
                                            {validateText(review.text)}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="reviews__control">
                            <PrevArrow 
                                className={`${slidesOffset >= 0 ? 'disabled' : ''}`}
                                onClick={showPrevSlide}/>
                            <NextArrow 
                            className={`${slidesOffset <= -60 ? 'disabled' : ''}`}
                            onClick={showNextSlide}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reviews;