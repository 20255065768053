import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { DecorCircle, DecorDots, EnterLink } from './CardComponents';
import useDate from '../../hooks/useDate';

import './Card.scss';

const EventCard = ({order}) => {
    const {number, dateTime, address, specialization} = order;
    const {formatDate} = useDate();

    return (
        <div className="card card--event">
            <div className="card__header card__header--event">
                <div className="card__order-number color-orange5">
                    {formatDate(dateTime).slice(12)}
                    
                </div>
                <div className="card__order-time">
                    Заказ №{number}
                </div>
            </div>
            <div className="card__content--event">
                <div className="card__content__item">
                    <div className="card__content__item__param">
                        {address}
                    </div>
                    <div className="card__content__item__value">
                        {specialization}
                    </div>
                    <div className="decor">
                        <DecorCircle/>
                        {/* <DecorDots/> */}
                    </div>
                </div>
            </div>
            <div className="card__footer card__footer--event">
                {/* <div className="card__info">
                    {`${price} руб.`}
                </div> */}
                <div className="card__links">
                    <Link to={`/worker-orders/${number}`}>
                        <IconContext.Provider value={{className: 'card__links__icon'}}>
                            <EnterLink/>
                        </IconContext.Provider>
                    </Link>
                </div>
            </div>
        </div>
    );
}; 

export default EventCard;