import './OrdersCounter.scss';

const OrdersCounter = ({counters}) => {

    return (
        <section className="orders-counter">
            {counters.map((item, i) => (
                        <div 
                            key={i} 
                            className={`orders-counter__item ${item?.active ? 'active' : ''}`} 
                            onClick={item.handleClick}>
                            <div className="orders-counter__item__number">
                                {item.number
                                    ? item.number
                                    : 0}
                            </div>
                            <div className="orders-counter__item__text">
                                {item.text}
                            </div>
                        </div>
            ))}
        </section>
    );
};

export default OrdersCounter;