import { useNavigate } from "react-router-dom";
import MenuQuick from "./MenuQuick";

const MenuQuickWorker = ({...props}) => {
    const navigate = useNavigate();
    return (
        <MenuQuick
            {...props}
            role='worker'
            handleClickBtn1={() => navigate('/worker-orders')}
            handleClickBtn2={() => navigate('/worker-messages')}
            handleClickBtn3={() => navigate('/vacant-orders')}
            handleClickBtn4={() => navigate('/worker-notifications')}
            handleClickBtn5={() => navigate('/worker-settings')}/>
    );
};

export default MenuQuickWorker;