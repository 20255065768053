import { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import HomeHeader from '../homePage/components/homeHeader/HomeHeader';
import MenuSideManual from '../../components/menu/menuSide/MenuSideManual';
import './ManualPage.scss';

export default function ManualPage() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const isMainManualPage = /manual$/i.test(location.pathname);
        
        if (isMainManualPage) setIsMenuOpen(true);
        if (!isMainManualPage && isMenuOpen) setIsMenuOpen(false);
    },[location.pathname]);

    return (
        <div className="manual-page">
            <HomeHeader/>
            <div className="main__wrapper">
                <MenuSideManual isActive={isMenuOpen} setIsActive={setIsMenuOpen}/>
                <main className="main__content">
                    <div className="container container--small">
                        <div 
                            className='manual-page__menu-trigger' 
                            onClick={() => setIsMenuOpen(state => !state)}>{`Навигация >`}</div>
                        <Outlet />
                    </div>
                </main>
            </div>
        </div>
    );
};