import ImageZoomer from '../../../../components/imageZoomer/ImageZoomer';
import './QuickStart.scss';
import registerPic1 from '../../../../assets/images/manual/quick-start-customer/register-pic1-mod.png';
import registerPic2 from '../../../../assets/images/manual/quick-start-customer/register-pic2-mod.png';
import registerPic3 from '../../../../assets/images/manual/quick-start-customer/register-pic3-mod.png';
import newOrderPic1 from '../../../../assets/images/manual/quick-start-customer/new-order-pic1_mod.jpg';
import newOrderPic2 from '../../../../assets/images/manual/quick-start-customer/new-order-pic2_mod.jpg';
import newOrderPic3 from '../../../../assets/images/manual/quick-start-customer/new-order-pic3_mod.jpg';
import trackingPic1 from '../../../../assets/images/manual/quick-start-customer/tracking-pic1_mod.jpg';
import trackingPic2 from '../../../../assets/images/manual/quick-start-customer/tracking-pic2_mod.jpg';
import trackingPic3 from '../../../../assets/images/manual/quick-start-customer/tracking-pic3_mod.jpg';
import chat from '../../../../assets/images/manual/quick-start-customer/chat_mod.jpg';
import review from '../../../../assets/images/manual/quick-start-customer/review_mod.jpg';
import support from '../../../../assets/images/manual/quick-start-customer/support_mod.jpg';


const QuickStartCustomer = () => {
    return (
        <div className="quick-start">
            <h1 className='title'>Быстрый старт. Создание и управление заказом.</h1>
            <p className='mt-30'>Наше приложение создано чтобы экономить Ваше время на поиск рабочих. Создание Вашего первого заказа займет не более 5 минут.</p>
            <p className="subtitle mt-30">Шаг №1. Зарегистрируйтесь в нашем приложении</p>
            <div className="images-wrapper">
                <ImageZoomer>
                    <img src={registerPic1} alt="registerPic1" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={registerPic2} alt="registerPic2" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={registerPic3} alt="registerPic3" />
                </ImageZoomer>
            </div>

            <p className="subtitle mt-30">Шаг №2. Создайте заказ</p>
            <div className="images-wrapper">
                <ImageZoomer>
                    <img src={newOrderPic1} alt="newOrderPic1" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={newOrderPic2} alt="newOrderPic2" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={newOrderPic3} alt="newOrderPic3" />
                </ImageZoomer>
            </div>
            <p className="subtitle mt-30">Шаг №3. Отслеживайте выполнение заказа</p>
            <div className="images-wrapper">
                <ImageZoomer>
                    <img src={trackingPic1} alt="trackingPic1" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={trackingPic2} alt="trackingPic2" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={trackingPic3} alt="trackingPic3" />
                </ImageZoomer>
            </div>
            <p className="subtitle mt-30">Дополнительные возможности</p>
            <div className="images-wrapper">
                <ImageZoomer>
                    <img src={chat} alt="chat-trigger" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={review} alt="review-trigger" />
                </ImageZoomer>
                <ImageZoomer>
                    <img src={support} alt="support-trigger" />
                </ImageZoomer>
            </div>
        </div>
    )
};

export default QuickStartCustomer;