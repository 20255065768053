import { useState } from 'react';
import { IoIosContact } from 'react-icons/io';
import DropDownMenu from '../../dropDownMenu/DropDownMenu';
import useAuth from '../../../hooks/useAuth';

const UserInfo = () => {
    const [isMenuActive, setIsMenuActive] = useState(false);
    const {userName, userRole, userPhoto} = useAuth();
    const isMobile = window.matchMedia('(max-width: 992px)').matches;

    const toggleMenuActive = () => {
        setIsMenuActive(state => !state);
    };

    const onMouseEnter = () => {
        if (!isMenuActive && !isMobile) setIsMenuActive(true);
    };

    const onMouseLeave = () => {
        if (isMenuActive ) setIsMenuActive(false);
    };

    return (
        <div 
            className={`header__user-info ${isMenuActive ? 'active' : ''}`}
            onClick={toggleMenuActive}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            >
            {userPhoto
            ? <img 
            className={`header__img`}
            src={userPhoto} alt="userPhoto" />
            : <IoIosContact className={`header__img`}/>
            }
            {userName && <span className='header__user-name'>{userName}</span>}
            <DropDownMenu 
                menuObj={{
                    Профиль: userRole === 'customer' ? '/customer-settings' : '/worker-settings', 
                    Выход: null
                }}
                isActive={isMenuActive}/>
        </div>
    );
};

export default UserInfo;