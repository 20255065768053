import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import DatePicker from "react-datepicker";
import ru from 'date-fns/locale/ru';
import HeaderCustomer from "../../components/header/HeaderCustomer";
import ButtonBurger from "../../components/buttonBurger/ButtonBurger";
import MenuSideCustomer from "../../components/menu/menuSide/MenuSideCustomer";
import MenuQuickCustomer from "../../components/menu/menuQuick/MenuQuickCustomer"
import CustomInput from "../../components/inputs/CustomInput";
import { ErrorInput } from "../../components/error/Error";
import { BackArrowWithText } from "../../components/arrow/Arrow";
import { MessageLink, PhoneLink } from "../../components/card/CardComponents";
import useAlert from "../../hooks/useAlert";
import useOrder from "../../hooks/useOrder";
import { createChat } from "../../api/api";

import './OrderPage.scss';


const CustomerOrderPage = () => {
    const [isActive, setIsActive] = useState(false);
    const navigate = useNavigate();
    const {showAlert, showAlertRequire} = useAlert();
    const {orderNumber, order, updateOrder, deleteOrder, refreshData, authToken} = useOrder();
    const {register, handleSubmit, formState: {errors, isValid}, reset, watch, setValue, control} = useForm();
    const address = watch('address');
    const dateTime = watch('dateTime');
    const description = watch('description');
    
    const onSubmit = async ({address, dateTime, description}) => {
        const newOrderData = {};

        if (address !== order.address) {
            newOrderData.address = address;
        };
        if (dateTime !== order.dateTime) {
            newOrderData.dateTime = dateTime;
        };
        if (description !== order.description) {
            newOrderData.description = description;
        };

        if(Object.keys(newOrderData).length > 0) {
            try {
                await updateOrder(newOrderData);
                refreshData();
            } catch (error) {

            };
        };
    };

    const goBack = async () => {
        try {
            if (address !== order.address || dateTime !== order.dateTime || description !== order.description) {
                await showAlertRequire('Сохранить изменения в заказе?', 'ok-cancel');
                await onSubmit({address, dateTime, description});
            };
        } catch (error) {

        } finally {
            navigate(-1);
        }
    };

    const onMessageClick = async () => {
        try {
            const chatCreated = await createChat(orderNumber, authToken);
            navigate('/customer-messages', {state: {
                chatNumber: chatCreated.chatNumber
            }});
        } catch (error) {
            console.log(error);
            showAlert(error.message);
        }
    };
    
    useEffect(() => {
        setValue('address', order?.address);
        setValue('dateTime', order?.dateTime);
        setValue('description', order?.description);

        if (order?.status && order.status !== 'Заказ закрыт' && order.status !== 'Заказ выполнен') {
            setIsActive(true);
        };
    },[order]);

    return (
        < >
            <HeaderCustomer>
                <div className="header__content">
                    <ButtonBurger />
                    <h1 className="header__title">{`Заказ №${orderNumber}`}</h1>
                </div>
            </HeaderCustomer>

        <div className="main__wrapper">
            <MenuSideCustomer />
            <main className="main__content">
                <div className="container container--small">
                    <div className="d-flex justify-between items-center mt--14 color-orange4">
                        <BackArrowWithText
                            text={'Назад'}
                            handleClick={goBack}
                            additionalClasses='color-orange4'/>
                        {isActive && order.status !== 'Поиск исполнителя' &&
                        <div className="d-flex items-center gap-x-30">
                            <MessageLink 
                                additionalClasses={'d-flex items-center color-orange4'}
                                iconAdditionalClasses={'h-24 w-24'}
                                handleClick={onMessageClick}/>
                            <PhoneLink
                               additionalClasses={'d-flex items-center color-orange4'}
                               iconAdditionalClasses={'h-24 w-24'}/>
                        </div>}
                    </div>
                    <form >
                        <CustomInput
                            withExtLabel={true}
                            labelText='Адрес'
                            additionalClasses={`mt-20`}
                            disabled={!isActive}
                            registerWithName={{register: register, name: 'address', options: {required: 'Укажите адрес'}}}/>
                        {errors?.address && <ErrorInput message={errors.address?.message || 'Ошибка'}/>}

                        <Controller
                            name="dateTime"
                            control={control}
                            defaultValue={null}
                            rules={{required: 'Укажите дату и время'}}
                            render={({field: {onChange, value}}) => (
                                <div className="mt-20">
                                    <label 
                                        htmlFor="date-picker"
                                        className="input__label">Дата и время </label>
                                    <DatePicker
                                        id="date-picker"
                                        className="input mt-5" 
                                        selected={value ? new Date(value) : null} 
                                        onChange={val => val?.toISOString && onChange(val.toISOString())}
                                        showTimeSelect
                                        dateFormat="d MMMM yyyy, HH:mm"
                                        timeFormat="HH:mm"
                                        timeCaption="Время"
                                        minDate={new Date()}
                                        minTime={new Date().setHours(9,0,0)}
                                        maxTime={new Date().setHours(18,0,0)}
                                        locale={ru}
                                        disabled={!isActive}
                                    />
                                </div>
                            )}
                        />
                        {errors?.dateTime && <ErrorInput message={errors.dateTime?.message || 'Ошибка'}/>}
                        
                        <CustomInput
                            additionalClasses={'mt-20'}
                            withExtLabel={true}
                            labelText='Описание'
                            disabled={!isActive}
                            registerWithName={{register: register, name: 'description', options: {required: 'Опишите задачу'}}}/>
                        {errors?.description && <ErrorInput message={errors.description?.message || 'Ошибка'}/>}
                        
                        <div className="input-group">
                            <div className="input__label">
                                Категория 
                                <div className="input input--grey mt-5 color-grey6">
                                    {order?.category}
                                </div>
                            </div>
                        </div>
                        
                        <div className="input-group">
                            <div className="input__label">
                                Специализация 
                                <div className="input input--grey mt-5 color-grey6">
                                    {order?.specialization}
                                </div>
                            </div>
                        </div>
                        
                        <div className="input-group">
                            <div className="input__label">
                                Квалификация 
                                <div className="input input--grey mt-5 color-grey6">
                                    {order?.qualification}
                                </div>
                            </div>
                        </div>
                        
                        <div className="input-group">
                            <div className="input__label">
                                Цена 
                                <div className="input input--grey mt-5 color-grey6">
                                    {order?.price && `${order?.price} руб.`}
                                </div>
                            </div>
                        </div>

                        <div className="input-group">
                            <div className="input__label">
                                Исполнитель 
                                <div className="input input--grey mt-5 color-grey6">
                                    {order?.worker
                                    ? order.worker
                                    : 'Исполнитель не назначен'}
                                </div>
                            </div>
                        </div>

                        <div className="input-group">
                            <div className="input__label">
                                Статус 
                                <div className="input input--grey mt-5 color-grey6">
                                    {order?.status}
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="order-page__footer">
                        {
                        ((order?.address && address !== order.address) ||
                        (order?.dateTime && dateTime !== order.dateTime) ||
                        (order?.description && description !== order.description))
                        ? <>
                            <button 
                                className="btn btn--primary btn--outline m-center h-40 ls-2"
                                onClick={refreshData}
                            >Сбросить изменения</button>
                            <button
                                className="btn btn--primary btn--orange m-center h-40 ls-2"
                                onClick={handleSubmit(onSubmit)}
                                >Сохранить изменения</button>
                        </>
                        : isActive
                            ? <button 
                                className="btn btn--primary btn--outline btn-border--red m-center h-40 ls-2"
                                onClick={deleteOrder}
                            >Отменить заказ</button>
                            : null
                        }    
                    </div> 
                </div>
                
                
            </main>
        </div>

        <MenuQuickCustomer/>
        
    </>
    );
};

export default CustomerOrderPage;