import { useState, useEffect } from "react";
import {useForm} from 'react-hook-form';
import useAuth from "./useAuth";
import useAlert from "./useAlert";
import { fetchUserProfile, updateUserData } from "../api/api";

const useSettings = () => {
    const [mode, setMode] = useState('profile');
    const [user, setUser] = useState();
    const [settingsChanged, setSettingsChanged] = useState(false);

    const {authToken, setNewUserPhoto} = useAuth();
    const {showAlert} = useAlert();
    const useFormMethodsProfile = useForm();
    const useFormMethodsAccount = useForm();

    
    const clearInputs = () => {
        if (mode === 'profile') {
            useFormMethodsProfile.reset();
        }
        if (mode === 'account') {
            useFormMethodsAccount.reset();
        }
    };

    const saveData = (data) => {
        
        updateUserData(data, authToken)
            .then(() => fetchUserProfile(authToken)
            .then((data) => {
                setUser(data);
                setNewUserPhoto(data.photo);
            })
            .then(() => clearInputs()))
            .catch(error => {
                console.log(error);
                showAlert(error.message);
            });
    };

    useEffect(() => {
        fetchUserProfile(authToken)
            .then(data => setUser(data))
            .catch(error => {
                console.log(error);
                showAlert(error.message);
            });
    }, []);

    useEffect(() => {
        if (mode === 'profile') {
            useFormMethodsAccount.reset();
        }
        if (mode === 'account') {
            useFormMethodsProfile.reset();
        }
    }, [mode]);

    return {
        user,
        mode,
        setMode,
        saveData,
        clearInputs,
        settingsChanged,
        setSettingsChanged,
        useFormMethodsProfile,
        useFormMethodsAccount
    };
};

export default useSettings;