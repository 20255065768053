import { useState, useEffect } from "react";
import useAuth from './useAuth';
import useAlert from "./useAlert";
import { fetchUserSupportRequests, createSupportRequest, fetchSupportTopics } from "../api/api";

const useSupportRequests = () => {
    const [userSupportRequests, setUserSupportRequests] = useState(null);
    const [supportTopics, setSupportTopics] = useState([]);
    const [updateSupportRequests, setUpdateSupportRequests] = useState(false);
    const {authToken} = useAuth();
    const {showAlert} = useAlert();

    const sendSupportRequest = async (topicId, text, files) => {
        try {
            const supportRequestPosted = await createSupportRequest(topicId, text, files, authToken);
            setUpdateSupportRequests(state => !state);
            console.log(supportRequestPosted);
            return supportRequestPosted;

        } catch (error) {
            console.log(error);
            showAlert(error.message);
        }
    };

    useEffect(() => {
        fetchUserSupportRequests(authToken)
            .then(supportRequests => {
                const supportRequestsSorted = supportRequests.sort((a, b) => b.number - a.number);
                console.log(supportRequestsSorted);
                setUserSupportRequests(supportRequestsSorted)
            })
            .catch(error => {
                console.log(error);
                showAlert(error.message);
            });
    }, [updateSupportRequests]);

    useEffect(() => {
        fetchSupportTopics(authToken)
            .then(topics => {
                setSupportTopics(topics);
            })
            .catch(error => {
                console.log(error);
                showAlert(error.message);
            });
    }, []);
    
    
    return {
        supportTopics,
        userSupportRequests,
        sendSupportRequest
    }
};

export default useSupportRequests;