//Компонент FileUploader построен по следующему принципу:
//Есть блок file-uploader, внутри label и скрытый input[type=file], ширина и высота label растянуты на весь блок FileUploader, таким образом клик в любое место запустит открытие окна для загрузки файлов.
//При загрузке файлов в инпут, мы запускаем метод handleFileChange, который переберет все файлы и поместит их в локальный стейт files, заодно выполнив проверку на уже существование файла
//Когда меняется локальный стейт files, запускается Ф-я exportFiles, которая выбросит все файлы из локального стейта во внешний элемент (в форму), для последующей отправки при submit

import { useEffect, useState } from "react";
import { ErrorInput } from "../error/Error";
import './FileUploader.scss';

const FileUploader = ({label, fileTypes, maxFilesQty=10, maxFileSize='5Mb', exportFiles, resetFiles}) => {
    const [files, setFiles] = useState([]);
    const [keyInput, setKeyInput] = useState(Math.random()); //стейт нужен для перерендера компонента при удалении файла
    const [inputId, setInputId] = useState(Math.floor(Math.random() * (9999 - 1) + 1));
    const [maxFileSizeInBytes, setMaxFileSizeInBytes] = useState();
    const [error, setError] = useState('');
    console.log(error);

    const modifyFileSize = (fileSize) => {
        const size = parseFloat(fileSize);
        const unit = fileSize.replace(size,'').trim().toLowerCase();
        let multiplier = 1;

        switch(unit) {
            case 'kb':
                multiplier = 1024;
                break;
            case 'mb':
                multiplier = 1024 * 1024;
                break;
            case 'gb':
                multiplier = 1024 * 1024 *1024;
                break;
        };

        const sizeInBytes = size * multiplier;
        return sizeInBytes;
    };

    useEffect(() => {
        //Если в стейте внешнего компоннета нет файлов, обнулим файлы в локальном стейте компонента (нужно для сброса полей, допустим при закрытии или отправке формы)
        if (resetFiles) {
            setFiles([]);
        }
    }, [resetFiles]);

    useEffect(() => {
        //Если состав файлов изменился, отправим все файлы в локальный стейт внешнего компонента
        exportFiles(files);
        if (maxFilesQty) {
            if (files.length >= maxFilesQty && !error) {
                setError(`Достигнуто максимальное количество файлов (${maxFilesQty} шт.)`);
                setTimeout(() => setError(''), 2000);
            } 
            if (files.length < maxFilesQty && error && !error.includes('Максимальный размер загружаемого файла')) setError('');
        };
    }, [files]);

    useEffect(() => {
        setMaxFileSizeInBytes(modifyFileSize(maxFileSize));
    },[]);

    //Ф-я handleFileChange примет объект события, и методом перебора массива файлов, добавит каждый из файлов (кроме тех, которые уже есть) в локальный стейт files
    const handleFileChange = (e) => {
        // const newFiles = [...e.target.files];
        // newFiles.forEach(file => {
        //     console.log(files.length)
        //     if (!files.some(item => item.name === file.name && item.size === file.size)) {
        //         setFiles(files => [...files, file]);
        //     };
        // });

        const newFiles = [...e.target.files].filter(file => {
            if (file.size > maxFileSizeInBytes) {
                setError(`Максимальный размер загружаемого файла ${maxFileSizeInBytes/1024/1024} Mb.`);
                setTimeout(() => setError(''), 3000);
                return false;
            };
            return !files.some(item => item.name === file.name && item.size === file.size);
        }).slice(0, maxFilesQty - files.length);
        console.log(newFiles);
        setFiles(files => [...files, ...newFiles]);
    };

    //Ф-я handleDeleteFile примет файл, отфильтрует массив файлов по поученному файлу и изменит стейт setKeyInput, для того чтобы вызвать перерендер компонента, т.к. при фильтрации массива, перерендер не стартует, т.к. ссылка на массив остается прежней
    const handleDeleteFile = (file) => {
        const newFiles = files.filter(item => item !== file);
        setFiles(newFiles);
        setKeyInput(Math.random());
    };

    return (
        <div className="file-uploader">
            <div className="input input--grey file-uploader__input">
                <label htmlFor={`fileUploaderInput-${inputId}`}>{label}</label>
                <input 
                    type="file" 
                    id={`fileUploaderInput-${inputId}`}
                    key={keyInput} 
                    multiple
                    accept={fileTypes}
                    onChange={handleFileChange}/>
            </div>
            {files.length > 0 ? (
                <ul className="file-uploader__files-list">
                    {files.map((file, index) => (
                        <li className="file-uploader__files-list__item" key={index}>
                            {file.name}
                            <div 
                                className="file-uploader__btn-delete"
                                onClick={() => handleDeleteFile(file)}>&times;</div>
                        </li>
                    ))}
                </ul>
            ) : null}
            {error && <ErrorInput message={error}/>}
        </div>
    );
};

export default FileUploader;