import { useState, useEffect } from "react";
import useAuth from './useAuth';
import useAlert from './useAlert';
import { fetchUserSpecializations, fetchSpecializations, fetchQualifications, createUserSpecialization, fetchCategories } from '../api/api';

const useSpecializations = () => {
    const [categories, setCategories] = useState([]);
    const [specializations, setSpecializations] = useState([]);
    const [userSpecializations, setUserSpecializations] = useState([]);
    const [updateSpecializationsList, setUpdateSpecializationsList] = useState(false);

    const {authToken} = useAuth();
    const {showAlert} = useAlert();
    
    const updateSpecializations = () => {
        setUpdateSpecializationsList(state => !state);
    };

    const createSpecialization = async (data) => {
        try {
            const userSpecializationPosted = await createUserSpecialization(authToken, data.category.name, data.specialization.name);
            
            return userSpecializationPosted;
            
        } catch (error) {
            console.log(error);
            showAlert(error.message);
        };
    };

    useEffect(() => {
        fetchUserSpecializations(authToken)
            .then(userSpecializations => {
                const userSpecializationsSorted = [...userSpecializations].sort((a,b) => b.id - a.id);
                setUserSpecializations(userSpecializationsSorted);
            })
            .catch(error => {
                console.log(error);
                showAlert(error.message);
            });
    }, [updateSpecializationsList]);

    useEffect(() => {
        fetchCategories()
            .then(categories => setCategories(categories))
            .catch(error => {
                console.log(error);
                showAlert(error.message);
            });
        fetchSpecializations()
            .then(specializations => setSpecializations(specializations))
            .catch(error => {
                console.log(error);
                showAlert(error.message);
            });
    }, []);



    return {
        categories,
        specializations,
        userSpecializations,
        createSpecialization,
        updateSpecializations
    }
};

export default useSpecializations;